import growlithe from "constants/growlithe";
import pichu from "constants/pichu";

import Router, { useRouter } from "next/router";
import Meowth from "@spring/meowth";
import { useCustomToast } from "@springcare/sh-component-library";

import { purgeRememberUser } from "lib/rememberUser";

import { AUTH_USER_IDENTIFIERS_LOADED } from "./types";

import { reduxStore } from "constants/reduxStore";

import routes from "routes";
import { initApollo } from "utils/apollo/init";

import {
  BOOKS_FAST_PROVIDERS,
  MEMBER_FAVORITE_PROVIDERS,
} from "components/templates/Browse/ProviderBrowsePage/utils";

import {
  alias,
  track,
  identifyUser as mixpanelIdentify,
  TRACK_EVENT,
} from "utils/mixpanel";
import {
  getUserIdentifiers,
  getUserInfo,
  getZendeskJwt,
} from "operations/queries/user";

import { isEqual } from "lodash/fp";
import { findRouteMatch, findVariablePositions } from "utils/routes";
import { useRegisterContext } from "context/RegisterContext";
import { useTranslation } from "hooks/react-i18next";

export async function getIdentifiers() {
  const apollo = initApollo();
  const id = Meowth.getUserId();

  return apollo
    .query({
      query: getUserIdentifiers,
      variables: { id },
    })
    .then(({ data }) => {
      const customerId = data?.user?.member?.cohort?.customer?.id;
      const cohortId = data?.user?.member?.cohort?.id;
      const minor = data?.user?.member?.minor ?? false;
      const memberId = data?.user?.member?.id;

      localStorage.setItem("customerId", customerId);
      localStorage.setItem("isMinor", minor);
      localStorage.setItem("member_id", memberId);
      localStorage.setItem("cohortId", cohortId);
      return Promise.resolve(data);
    })
    .catch((err) => {
      if (
        err.graphQLErrors?.length > 0 &&
        err.graphQLErrors[0].message?.match(
          /.*NoMethodError: undefined method.*for nil:NilClass.*/,
        )
      ) {
        pichu.logout();
        return Promise.reject("Customer not configured");
      } else return Promise.reject("Login error");
    });
}

export async function getZendeskJwtToken(userId) {
  const apollo = initApollo();
  const id = userId || Meowth.getUserId();

  return apollo
    .query({
      query: getZendeskJwt,
      variables: { id },
      fetchPolicy: "network-only",
    })
    .then(({ data }) => {
      return Promise.resolve(data);
    })
    .catch((err) => {
      // eslint-disable-next-line no-console
      console.log("getZendeskJwt error", err);
      return null;
    });
}

async function getUserData(id) {
  const apollo = initApollo();
  const res = await apollo.query({
    query: getUserInfo,
    variables: { id },
  });

  if (res.data) {
    return res.data;
  }
  return false;
}

export function mergeRecursive(tmpObj1, obj2) {
  //make object editable
  let obj1 = JSON.parse(JSON.stringify(tmpObj1));

  for (var p in obj2) {
    try {
      // Property in destination object set; update its value.
      if (obj2[p].constructor === Object) {
        obj1[p] = mergeRecursive(obj1[p], obj2[p]);
      } else {
        obj1[p] = obj2[p];
      }
    } catch (_e) {
      // Property in destination object not set; create it and set its value.
      obj1[p] = obj2[p];
    }
  }

  return obj1;
}

export async function getFullUserInfo() {
  const userIdentifiers = await getIdentifiers();
  const userInfo = await getUserData(userIdentifiers?.user?.id);
  let fullUser = mergeRecursive(userInfo, userIdentifiers);
  const meResp = await pichu.getMe();
  fullUser.user["is_confirmed"] = meResp.data.is_confirmed;

  return fullUser;
}

/**
 *
 * Actions.
 *
 */
export async function verifyToken() {
  try {
    const succeeded = await pichu.verifyToken();

    if (succeeded) {
      // Look into how we can leverage state/caching to avoid calling getUser more than necessary
      return getFullUserInfo();
    }
  } catch (_err) {
    return;
  }
}

function redirectToRegister() {
  Router.push(routes.Register.to, routes.Register.as);
}

function redirectToCodeConfirmationPage(email, fromSignIn) {
  localStorage.setItem("fromSignIn", fromSignIn || false);
  Router.push(
    routes.CodeConfirmationEmail.to,
    routes.CodeConfirmationEmail.as + "?email=" + encodeURIComponent(email),
  );
}

function redirectToMomentsOnly() {
  Router.replace(routes.MomentsOnly.to);
}

function redirectToChooseUser() {
  Router.replace(routes.ChooseUser.to, routes.ChooseUser.as);
}

export function redirectToMemberHome() {
  Router.replace(routes.MemberHome.to, routes.MemberHome.as);
}

export function setUserIdentifiers(userIdentifiers) {
  return {
    type: AUTH_USER_IDENTIFIERS_LOADED,
    userIdentifiers,
  };
}

export async function newSignIn(data) {
  track("Auth -- Signin Sent");
  const { email, password, mixpanelAlias } = data;
  // sign in
  const res = await pichu.signIn(email, password, [
    "group:member",
    "group:moments_only",
  ]);
  const { id } = res.data;
  // if user is new, add MP alias
  if (id && mixpanelAlias) {
    alias(id);
  }
  // get identifiers and add to Redux for downstream
  const userIdentifiers = await getFullUserInfo();
  const identifiersWithScope = { ...userIdentifiers, scopes: res.scopes };
  reduxStore.dispatch(setUserIdentifiers(identifiersWithScope));
  // identify user in MP, track sign-in event in MP
  mixpanelIdentify(userIdentifiers);

  if (userIdentifiers?.user?.member?.id) {
    track("Auth -- Signin Succeeded");
  }

  //check is moments only login
  if (res.scopes.includes("group:moments_only")) {
    redirectToMomentsOnly();
  }
  return { ...identifiersWithScope };
}

export async function refreshUserData() {
  const userIdentifiers = await getFullUserInfo();
  await mixpanelIdentify(userIdentifiers);
  reduxStore.dispatch(setUserIdentifiers(userIdentifiers));
  return userIdentifiers;
}

async function postSignInActions(
  response,
  routeAlias,
  mixpanelAlias,
  fromSignIn,
) {
  if (mixpanelAlias) {
    alias(response.data.id);
  }

  // Look into how we can leverage state/caching to avoid calling getUser more than necessary
  // We are now adding user to auth redux store on the return of this signIn call
  // and verify using setUserIdentifiers(userIdentifiers)
  const userIdentifiers = await getFullUserInfo();
  // Add user Id's to store upon signIn - lack of this was causing an error in accurately setting user flags
  reduxStore.dispatch(setUserIdentifiers(userIdentifiers));
  // Opportunity to remove this mixpanel identify after identity merge is turned on in mixpanel
  // or we can verify it will not break reporting. If not identified here, the below track calls
  // are not associated with an identified user (potentially others on redirect, not confirmed).
  // MP identify is already getting called on verify in _app.js
  mixpanelIdentify(userIdentifiers);

  let momentsOnlyUser = response.scopes.includes("group:moments_only");

  if (momentsOnlyUser) {
    redirectToMomentsOnly();
    return userIdentifiers;
  }

  if (userIdentifiers?.user?.member?.id) {
    track("Auth -- Signin Succeeded");
  }

  const userData = await getUserData(userIdentifiers?.user?.id);
  if (!userData.user.member.postal_address.street_address_1) {
    redirectToRegister();
    return userIdentifiers;
  }

  const isConfirmed = response.data.is_confirmed;

  if (!isConfirmed) {
    track("Sign-In -- Redirected to Confirmation Page");
    redirectToCodeConfirmationPage(response.data.email, fromSignIn);
    return userIdentifiers;
  }

  if (routeAlias) {
    const { to, as } = routes[routeAlias];
    Router.replace(to, as);
    localStorage.removeItem("initialTarget");
    return userIdentifiers;
  } else if (localStorage.getItem("initialTarget")) {
    const initialTarget = localStorage.getItem("initialTarget");
    const targetAlias = findRouteMatch(initialTarget);
    const variablePositions = findVariablePositions(targetAlias.as);

    if (targetAlias && variablePositions && !!variablePositions.length) {
      // Sets query for routes with dynamic values
      const query = {};
      for (let x of Object.values(variablePositions)) {
        const key = x.key.slice(1);
        const value = initialTarget.split("/").slice()[x.position];
        query[key] = value;
      }

      Router.replace(
        {
          pathname: targetAlias.to,
          query,
        },
        initialTarget,
      );

      localStorage.removeItem("initialTarget");
      return userIdentifiers;
    } else if (
      targetAlias &&
      initialTarget !== "/" &&
      initialTarget !== "/sign_in" &&
      initialTarget !== "/sign_up" &&
      initialTarget !== "/forgot_password" &&
      initialTarget !== "/reset_password" &&
      initialTarget !== "/logout" &&
      initialTarget !== "/register" &&
      initialTarget !== "/sso_sign_in" &&
      initialTarget !== "/sso_register"
    ) {
      Router.replace(targetAlias.to, initialTarget);
      localStorage.removeItem("initialTarget");
      return userIdentifiers;
    }
  }

  localStorage.removeItem("initialTarget");

  const managedDependents =
    userIdentifiers?.user?.member?.managed_dependents ?? [];

  if (managedDependents.length > 0) {
    redirectToChooseUser();
  } else {
    redirectToMemberHome();
  }
  return userIdentifiers;
}

export function signIn(
  email,
  password,
  routeAlias,
  mixpanelAlias, // call alias in MP to identify this user, should only be used on signup
  fromSignIn,
  params = {},
) {
  track("Auth -- Signin Sent");

  return pichu
    .signIn(email, password, ["group:member", "group:moments_only"], params)
    .then(async (response) =>
      postSignInActions(response, routeAlias, mixpanelAlias, fromSignIn),
    );
}

export async function mfaCreateAccessToken(mfaToken, callback = undefined) {
  await pichu.createMFAAccessToken(mfaToken);

  if (typeof callback === "function") {
    callback();
  }
}

export function mfaSignIn(email, password, challenge, mfaToken, routeAlias) {
  return pichu
    .mfaSignIn(challenge, mfaToken, email, password, ["group:member"])
    .then(async (response) =>
      postSignInActions(response, routeAlias, null, true),
    );
}

export function signUp(email, password, password_confirmation, first_name) {
  return (dispatch) =>
    dispatch(
      growlithe.post(
        "auth",
        {
          confirm_success_url: window ? window.location.href : "",
          email,
          password,
          password_confirmation,
          first_name,
        },
        {
          prepend: "SIGN_UP",
        },
      ),
    );
}

export function forgotPassword(email, redirect) {
  const encodedEmail = encodeURIComponent(email);
  return pichu.forgotPassword(encodedEmail, redirect);
}

export function resetPassword(email, password, token) {
  return pichu.resetPassword(email, password, token);
}

export function logout(redirectToPath = routes.SignIn.as, query = {}) {
  localStorage.removeItem("hasOpened");
  localStorage.removeItem("uuid");
  sessionStorage.removeItem("availability_requested_medication_manager");
  sessionStorage.removeItem("availability_requested_therapist");
  localStorage.removeItem("availability_requested_sud_cn");
  sessionStorage.removeItem("countryChangeRequested");
  sessionStorage.removeItem("langDropdownTriggered");
  sessionStorage.removeItem("supportForParentsModalShown");
  sessionStorage.removeItem("ageOutUpdateSettingsShown");
  localStorage.removeItem(BOOKS_FAST_PROVIDERS);
  localStorage.removeItem(MEMBER_FAVORITE_PROVIDERS);

  purgeRememberUser();
  pichu.logout();
  Router.replace({
    pathname: redirectToPath,
    query: query,
  });
}

export function resendConfirmationEmail(email) {
  return pichu.resendConfirmationEmail(email);
}

export function verifyConfirmationCode({ confirmation_code, email }) {
  return pichu.verifyConfirmationCode({ confirmation_code, email });
}

// Update items on member right after login based on query params
export const updateMemberDataAfterLoginFromParams = (
  updateMemberGraphQlCall,
  memberId,
) => {
  if (!memberId) {
    return;
  }

  const patch = {};

  // Check for `opt_in_personal` flag which enables a provider to see anonymous member data
  if (localStorage.getItem("opt_in_personal")) {
    patch.opt_in_personal =
      localStorage.getItem("opt_in_personal").toLowerCase() === "true";
    localStorage.removeItem("opt_in_personal");
  }

  const hasKeys = !!Object.keys(patch).length;

  if (hasKeys > 0) {
    updateMemberGraphQlCall({
      variables: {
        input: {
          id: memberId,
          patch: patch,
        },
      },
    });
  }
};

export const checkAndSetSSORelayState = async (ssoRelayState = {}) => {
  let relay_token = window.localStorage.getItem("relay_token");

  // If no relay token, do nothing
  if (!relay_token) {
    return;
  }

  // Check to see if we have a relay state loaded
  if (ssoRelayState.id && isEqual(relay_token, ssoRelayState.relay_token)) {
    return;
  }

  // Set the SSO relay state in redux for later use
  return pichu.getSSORelayState(relay_token).catch((error) => {
    // eslint-disable-next-line no-console
    console.log(error);
  });
};

export const AVAILABLE_SSO_CLIENTS_ERRORS = {
  BACKEND_FAILED_RESPONSE: "BACKEND_FAILED_RESPONSE",
  CUSTOMER_NOT_ACTIVE_OR_LAUNCHED: "CUSTOMER_NOT_ACTIVE_OR_LAUNCHED",
  MEMBER_NOT_FOUND: "MEMBER_NOT_FOUND",
  MISSING_REQUIRED_PARAMS: "MISSING_REQUIRED_PARAMS",
};

/**
 * Hook that encapsulates the logic for fetching available SSO clients for a given email address,
 * as well as alerting and redirecting as needed.
 */
export const useAvailableSSOClients = () => {
  const { setCurrentView, setSsoClientOptions, STEPS } = useRegisterContext();

  const { t } = useTranslation("limitedLangAuth", { keyPrefix: "signIn" });

  const router = useRouter();

  const handleRedirectForNoSSOOptions = async (isMember = false) => {
    if (isMember) {
      if (router.asPath === routes.SignIn.as) {
        setCurrentView(null);
      } else {
        return router.replace(routes.SignIn.as);
      }
    } else {
      if (router.asPath === routes.Register.as) {
        setCurrentView(STEPS.EMAIL_VERIFICATION);
      } else {
        return router.replace(routes.Register.as);
      }
    }
  };

  const backendFailureToast = useCustomToast({
    message: t("viaSSOProvider.getSSOProviderErrors.generalError"),
    type: "error",
  });
  const customerError = useCustomToast({
    message: t("viaSSOProvider.getSSOProviderErrors.customerErrorMessage"),
    type: "error",
  });
  const memberNotFoundError = useCustomToast({
    message: t(
      "viaSSOProvider.getSSOProviderErrors.memberNotFoundErrorMessage",
    ),
    type: "error",
  });
  const missingParamsError = useCustomToast({
    message: t("viaSSOProvider.getSSOProviderErrors.missingParamsErrorMessage"),
    type: "error",
  });
  const noSSOOptionsUnclaimedCoveredLifeToast = useCustomToast({
    message: t("viaSSOProvider.getSSOProviderErrors.noSSOOptionsFoundMessage"),
    type: "error",
  });
  const noSSOOptionsClaimedCoveredLifeToast = useCustomToast({
    message: t(
      "viaSSOProvider.getSSOProviderErrors.noSSOOptionsFoundClaimedCoveredLifeMessage",
    ),
    type: "error",
  });
  const unsupportedSSOProviderToast = useCustomToast({
    message: t(
      "viaSSOProvider.getSSOProviderErrors.unsupportedSSOProviderToast",
    ),
    type: "error",
  });

  const getAvailableSSOClients = async (email) => {
    try {
      const data = await pichu.fetchAvailableSSOOptions(email);

      if (data.success) {
        const ssoOptions = data.sso_options;

        // We've found a CL or a member, but there are no SSO options
        if (ssoOptions.length === 0) {
          return handleRedirectForNoSSOOptions(data.is_member).then(() => {
            if (data.is_member) {
              noSSOOptionsClaimedCoveredLifeToast();
              return trackSSONotification("SSO Clients: None For Claimed CL");
            }
            noSSOOptionsUnclaimedCoveredLifeToast();
            return trackSSONotification("SSO Clients: None For Unclaimed CL");
          });
        }

        // We've found a CL or a member with SSO clients, but the clients may not have login URLs
        // setup yet
        const validSSOOptions = getSSOOptionsWithLoginURLs(ssoOptions);

        if (validSSOOptions.length === 0) {
          return handleRedirectForNoSSOOptions(data.is_member).then(() => {
            unsupportedSSOProviderToast();
            const notificationType = data.is_member
              ? "SSO Clients: Unsupported For Claimed CL"
              : "SSO Clients: Unsupported For Unclaimed CL";

            return trackSSONotification(notificationType);
          });
        }

        // We've found exactly one SSO option, so we can redirect the user
        if (validSSOOptions.length === 1) {
          return router.replace(validSSOOptions[0].login_url);
        }

        // More than one SSO option, allow a user to select one
        setSsoClientOptions(validSSOOptions);
        setCurrentView(STEPS.SELECT_SSO_PROVIDER);
      } else {
        if (
          data.message === AVAILABLE_SSO_CLIENTS_ERRORS.BACKEND_FAILED_RESPONSE
        ) {
          backendFailureToast();
          return trackSSONotification(data.message);
        }

        if (
          data.message === AVAILABLE_SSO_CLIENTS_ERRORS.MISSING_REQUIRED_PARAMS
        ) {
          missingParamsError();
          return trackSSONotification(data.message);
        }

        if (
          data.message ===
          AVAILABLE_SSO_CLIENTS_ERRORS.CUSTOMER_NOT_ACTIVE_OR_LAUNCHED
        ) {
          customerError();
          return trackSSONotification(data.message);
        }

        if (data.message === AVAILABLE_SSO_CLIENTS_ERRORS.MEMBER_NOT_FOUND) {
          return handleRedirectForNoSSOOptions(false).then(() => {
            memberNotFoundError();
            return trackSSONotification(data.message);
          });
        }

        backendFailureToast();
        return trackSSONotification("SSO Clients: Unexpected Response");
      }
    } catch (_e) {
      backendFailureToast();
      return trackSSONotification("SSO Clients: Fetch Error");
    }
  };

  return {
    /**
     * Fetches available SSO clients for a given email address from the auth service.
     */
    getAvailableSSOClients,
  };
};

const getSSOOptionsWithLoginURLs = (ssoOptions) => {
  return ssoOptions.filter((ssoOption) => ssoOption.login_url != null);
};

const trackSSONotification = (message) => {
  return TRACK_EVENT.NOTIFICATION_VIEWED(window.location.pathname, message);
};
