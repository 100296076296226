import {
  Box,
  Modal,
  ModalOverlay,
  ModalContent,
  VStack,
  Heading,
  useMediaQuery,
  HStack,
  useDisclosure,
} from "@springcare/sh-component-library";
import { VideoPlayer } from "modules/shared/components/VideoPlayer/VideoPlayer";
import { VideoPlayerProvider } from "modules/shared/components/VideoPlayer/VideoPlayer/context/videoPlayerContext";
import {
  trackVideoLoaded,
  trackVideoPlayed,
  trackVideoPaused,
  trackVideoEnded,
  trackVideoForward10,
  trackVideoSeek,
  trackVideoBackward10,
  trackCaptionChange,
  trackFullScreenChange,
  trackCloseMoment,
} from "modules/MemberDashboard/Moments/components/analytics";
import { MomentsCompletionModal } from "modules/MemberDashboard/Moments/components";
import { Exercise } from "modules/MemberDashboard/Moments/types/exerciseType";
import { toTitleCase } from "utils/mixpanel";
import { mobileAndTabletCheck } from "utils/displayHelpers";
import { VideoMomentModalOverlayCloseButton } from "./VideoMomentModalOverlayCloseButton";
import completeStep from "operations/mutations/exercise/completeStep";
import {
  ApolloQueryResult,
  OperationVariables,
  useMutation,
} from "@apollo/client";

const createListenerCallbacks = (
  exercise: Exercise,
  onOpenCompletedModal: () => void,
  onCloseVideoModal: () => void,
  completeVideoStep: () => void,
) => ({
  onPlaying: () => {
    trackVideoLoaded(
      exercise.id,
      exercise.title,
      exercise.categories?.map((item) => toTitleCase(item.category)).join(", "),
      exercise.content_formats?.join(", "),
    );
  },
  onPlay: () => {
    trackVideoPlayed(
      exercise.id,
      exercise.title,
      exercise.categories?.map((item) => toTitleCase(item.category)).join(", "),
      exercise.content_formats?.join(", "),
    );
  },
  onPause: () => {
    trackVideoPaused(
      exercise.id,
      exercise.title,
      exercise.categories?.map((item) => toTitleCase(item.category)).join(", "),
      exercise.content_formats?.join(", "),
    );
  },
  onEnd: async () => {
    trackVideoEnded(
      exercise.id,
      exercise.title,
      exercise.categories?.map((item) => toTitleCase(item.category)).join(", "),
      exercise.content_formats?.join(", "),
    );
    onOpenCompletedModal();
    onCloseVideoModal();
    try {
      return await completeVideoStep();
    } catch (e) {
      console.error("Error completing video step", e);
    }
  },
  onTimeUpdate: (currentTime: number) => {
    return;
  },
  onForward10: () => {
    trackVideoForward10(
      exercise.id,
      exercise.title,
      exercise.categories?.map((item) => toTitleCase(item.category)).join(", "),
      exercise.content_formats?.join(", "),
    );
  },
  onBackward10: () => {
    trackVideoBackward10(
      exercise.id,
      exercise.title,
      exercise.categories?.map((item) => toTitleCase(item.category)).join(", "),
      exercise.content_formats?.join(", "),
    );
  },
  onSeeking: (updateTime: number) => {
    trackVideoSeek(
      exercise.id,
      exercise.title,
      exercise.categories?.map((item) => toTitleCase(item.category)).join(", "),
      exercise.content_formats?.join(", "),
    );
  },
  onCaptionChange: (caption: boolean) => {
    trackCaptionChange(
      exercise.id,
      exercise.title,
      exercise.categories?.map((item) => toTitleCase(item.category)).join(", "),
      exercise.content_formats?.join(", "),
      caption,
    );
  },
  onFullScreenChange: (fullScreen: boolean) => {
    trackFullScreenChange(
      exercise.id,
      exercise.title,
      exercise.categories?.map((item) => toTitleCase(item.category)).join(", "),
      exercise.content_formats?.join(", "),
      fullScreen,
    );
  },
});

type VideoMomentModalOverlayProps = {
  isOpen: boolean;
  onClose: () => void;
  exercise: Exercise;
  onOpenExitModal: () => void;
  onCloseVideoModal: () => void;
  refetchMemberAppointmentAndAssessmentData?: (
    variables?: Partial<OperationVariables>,
  ) => Promise<ApolloQueryResult<any>>;
};

export const VideoMomentModalOverlay: React.FC<
  VideoMomentModalOverlayProps
> = ({
  isOpen,
  onClose,
  exercise,
  onOpenExitModal,
  onCloseVideoModal,
  refetchMemberAppointmentAndAssessmentData,
}) => {
  const src: [{ src: string; type: string }] = [
    {
      src: exercise?.starting_step?.video_url,
      type: "application/x-mpegURL", //TODO: in later PR, make more flexible to also handle mp4
    },
  ];
  const captionUrl = exercise?.starting_step?.caption_url;
  const title = exercise?.title;
  const {
    isOpen: isCompletedModalOpen,
    onOpen: onOpenCompletedModal,
    onClose: onCloseCompletedModal,
  } = useDisclosure();

  const [completeStepMutation] = useMutation(completeStep, {
    onCompleted: ({ completeStep: { success } }) => {
      if (success) {
        refetchMemberAppointmentAndAssessmentData?.();
      }
    },
  });

  const completeVideoStep = async () => {
    await completeStepMutation({
      variables: {
        exercise_id: exercise.id,
        current_step_id: exercise.starting_step.id,
      },
    });
  };

  const listenerCallbacks = createListenerCallbacks(
    exercise,
    onOpenCompletedModal,
    onCloseVideoModal,
    completeVideoStep,
  );
  const [isLandscape] = useMediaQuery("(orientation: landscape)");
  const [isMobile] = useMediaQuery("(max-width:600px)");
  const isTablet = /iPad|Tablet/.test(navigator.userAgent);

  const isMobileOrTabletDevice = mobileAndTabletCheck();

  const handleCloseButtonOnClick = () => {
    trackCloseMoment(
      exercise?.id,
      exercise?.title,
      exercise?.categories
        ?.map((item) => toTitleCase(item.category))
        .join(", "),
      exercise?.content_formats?.join(", "),
    );
    onOpenExitModal();
  };

  return (
    <>
      <VideoPlayerProvider
        mobile={isMobileOrTabletDevice}
        listenerCallbacks={listenerCallbacks}
      >
        <Modal
          autoFocus={false}
          isOpen={isOpen}
          onClose={onClose}
          isCentered
          size="full"
        >
          <ModalOverlay />
          <ModalContent
            m={0}
            p={0}
            borderRadius="none"
            bg={
              isMobile || (isMobileOrTabletDevice && isLandscape)
                ? "black"
                : "white"
            }
          >
            <VStack
              spacing={0}
              w={
                isTablet || (isMobileOrTabletDevice && isLandscape)
                  ? "full"
                  : ["full", 1024]
              }
              h={isTablet ? "full" : ["full", 574]}
              mx={isTablet ? 0 : [0, "auto"]}
              mt={isTablet ? 0 : [0, 49]}
              position="relative"
            >
              <HStack justifyContent="space-between" mb="v-24" w="100%">
                <Heading
                  size="heading-small"
                  display={["none", "none", "block"]}
                  marginTop={isMobileOrTabletDevice && isLandscape ? 0 : "v-24"}
                  ml={isTablet ? "v-8" : "0"}
                >
                  {title}
                </Heading>
                <VideoMomentModalOverlayCloseButton
                  handleCloseButtonOnClick={handleCloseButtonOnClick}
                />
              </HStack>
              <Box w="full" h="full">
                <VideoPlayer
                  src={src}
                  captionUrl={captionUrl}
                  listenerCallbacks={listenerCallbacks}
                  shouldWrapWithProvider={false}
                />
              </Box>
            </VStack>
          </ModalContent>
        </Modal>
        <MomentsCompletionModal
          isOpen={isCompletedModalOpen}
          onClose={onCloseCompletedModal}
          exerciseTitle={exercise?.title}
          exerciseId={exercise?.id}
          onCloseVideoModal={onCloseVideoModal}
          exerciseCategory={exercise?.categories
            ?.map((item) => toTitleCase(item.category))
            .join(", ")}
          exerciseMediaType={exercise?.content_formats?.join(", ")}
        />
      </VideoPlayerProvider>
    </>
  );
};
