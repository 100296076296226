import { gql } from "@apollo/client";

import {
  experienceState,
  postalAddress,
  contractTerm,
} from "operations/fragments/member";
const firstUpcomingAppointments = (peerRecoverySpecialistAvailable) => {
  const firstUpcomingAppointmentsWithPRS = gql`
    fragment firstUpcomingAppointments on FirstUpcomingAppointmentOutputType {
      therapy {
        id
        kind
        provider_id
        start_at
        end_at
        medium
        location
        confirmed_at
        provider {
          id
          name
          avatar
          user_id
        }
      }
      coaching {
        id
        kind
        provider_id
        start_at
        end_at
        medium
        location
        confirmed_at
        provider {
          id
          name
          avatar
          user_id
        }
      }
      medication_management {
        id
        kind
        provider_id
        start_at
        end_at
        medium
        location
        confirmed_at
        provider {
          id
          name
          avatar
          user_id
        }
      }
      care_navigation {
        id
        kind
        provider_id
        start_at
        end_at
        medium
        location
        provider {
          id
          name
          avatar
          user_id
        }
      }
      peer_recovery {
        id
        kind
        provider_id
        start_at
        end_at
        medium
        location
        provider {
          id
          name
          avatar
          user_id
        }
      }
    }
  `;
  const firstUpcomingAppointmentsWithoutPRs = gql`
    fragment firstUpcomingAppointments on FirstUpcomingAppointmentOutputType {
      therapy {
        id
        kind
        provider_id
        start_at
        end_at
        medium
        location
        confirmed_at
        provider {
          id
          name
          avatar
          user_id
        }
      }
      coaching {
        id
        kind
        provider_id
        start_at
        end_at
        medium
        location
        confirmed_at
        provider {
          id
          name
          avatar
          user_id
        }
      }
      medication_management {
        id
        kind
        provider_id
        start_at
        end_at
        medium
        location
        confirmed_at
        provider {
          id
          name
          avatar
          user_id
        }
      }
      care_navigation {
        id
        kind
        provider_id
        start_at
        end_at
        medium
        location
        provider {
          id
          name
          avatar
          user_id
        }
      }
    }
  `;
  const firstUpcomingAppointments = peerRecoverySpecialistAvailable
    ? firstUpcomingAppointmentsWithPRS
    : firstUpcomingAppointmentsWithoutPRs;
  return firstUpcomingAppointments;
};

export const getHomePageMemberInfo = (
  peerRecoverySpecialistAvailable = false,
) => {
  return gql`
    query getHomePageMemberInfo($id: ID!) {
      user(id: $id) {
        id
        first_name
        last_name
        created_at
        member {
          id
          email
          date_of_birth
          preferred_language
          country_code
          # New endpoints - Use these instead of the above endpoints
          visits_covered_total # Includes Therapy (basic), Specialist (MM), and sponsored covered Sessions
          visits_covered_coaching # Only coaching covered
          visits_covered_specialist # Only Specialist covered
          visits_used_total # Includes Therapy, Specialist (MM), and sponsored used sessions
          visits_used_basic # Only Therapy (basic) used sessions
          visits_used_specialist # Only Specialist (MM) used sessions
          visits_used_coaching # Only Coaching used sessions
          visits_remaining_coaching # Only Coaching remaining sessions
          visits_remaining_total # Includes Therapy (basic), Specialist (MM), and sponsored remaining sessions
          visits_remaining_specialist # Only Specialist (MM) remaining sessions
          visits_eligibility_limit_date # The contract term expiration date
          workplace_manager
          # -----------------------------
          access_limit_date
          inside_inactivation_window
          terminate_reason
          is_private_practice
          covered_life {
            country
            dependent
          }
          first_upcoming_appointments {
            ...firstUpcomingAppointments
          }
          visits_eligibility_limit_date
          postal_address {
            ...postalAddress
          }

          minor

          manager {
            id
            first_name
            last_name
            email
          }

          is_work_life_supported

          managed_dependents {
            id
            first_name
            last_name
            email
            date_of_birth
            within_aging_out
          }

          check_in_frequency_days
          full_address_available
          chart {
            medication_manager_recommended
            care_navigator_call_recommended
          }
          todos {
            id
            detail
            title
            kind
            data
          }
          experience_state {
            ...experienceState
          }
          optimism
          previsit {
            id
            status
            can_schedule
          }
          cohort {
            id
            benefits_summary
            basic_visits_covered
            specialist_visits_covered
            all_basic_visits_covered
            all_specialist_visits_covered
            in_person_supported
            in_person_med_management_supported
            dependents_allowed
            child_dependents_allowed
            coaching_visits_covered
            cohort_eap_link
            cohort_eap_text
            eap_intro_text
            eap_access_code_text
            eap_freephone_number
            eap_direct_dial_number
            eap_sms_number
            eap_email
            has_dedicated_providers
            unlimited_coaching_direct_visits
            unlimited_coaching_cn_visits
            in_network
            international
            contract_term {
              ...contractTerm
            }
            customer {
              id
              name
              enable_member_no_show_policy
              trial
              is_health_plan
            }
          }

          customer_site {
            id
            name
            address
            city
            state
            postal_code
            appointment_directions
            care_providers {
              id
              user_id
            }
          }

          aged_out_consent_fields {
            created_at
            terms_of_service_agreed_at
          }

          customer_site_id

          assessments(status: "Finished") {
            kind
            ended_at
            risk
          }

          tracked_goals {
            id
            goal {
              description
            }
          }
        }
      }
    }
    ${experienceState}
    ${postalAddress}
    ${contractTerm}
    ${firstUpcomingAppointments(peerRecoverySpecialistAvailable)}
  `;
};
