import {
  Heading,
  Text,
  ModalSheet,
  useMediaQuery,
} from "@springcare/sh-component-library";
import { useTranslation } from "react-i18next";

type SpecialityCareFlyoutType = {
  isOpen: boolean;
  onClose: () => void;
};
const SpecialityCareFlyoutBody = () => {
  const { t } = useTranslation("homepage", {
    keyPrefix: "careInfoFlyout.specialityCare",
  });

  return (
    <>
      <Heading size="heading-small" mb="v-8">
        {t("questions.first.heading")}
      </Heading>
      <Text mb={24}>{t("questions.first.body")}</Text>

      <Heading size="heading-small" mb="v-8">
        {t("questions.second.heading")}
      </Heading>
      <Text mb={24}>{t("questions.second.body")}</Text>

      <Heading size="heading-small" mb="v-8">
        {t("questions.third.heading")}
      </Heading>
      <Text mb={24}>{t("questions.third.body")}</Text>
    </>
  );
};
export const SpecialityCareFlyout = ({
  isOpen,
  onClose,
}: SpecialityCareFlyoutType) => {
  const { t } = useTranslation("homepage", {
    keyPrefix: "careInfoFlyout.specialityCare",
  });
  const [isSm] = useMediaQuery("(max-width: 480px)");

  return (
    <ModalSheet
      placement={isSm ? "bottom" : "right"}
      title={t("heading")}
      footerVariant="high-emphasis"
      footerActions={[
        {
          label: t("buttonText"),
          onClick: onClose,
        },
      ]}
      isOpen={isOpen}
      onClose={onClose}
      scrim={true}
      size={isSm ? "lg" : "sm"}
    >
      <>
        <SpecialityCareFlyoutBody />
      </>
    </ModalSheet>
  );
};
