import React, { useEffect, useState, useCallback } from "react";
import uuid from "react-uuid";
import {
  Box,
  ModalSheet,
  useMediaQuery,
} from "@springcare/sh-component-library";
import { useToast, SHNotification } from "design-system/components";
import CareProviderScheduleContent from "components/molecules/CareProviderScheduleContent/CareProviderScheduleContent";
import { trackProviderProfileModalClosed } from "components/templates/Browse/ProviderBrowsePage/analytics";
import { FLAGS, useFeatureFlag } from "utils/launchdarkly";
import { PaymentInfoUpdateWrapper } from "components/modals";
import {
  COST_ESTIMATE_DISPLAY_STATES,
  ValueOfCostEstimateDisplayStates,
} from "components/templates/CostEstimation/constants";
import { useTranslation } from "hooks/react-i18next";
import { useScheduleModalData } from "./__hooks__/useScheduleModalData";
import {
  CareProviderScheduleContentProps,
  CareProviderScheduleModalV2Props,
} from "./types";
import { getEventProps, lookupModalTitle, getModalPlacement } from "./utils";
import { isGlobalUser } from "utils/global";
import { useModalTracking } from "./__hooks__/useModalTracking";
import { getOr } from "lodash/fp";
import {
  isMedicationManagement,
  isTherapy,
  isCoaching,
  PaymentPreference,
} from "@spring/constants";
import { datadogAddError } from "lib/datadog-setup";
import { TRACK_EVENT } from "utils/mixpanel";

// Clone of CareProviderScheduleModal - MEI-107 will replace remaining uses
// of CareProviderScheduleModal with this component after GA of MXENG-3682
export const CareProviderScheduleModalV2 = ({
  isOpen: isScheduleModalOpen,
  onClose: onScheduleModalClose,
  kind,
  provider,
  providerRole,
  action,
  buttonText,
  dataCy,
  appointmentId,
  providerOrder,
  queryRequestId,
  pageNumber,
  previousReasonForScheduling,
  telemetryProps,
  defaultTab,
}: CareProviderScheduleModalV2Props): JSX.Element => {
  const [isDesktop] = useMediaQuery("(min-width: 780px)");
  const [isMobile] = useMediaQuery("(max-width: 480px)");
  const therapyTopSpecialtiesFlag = useFeatureFlag(
    FLAGS.THERAPY_TOP_SPECIALTIES,
  );
  const shouldShowNewInsuranceModal = useFeatureFlag(FLAGS.NEW_INSURANCE_MODAL);
  const [currentProvider, setCurrentProvider] = useState(provider);

  const [modalUuid, setModalId] = useState<string>("");
  const [showScheduleContent, setShowScheduleContent] = useState<boolean>(null);
  const [displayState, setDisplayState] =
    useState<ValueOfCostEstimateDisplayStates>(
      COST_ESTIMATE_DISPLAY_STATES.LOADING,
    );
  const { t } = useTranslation("costEstimate");
  const {
    cnAppointmentData,
    mmAppointmentData,
    member,
    wrappedMemberInsuranceData,
    medium,
    initialStartTime,
    customerSite,
    upcomingAppointmentSlotsLoading,
    isLoading,
  } = useScheduleModalData({ providerRole, kind, provider: currentProvider });

  const isCCAProviderListLoaded =
    customerSite?.care_providers?.length !== undefined;

  const providerIsLoading =
    isLoading ||
    (provider && !currentProvider) ||
    (!isCCAProviderListLoaded && providerRole === "Care Advocate");

  useEffect(() => {
    setCurrentProvider(provider);
  }, [provider]);

  const handleCCANext = () => {
    setCurrentProvider((currentProvider) => {
      const careProviderIndices =
        customerSite?.care_providers.map((provider) => provider.id) || [];
      const currentIndex = careProviderIndices.indexOf(currentProvider?.id);
      const length = customerSite?.care_providers.length;
      const nextIndex = (currentIndex + 1) % length;
      if (!customerSite || customerSite.care_providers.length === 0) {
        return null;
      }
      return customerSite?.care_providers[nextIndex];
    });
  };

  const errorLoadingProvider =
    !providerIsLoading && isScheduleModalOpen && !currentProvider;

  useModalTracking(
    currentProvider,
    kind,
    medium,
    telemetryProps,
    isScheduleModalOpen,
    providerIsLoading,
    showScheduleContent,
  );

  useEffect(() => {
    if (
      !providerIsLoading &&
      displayState === COST_ESTIMATE_DISPLAY_STATES.LOADING
    ) {
      setDisplayState(COST_ESTIMATE_DISPLAY_STATES.INITIAL_DISPLAY);
    }
  }, [providerIsLoading, displayState]);

  const hasTwoOrFewerVisitsRemaining = useCallback(() => {
    const visitsRemainingSpecialist = getOr(
      0,
      "visits_remaining_specialist",
      member,
    );
    const visitsRemainingTotal = getOr(0, "visits_remaining_total", member);
    const visitsRemainingCoaching = getOr(
      0,
      "visits_remaining_coaching",
      member,
    );
    const unlimitedVisitsCoachingcn = getOr(
      false,
      "cohort.unlimited_coaching_cn_visits",
      member,
    );
    const unlimitedDirectVisitsCoaching = getOr(
      false,
      "cohort.unlimited_coaching_direct_visits",
      member,
    );

    if (isMedicationManagement(kind)) {
      return visitsRemainingSpecialist <= 2;
    } else if (isTherapy(kind)) {
      return visitsRemainingTotal <= 2;
    } else if (isCoaching(kind)) {
      if (unlimitedVisitsCoachingcn || unlimitedDirectVisitsCoaching)
        return false;
      return visitsRemainingCoaching <= 2;
    } else {
      // This means session is care navigation (or some other type added later)
      return false;
    }
  }, [kind, member]);

  const country: string = member?.postal_address?.country;

  const shouldShowPaymentModal = useCallback(() => {
    const hasInsuranceInfo =
      !!wrappedMemberInsuranceData?.user?.member?.insurance_policy
        ?.carrier_name;
    const hasElectedToSelfPay =
      wrappedMemberInsuranceData?.user?.member?.payment_preference ===
      PaymentPreference.OutOfPocket;
    return (
      hasTwoOrFewerVisitsRemaining() &&
      !hasInsuranceInfo &&
      !hasElectedToSelfPay &&
      shouldShowNewInsuranceModal &&
      !isGlobalUser(country)
    );
  }, [
    shouldShowNewInsuranceModal,
    wrappedMemberInsuranceData,
    hasTwoOrFewerVisitsRemaining,
    country,
  ]);

  useEffect(() => {
    setModalId(uuid());
  }, []);

  useEffect(() => {
    //condition for displaying payment modal for the first time
    if (!providerIsLoading) {
      setShowScheduleContent(!shouldShowPaymentModal());
    }
  }, [
    isScheduleModalOpen,
    shouldShowNewInsuranceModal,
    wrappedMemberInsuranceData,
    providerIsLoading,
    shouldShowPaymentModal,
    country,
  ]);

  const toast = useToast();
  const createToast = (text: string, status: string) => {
    toast({
      duration: null,
      containerStyle: {
        maxWidth: "100%",
        width: "80%",
      },
      render: () => (
        <SHNotification
          notification={{ text: text, status: status }}
          setNotification={() => true}
        />
      ),
    });
  };

  useEffect(() => {
    // If the modal is being opened but there's no provider
    // then show an error toast, log the error, and "close" the modal
    if (errorLoadingProvider) {
      const message = `CareProviderScheduleModalV2: provider is not defined`;

      console.error(message); // eslint-disable-line no-console
      createToast(`Error loading ${providerRole} info for scheduling`, "error");
      datadogAddError(message);
      TRACK_EVENT.ERROR(window.location.pathname, message);

      onScheduleModalClose();
    }
  }, [providerIsLoading, isScheduleModalOpen, currentProvider]);

  const onScheduleModalCloseWithTracking = () => {
    trackProviderProfileModalClosed({
      modalUuid,
      type: "Insurance Details Prompt Modal",
      eventProps: getEventProps(
        currentProvider,
        kind,
        medium,
        therapyTopSpecialtiesFlag,
      ),
    });
    setModalId(uuid());
    setDisplayState(COST_ESTIMATE_DISPLAY_STATES.INITIAL_DISPLAY);
    onScheduleModalClose();
  };

  const transitionToScheduleModal = () => {
    setDisplayState(COST_ESTIMATE_DISPLAY_STATES.INITIAL_DISPLAY);
    setShowScheduleContent(true);
  };

  const handleBackButtonClick = () =>
    setDisplayState(COST_ESTIMATE_DISPLAY_STATES.INITIAL_DISPLAY);
  const shouldShowBackButton =
    displayState === COST_ESTIMATE_DISPLAY_STATES.INSURANCE_FORM ||
    displayState === COST_ESTIMATE_DISPLAY_STATES.SELF_PAY_DISPLAY
      ? "on"
      : "off";

  const scheduleContentProps: CareProviderScheduleContentProps = {
    modalUUID: modalUuid,
    careProvider: currentProvider,
    memberInfo: member,
    providerRole,
    appointmentKind: kind,
    cnAppointments: cnAppointmentData,
    mmAppointments: mmAppointmentData,
    action,
    buttonText,
    dataCy,
    appointmentId,
    providerOrder,
    queryRequestId,
    pageNumber,
    previousReasonForScheduling,
    medium,
    initialStartTime,
    customerSite,
    displayState,
    setDisplayState,
    defaultTab,
    handleCCANext,
    currentProvider,
    upcomingAppointmentSlotsLoading,
  };

  if (errorLoadingProvider) return null; /* Needs to be after all the hooks */

  const getMinWidth = () => {
    if (
      isMobile ||
      displayState === COST_ESTIMATE_DISPLAY_STATES.CANCELLATION_POLICY
    ) {
      return undefined;
    } else if (isDesktop) {
      return "728px";
    } else {
      return "calc(100vw - 50px)";
    }
  };

  return (
    <>
      {!providerIsLoading && (
        <ModalSheet
          isOpen={isScheduleModalOpen}
          onClose={onScheduleModalCloseWithTracking}
          placement={getModalPlacement(
            isMobile,
            displayState,
            showScheduleContent,
          )}
          size={
            displayState === COST_ESTIMATE_DISPLAY_STATES.CANCELLATION_POLICY &&
            !isMobile
              ? "md"
              : "lg"
          }
          title={
            !isScheduleModalOpen ||
            displayState === COST_ESTIMATE_DISPLAY_STATES.CANCELLATION_POLICY
              ? lookupModalTitle(t)[COST_ESTIMATE_DISPLAY_STATES[displayState]]
              : ""
          }
          onBack={handleBackButtonClick}
          backButton={shouldShowBackButton}
        >
          {showScheduleContent ? (
            <Box maxWidth="728px" minWidth={getMinWidth()} mx="auto">
              <CareProviderScheduleContent {...scheduleContentProps} />
            </Box>
          ) : (
            <PaymentInfoUpdateWrapper
              closeModal={transitionToScheduleModal}
              displayState={displayState}
              setDisplayState={setDisplayState}
              wrappedMemberInsuranceData={wrappedMemberInsuranceData}
              isMemberInsuranceLoading={providerIsLoading}
            />
          )}
        </ModalSheet>
      )}
    </>
  );
};
