import { setUserIdentifiers, verifyToken } from "actions/auth/actions";
import { datadogAddAction } from "lib/datadog-setup";
import {
  getCachedLocalStoreData,
  isParentLogged,
  PARENT_KEY,
} from "lib/rememberUser";
import { get } from "lodash/fp";
import { getInitialAndRecentAssessments } from "utils/assessments/assessmentUtils";
import { isT2Member, memberAgeRange } from "utils/memberHelpers";

const sleep = (delay) => new Promise((resolve) => setTimeout(resolve, delay));

let mixpanel = require("mixpanel-browser");

let session_id = Date.now();

if (process.env.NODE_ENV !== "jest" && process.env.MIXPANEL_KEY) {
  mixpanel.init(process.env.MIXPANEL_KEY, {
    track_pageview: false,
  });

  mixpanel.register({ session_id });
} else {
  // Here we construct a fake MP opject to use when MP is not initialized.
  mixpanel = {
    alias: () => {},
    identify: () => {},
    time_event: () => {},
    track: () => {},
    register: () => {},
    reset: () => {},
    get_property: () => {},
    people: {
      set: () => {},
    },
  };
}

export const alias = (id) => {
  mixpanel.alias(id);
};

const getMpCookie = () => {
  let data = document.cookie;
  let cookies = data.split("; ").reduce(function (obj, str) {
    let strParts = str.split("=");
    if (strParts[0] && strParts[1]) {
      //<-- Make sure the key & value are not undefined
      obj[strParts[0].replace(/\s+/g, "")] = strParts[1].trim(); //<-- Get rid of extra spaces at beginning of value strings
    }
    return obj;
  }, {});

  const mp_cookie = Object.entries(cookies).find(([k]) => k.startsWith("mp_"));
  return mp_cookie;
};

const resetMpCookieKeepDeviceId = (mp_cookie) => {
  const deviceIdRegex = /%24device_id%22%3A%20%22([0-9a-z-]+)/gm;

  const res = mp_cookie[1].match(deviceIdRegex);
  const currentDeviceId = res[0].split("%22%3A%20%22");

  resetSession();
  //keep old device id
  mixpanel.register({
    $device_id: currentDeviceId[1],
  });
};

let hitIdRaceConditionRetries = 0;
let stopRaceConditionRetry = false;

const handleRaceCondition = async (currentIdentifiedUserId, cachedParentId) => {
  if (currentIdentifiedUserId === cachedParentId && !stopRaceConditionRetry) {
    // Hit race condition, uuid on local is not yet updated
    // TODO: add datadog error handling
    // * hitIdRaceConditionRetries
    // * currentIdentifiedUserId
    // * cachedParentId
    // eslint-disable-next-line no-console
    console.error("Hit race condition");

    //identify again to wait for uuid update
    if (hitIdRaceConditionRetries <= 6) {
      hitIdRaceConditionRetries++;
      await sleep(10000);
      verifyToken().then((userIdentifiers) => {
        setUserIdentifiers(userIdentifiers);
        identifyUser(userIdentifiers);
      });
    } else {
      hitIdRaceConditionRetries++;
      stopRaceConditionRetry = true;
    }
  }
};

export const identifyUser = async (userIdentifiers) => {
  const id = get("user.id", userIdentifiers);

  const isDependent = (userIdentifiers) => {
    let dependentResult = get(
      "user.member.covered_life.dependent",
      userIdentifiers,
    );

    if (dependentResult === true) {
      return "Dependent";
    } else if (dependentResult === false) {
      return "Not Dependent";
    }

    return undefined;
  };

  const isParentLoggedIntoMinorAccount = userIdentifiers?.user?.member?.minor;

  if (isParentLogged() && isParentLoggedIntoMinorAccount) {
    await identifyParent(userIdentifiers);
    return;
  }

  const mp_cookie = getMpCookie();
  if (
    !isParentLogged() &&
    mp_cookie &&
    mp_cookie[1].includes("on_behalf_of_managed_user")
  ) {
    resetMpCookieKeepDeviceId(mp_cookie);
  }

  const assessments = userIdentifiers?.user?.member?.assessments || [];
  const [initialAssessment, mostRecentAssessment] =
    getInitialAndRecentAssessments(assessments);
  const ageRange = memberAgeRange(
    get("user.member.date_of_birth", userIdentifiers),
  );

  mixpanel.register({
    on_behalf_of_managed_user: false,
    cl_id: get("user.member.covered_life.id", userIdentifiers),
    customer_name: get("user.member.cohort.customer.name", userIdentifiers),
    customer_id: get("user.member.cohort.customer.id", userIdentifiers),
    cohort_id: get("user.member.cohort.id", userIdentifiers),
    member_id: get("user.member.id", userIdentifiers),
    member_risk: get("user.member.risk", userIdentifiers) || undefined,
    coaching_tier:
      get("user.member.coaching_tier", userIdentifiers) || undefined,
    dependent: isDependent(userIdentifiers),
    managed_minor_count: get(
      "user.member.managed_dependents.length",
      userIdentifiers,
    ),
    is_international: get("user.member.cohort.international", userIdentifiers),
    sud_supported: get(
      "user.member.cohort.contract_term.sud_supported",
      userIdentifiers,
    ),
    age_category: get("user.member.minor", userIdentifiers) ? "Minor" : "Adult",
    age_range: ageRange,
    in_network:
      get("user.member.cohort.in_network", userIdentifiers) || undefined,
    available_sessions: get(
      "user.member.visits_remaining_total",
      userIdentifiers,
    ),
    initial_risk_level: initialAssessment?.risk,
    most_recent_risk_level: mostRecentAssessment?.risk,
    initial_sud_risk: get("user.member.initial_sud_risk", userIdentifiers),
    latest_sud_risk: get("user.member.latest_sud_risk", userIdentifiers),
    is_t2_member: isT2Member(get("user.member", userIdentifiers)) || false,
    is_private_practice: get(
      "user.member.is_private_practice",
      userIdentifiers,
    ),
  });

  identify(id, {
    created_at: get("user.created_at", userIdentifiers),
    member_id: get("user.member.id", userIdentifiers),
  });

  return;
};

const identifyParent = async (userIdentifiers) => {
  const currentIdentifiedUserId = get("user.id", userIdentifiers);
  const cachedParentId = getCachedLocalStoreData(PARENT_KEY, "uuid");

  const isManagerDependent = (userIdentifiers) => {
    let dependentResult = get(
      "user.member.manager.covered_life.dependent",
      userIdentifiers,
    );

    if (dependentResult === true) {
      return "Dependent";
    } else if (dependentResult === false) {
      return "Not Dependent";
    }

    return undefined;
  };

  await handleRaceCondition(currentIdentifiedUserId, cachedParentId);

  const assessments = userIdentifiers?.user?.member?.assessments || [];
  const [initialAssessment, mostRecentAssessment] =
    getInitialAndRecentAssessments(assessments);
  const ageRange = memberAgeRange(
    get("user.member.date_of_birth", userIdentifiers),
  );

  mixpanel.register({
    on_behalf_of_managed_user: true,
    managed_user_id: currentIdentifiedUserId,
    managed_user_member_id: get("user.member.id", userIdentifiers),
    customer_name: get("user.member.cohort.customer.name", userIdentifiers),
    cl_id: get("user.member.covered_life.id", userIdentifiers),
    customer_id: getCachedLocalStoreData(PARENT_KEY, "customerId"),
    cohort_id: getCachedLocalStoreData(PARENT_KEY, "cohortId"),
    member_id: getCachedLocalStoreData(PARENT_KEY, "member_id"),
    dependent: isManagerDependent(userIdentifiers),
    managed_minor_count: get(
      "user.member.managed_dependents.length",
      userIdentifiers,
    ),
    is_international: get("user.member.cohort.international", userIdentifiers),
    sud_supported: get(
      "user.member.cohort.contract_term.sud_supported",
      userIdentifiers,
    ),
    age_category: get("user.member.minor", userIdentifiers) ? "Minor" : "Adult",
    age_range: ageRange,
    initial_risk_level: initialAssessment?.risk,
    most_recent_risk_level: mostRecentAssessment?.risk,
  });

  identify(getCachedLocalStoreData(PARENT_KEY, "uuid"));
};

export const identify = (id, attributes) => {
  mixpanel.identify(id);
  mixpanel.people.set({
    user_id: id,
    ...attributes,
  });
};

export const time_event = (event) => {
  mixpanel.time_event(event);
};

export const get_property = (property) => {
  return mixpanel.get_property(property);
};

// Duplicated in datadog-setup.js
const emailRegex = /email=[^&]*/;
const phoneRegex = /phone_number=[^&]*/;
const countryRegex = /country=[^&]*/;

const sanitizeQuery = (url) =>
  url
    .replace(new RegExp(emailRegex, "g"), "email=REDACTED")
    .replace(new RegExp(phoneRegex, "g"), "phone_number=REDACTED")
    .replace(new RegExp(countryRegex, "g"), "country=REDACTED");

export const track = (event, config) => {
  // check for empty strings on all params passed in and convert them to undefined before tracking
  let sanitizedConfig = {};
  Object.entries(config || {}).forEach(([key, value]) => {
    sanitizedConfig[key] = value || undefined;
  });

  mixpanel.track(event, {
    ...sanitizedConfig,
    $current_url: sanitizeQuery(window.location.href),
    //hoping this also cleans intial referrer, need to test
    $referrer: sanitizeQuery(document.referrer),
  });

  datadogAddAction(event + " MP", { ...sanitizedConfig });
};

export const register = (config) => {
  const options = Object.assign({ session_id }, config);

  mixpanel.register(options);
};

export const resetSession = () => {
  //Only reset cookie if user is identified
  const mp_cookie = getMpCookie();
  if (mp_cookie && mp_cookie[1].includes("member_id")) {
    mixpanel.reset();
  }

  session_id = Date.now();
  mixpanel.register({ session_id });
};

export const addCustomFlag = (flagName, flagSet = [], flagValue) => {
  const indexTrue = flagSet.indexOf(`${flagName}: true`);
  const indexFalse = flagSet.indexOf(`${flagName}: false`);

  if (indexTrue === -1 && indexFalse === -1) {
    flagSet.push(`${flagName}: ${flagValue}`);
  } else if (flagValue && indexFalse !== -1) {
    flagSet[indexFalse] = `${flagName}: true`;
  } else if (!flagValue && indexTrue !== -1) {
    flagSet[indexTrue] = `${flagName}: false`;
  }
};
