/**
 * A child component shown on a public page. The end session view appears after a
 * virtual session ends and prompts users to take a survey or schedule a session.
 */
import React, { FC, useEffect, useState } from "react";
import { useRouter } from "next/router";
import { useTranslation } from "react-i18next";
import {
  Box,
  Button,
  Flex,
  Heading,
  HStack,
  Link,
  Text,
} from "@springcare/sh-component-library";
import {
  feedbackFormUrl,
  navBarHeight,
} from "components/templates/VirtualSessionView/constants";
import { TRACK_EVENT } from "utils/mixpanel";
import { FLAGS, useFeatureFlag } from "utils/launchdarkly";

interface EndSessionViewProps {
  appointmentId: string;
}

const EndSessionView: FC<EndSessionViewProps> = ({ appointmentId }) => {
  const { t } = useTranslation("virtualSession");
  const router = useRouter();
  const schedule_appt_url = "/members/care_visits";
  const [trackedOnce, setTrackedOnce] = useState(false);
  const showFeedbackURL = useFeatureFlag(FLAGS.ENABLE_ZOOM_FEEDBACK_URL);

  useEffect(() => {
    if (!trackedOnce) {
      TRACK_EVENT.COMPONENT_VIEWED(window.location.pathname, "Zoom End Page", {
        appointment_id: appointmentId,
      });
      setTrackedOnce(true);
    }
  });

  const handleScheduleClick = () => {
    TRACK_EVENT.LINK_CLICKED(
      window.location.pathname,
      "Schedule Next Appointment",
      {
        appointment_id: appointmentId,
      },
    );
    router.push(schedule_appt_url);
  };

  return (
    <Flex
      w="100%"
      flex="1"
      justifyContent="center"
      bgGradient={{
        base: "none",
        md: "linear-gradient(90deg, transparent 0%, transparent 50%, background-subtle 50%, background-subtle 100%)",
      }}
      pt={navBarHeight}
      minH="100vh"
    >
      <Flex flex="1" maxW="1350px">
        <Flex
          direction="column"
          justifyContent="center"
          alignItems={"flex-start"}
          flex={1}
        >
          <Box m="36px">
            <Box>
              <Heading as="h1" mb="0.5rem" size="heading-medium">
                {t("endSession.sessionCompleted")}
              </Heading>
              <Text size="body-medium-regular" mb="1.5rem">
                {t("endSession.surveyEmail")}
              </Text>
              <Text size="body-medium-regular" mb="1.5rem">
                {t("endSession.bookNextAppointment")}
              </Text>
            </Box>
            <Button
              variant="high-emphasis"
              onClick={handleScheduleClick}
              role="link"
              _focus={{ outline: "0px solid transparent !important" }}
              _focusVisible={{ outline: "2px solid black !important" }}
            >
              {t("endSession.scheduleBtn")}
            </Button>
            {showFeedbackURL && (
              <HStack mt="1rem">
                <Text size="body-medium-regular">
                  {t("endSession.helpImprove")}
                </Text>
                <Link href={feedbackFormUrl} isExternal>
                  {t("endSession.provideFeedback")}
                </Link>
              </HStack>
            )}
          </Box>
        </Flex>
        <Flex
          display={{ md: "flex", base: "none" }}
          flex="1"
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Box maxW={500}>
            <img
              src="/static/celebration.svg"
              alt="Celebration illustration"
              role="presentation"
            />
          </Box>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default EndSessionView;
