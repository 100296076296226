import { useRef, useEffect } from "react";
import { links } from "@spring/constants";
import { Flex, Box, Button } from "@springcare/sh-component-library";
import { FLAGS, useFeatureFlag } from "utils/launchdarkly";
import SessionDetailsContainer from "components/templates/SHSessionRoom/components/SessionDetailsContainer/SessionDetailsContainer";
import SessionDetailsHeader from "components/templates/SHSessionRoom/components/SessionDetailsContainer/SessionDetailsHeader";
import SessionDetailsBody from "components/templates/SHSessionRoom/components/SessionDetailsContainer/SessionDetailsBody";
import VideoPreviewSection from "components/templates/SHSessionRoom/components/VideoPreviewSection";
import SessionConsentJoinAlert from "components/templates/SHSessionRoom/components/SessionConsentJoin";
import {
  useSessionViewStatus,
  useSessionConnectStatus,
  useSessionDevices,
  useParticipantMetadata,
} from "context/SessionRoomContext";
import {
  trackPageVersionViewed,
  trackStartSessionClick,
  trackContactSupportClicked,
  trackBrowserPermissionsInstructionsClicked,
} from "components/templates/SHSessionRoom/telemetry/analytics";
import {
  SessionViewStatus,
  ParticipantType,
} from "components/templates/SHSessionRoom/types";
import { useTranslation } from "hooks/react-i18next";

const PreJoin = ({ sessionData, isMobile, appointmentId }) => {
  const { t } = useTranslation("livekitExperience", {
    keyPrefix: "preJoin",
  });
  const timerRef = useRef(null);
  const { sessionViewStatus, setSessionViewStatus } = useSessionViewStatus();
  const { setShouldConnect, setSkipSetStatus } = useSessionConnectStatus();
  const { sessionDevices } = useSessionDevices();
  const enableConsentsWaitingRoom =
    useFeatureFlag(FLAGS.ENABLE_CONSENTS_WAITING_ROOM_LIVEKIT) &&
    sessionDevices?.stream;
  const { participantType } = useParticipantMetadata();

  // Grace period is 10 minutes (600K milliseconds)
  const PROVIDER_GRACE_PERIOD = 600_000;

  useEffect(() => {
    trackPageVersionViewed("pre_join");
  }, []);

  useEffect(() => {
    if (sessionViewStatus === SessionViewStatus.MemberIsWaiting) {
      // sets a Grace Period timer for Provider, when a Member requests to join room
      timerRef.current = setTimeout(() => {
        setSessionViewStatus(SessionViewStatus.ProviderNoShow);
      }, PROVIDER_GRACE_PERIOD);
    }

    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
    };
  }, [sessionViewStatus]);

  const handleJoinMeeting = () => {
    setShouldConnect(true);
    setSessionViewStatus(SessionViewStatus.Connecting);
    trackStartSessionClick();
  };

  const handleCancelJoinMeeting = () => {
    // NOTE: We need to reload here to disconnect from livekit
    // doing this reconnects, and resets the Member's permissions
    // which allows them to ask permission again, if they want

    // eslint-disable-next-line no-self-assign
    window.location.href = window.location.href;
  };

  const handleContactSupport = (action: string) => {
    setSkipSetStatus(true);
    trackContactSupportClicked(action);
    window.open(links.Support, "_blank");
  };

  return (
    <SessionDetailsContainer isMobile={isMobile}>
      <VideoPreviewSection isMobile={isMobile} />

      <Flex
        w={["100%", "100%", "v-4xl", "v-4xl"]}
        flexDir="column"
        mb="v-8"
        alignSelf="center"
      >
        <SessionDetailsHeader isMobile={isMobile} />

        <SessionDetailsBody
          sessionData={sessionData}
          appointmentId={appointmentId}
        >
          <Box>
            {enableConsentsWaitingRoom &&
            sessionViewStatus != SessionViewStatus.BrowserPermissionsDenied &&
            sessionViewStatus != SessionViewStatus.ProviderNoShow ? (
              <SessionConsentJoinAlert
                handleJoin={handleJoinMeeting}
                appointmentId={appointmentId}
              />
            ) : (
              <>
                {sessionViewStatus !== SessionViewStatus.ProviderNoShow &&
                  sessionViewStatus !=
                    SessionViewStatus.BrowserPermissionsDenied && (
                    <Button
                      w="100%"
                      variant="high-emphasis"
                      onClick={handleJoinMeeting}
                      isLoading={
                        sessionViewStatus === SessionViewStatus.Connecting ||
                        sessionViewStatus === SessionViewStatus.MemberIsWaiting
                      }
                      disabled={!sessionDevices.stream}
                      _focus={{ outline: "0px solid transparent !important" }}
                      _focusVisible={{ outline: "2px solid black !important" }}
                    >
                      {participantType === ParticipantType.Provider
                        ? t("startSession")
                        : t("joinSession")}
                    </Button>
                  )}
              </>
            )}
            {sessionViewStatus === SessionViewStatus.MemberIsWaiting && (
              <Button
                paddingTop={4}
                w="100%"
                mt={16}
                variant="low-emphasis"
                onClick={handleCancelJoinMeeting}
                _focus={{ outline: "0px solid transparent !important" }}
                _focusVisible={{ outline: "2px solid black !important" }}
              >
                {t("cancel")}
              </Button>
            )}

            {sessionViewStatus === SessionViewStatus.ProviderNoShow && (
              <Button
                w="100%"
                variant="high-emphasis"
                onClick={() => handleContactSupport("Provider No Show")}
                _focus={{ outline: "0px solid transparent !important" }}
                _focusVisible={{ outline: "2px solid black !important" }}
              >
                {t("contactSupport")}
              </Button>
            )}
            {sessionViewStatus ===
              SessionViewStatus.BrowserPermissionsDenied && (
              <>
                <Button
                  w="100%"
                  variant="high-emphasis"
                  onClick={() => {
                    trackBrowserPermissionsInstructionsClicked();
                    window.open(links.BrowserPermissionsSupportPage, "_blank");
                  }}
                  mb="v-16"
                  _focus={{ outline: "0px solid transparent !important" }}
                  _focusVisible={{ outline: "2px solid black !important" }}
                >
                  {t("browserPermissionsDeniedCopy.instructions")}
                </Button>

                <Button
                  w="100%"
                  variant="low-emphasis"
                  onClick={() => handleContactSupport("Browser Permissions")}
                  _focus={{ outline: "0px solid transparent !important" }}
                  _focusVisible={{ outline: "2px solid black !important" }}
                >
                  {t("browserPermissionsDeniedCopy.needHelp")}
                </Button>
              </>
            )}
          </Box>
        </SessionDetailsBody>
      </Flex>
    </SessionDetailsContainer>
  );
};

export default PreJoin;
