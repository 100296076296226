import {
  Box,
  Flex,
  Text,
  Avatar,
  Divider,
} from "@springcare/sh-component-library";
import { useParticipants } from "@livekit/components-react";
import { useParticipantMetadata } from "context/SessionRoomContext";
import { useTranslation } from "hooks/react-i18next";

const AttendeeSection = () => {
  const participants = useParticipants();
  const { localParticipantName, remoteParticipantName } =
    useParticipantMetadata();
  const { t } = useTranslation("livekitExperience", {
    keyPrefix: "sessionRoomDrawer.attendeeSection",
  });

  return (
    <Box px="v-24" pt="v-24">
      <Box>
        <Text size="body-medium-strong">{t("inSession")}</Text>
      </Box>
      {participants.map((participant) => {
        const name = participant.isLocal
          ? localParticipantName
          : remoteParticipantName;
        return (
          <Box key={participant?.sid}>
            <Flex py="v-20" px="v-4">
              <Avatar name={name ?? t("guest")} />
              <Text ml="v-8" size="body-medium">
                {name ?? t("guest")}
              </Text>
            </Flex>
            <Divider w="100%" />
          </Box>
        );
      })}
    </Box>
  );
};

export default AttendeeSection;
