import { useRef, useEffect } from "react";
import { Flex, Text, Box, AspectRatio } from "@springcare/sh-component-library";
import { datadogAddAction } from "lib/datadog-setup";
import CameraPlaceholder from "components/templates/SHSessionRoom/components/ParticipantTile/CameraPlaceholder";
import DeviceMenuSection from "components/templates/SHSessionRoom/components/DeviceMenuSection";
import PreJoinVideoCallControls from "components/templates/SHSessionRoom/components/PreJoinVideoCallControls";
import { SessionViewStatus } from "components/templates/SHSessionRoom/types";
import {
  useSessionDevices,
  useSessionViewStatus,
} from "context/SessionRoomContext";

import {
  trackDeviceToggleState,
  trackBrowserPermissionsModalOpened,
  trackBrowserPermissionsSet,
} from "components/templates/SHSessionRoom/telemetry/analytics";
import { ToggleButtonTypes } from "components/templates/SHSessionRoom/types";
import { useTranslation } from "hooks/react-i18next";

const VideoPreviewSection = ({ isMobile }) => {
  const videoRef = useRef(null);
  const { sessionDevices, setSessionDevices } = useSessionDevices();
  const { t } = useTranslation("livekitExperience", {
    keyPrefix: "videoPreviewSection",
  });
  const { sessionViewStatus, setSessionViewStatus } = useSessionViewStatus();

  const startMedia = async () => {
    // NOTE: this is implicit, as Safari/Firefox do not allow use of Permissions API
    // This event should only be used for analysis at the beginning of a user flow
    // as past the first acceptance/denial, this event will not be accurate
    trackBrowserPermissionsModalOpened();
    const constraints = {
      video: {
        width: isMobile ? { ideal: 1080 } : { ideal: 1920 },
        height: isMobile ? { ideal: 1920 } : { ideal: 1080 },
        aspectRatio: { exact: 1.777 },
      },
      audio: true,
    };
    // called onload and requests access to camera and microphone
    // if given access, sets the stream to the video element and starts audio detection
    try {
      const stream = await navigator.mediaDevices.getUserMedia(constraints);
      videoRef.current.srcObject = stream;

      setSessionDevices((prevState) => ({
        ...prevState,
        stream,
        isCameraEnabled: constraints.video ? true : prevState.isCameraEnabled,
        isMicrophoneEnabled: constraints.audio
          ? true
          : prevState.isMicrophoneEnabled,
      }));
      // NOTE: this is implicit, as Safari/Firefox do not allow use of Permissions API
      // This event should only be used for analysis at the beginning of a user flow
      // as past the first acceptance/denial, this event will not be accurate
      trackBrowserPermissionsSet(true, ["camera", "microphone"]);
    } catch (e) {
      setSessionViewStatus(SessionViewStatus.BrowserPermissionsDenied);
      // NOTE: this is implicit, as Safari/Firefox do not allow use of Permissions API
      trackBrowserPermissionsSet(false, ["camera", "microphone"]);
      datadogAddAction("session_stream_start_error", {
        error: e,
        trackType: "both",
      });
      return;
    }
  };

  const toggleVideo = () => {
    if (sessionDevices.stream) {
      const newCameraState = !sessionDevices.isCameraEnabled;
      trackDeviceToggleState(
        ToggleButtonTypes.Camera,
        newCameraState,
        "pre_join",
      );
      setSessionDevices((prevState) => ({
        ...prevState,
        isCameraEnabled: !prevState.isCameraEnabled,
      }));
    }
  };

  const toggleAudio = async () => {
    if (sessionDevices.stream) {
      const newMicrophoneState = !sessionDevices.isMicrophoneEnabled;
      trackDeviceToggleState(
        ToggleButtonTypes.Microphone,
        newMicrophoneState,
        "pre_join",
      );
      setSessionDevices((prevState) => ({
        ...prevState,
        isMicrophoneEnabled: !prevState.isMicrophoneEnabled,
      }));
    }
  };

  useEffect(() => {
    startMedia();
    return () => {
      if (videoRef.current?.srcObject) {
        const tracks = (videoRef.current.srcObject as MediaStream).getTracks();
        tracks.forEach((track) => track.stop());
      }
    };
  }, []);

  return (
    <Flex flex={1} mr={[0, 0, 40, 40]} direction="column" alignItems="center">
      <Box
        w={["100%", "100%", 688, 688]}
        mb="v-8"
        pt={isMobile ? "v-none" : "v-64"}
      >
        <Box pos="relative">
          {sessionViewStatus === SessionViewStatus.BrowserPermissionsDenied ? (
            <Flex
              borderRadius={16}
              justify="center"
              align="center"
              bg="black"
              overflow="hidden"
              sx={{ aspectRatio: "16 / 9" }}
            >
              <Text color="white">{t("noPreview")}</Text>
            </Flex>
          ) : (
            <>
              <Box
                display={sessionDevices.isCameraEnabled ? "none" : "block"}
                borderTopRightRadius={16}
                borderTopLeftRadius={16}
                sx={{ aspectRatio: "16 / 9" }}
                overflow="hidden"
              >
                <CameraPlaceholder borderRadius="v-none" />
              </Box>

              <AspectRatio
                display={sessionDevices.isCameraEnabled ? "block" : "none"}
                ratio={16 / 9}
              >
                <video
                  ref={videoRef}
                  autoPlay
                  muted
                  playsInline
                  controls={false}
                  aria-label="Video preview"
                  style={{
                    borderTopRightRadius: "16px",
                    borderTopLeftRadius: "16px",
                    transform: "scaleX(-1)",
                  }}
                />
              </AspectRatio>
              <PreJoinVideoCallControls
                isMobile={isMobile}
                isCameraEnabled={sessionDevices.isCameraEnabled}
                isMicrophoneEnabled={sessionDevices.isMicrophoneEnabled}
                toggleVideo={toggleVideo}
                toggleAudio={toggleAudio}
              />
            </>
          )}
        </Box>
      </Box>

      <Flex
        direction="row"
        w="100%"
        p="v-2"
        justify="space-evenly"
        overflowX="visible"
        hidden={isMobile}
      >
        <DeviceMenuSection />
      </Flex>
    </Flex>
  );
};

export default VideoPreviewSection;
