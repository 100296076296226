import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { getOr } from "lodash/fp";
import { useTranslation } from "hooks/react-i18next";
import {
  AppointmentMedium,
  AppointmentKind,
  isTherapy,
  isMedicationManagement,
  getAppointmentKindForTracking,
  getAppointmentMediumForTracking,
  isCoaching,
} from "@spring/constants";
import { openModal } from "@spring/smeargle/actions";
import { useMemberInfo } from "hooks";
import { DateTime } from "luxon";
import {
  getShouldShowPaymentCopy,
  formatAppointmentTime,
} from "components/templates/CareVisitDetails/utils";
import {
  PaymentDetails,
  VideoCopy,
  InPersonCopy,
  PhoneCopy,
  MakeChangeCopy,
  CancellationPolicy,
  NoShowTermsCopy,
  RebookCopy,
} from "components/templates/CareVisitDetails/components/ConfirmationCopy";
import { Box, Link } from "@springcare/sh-component-library";
import { FLAGS, useFeatureFlag } from "utils/launchdarkly";
import { useInViewportOnce } from "hooks/useInViewport";
import { TRACK_EVENT } from "utils/mixpanel";
import envUtils from "utils/environment";

const ConfirmationCopy = ({
  cancelled,
  kind,
  medium,
  time,
  isCoveredForMember,
  openCouplesTherapyModal,
  ...props
}) => {
  const dispatch = useDispatch();
  const { data: memberInfo, memberInfoLoading } = useMemberInfo();
  const handleDispatchOpenModal = (modalId, data) =>
    dispatch(openModal(modalId, data));
  const { t } = useTranslation("careVisits");
  const isTherapyAppt = isTherapy(kind);

  const isCoachingProvider = isCoaching(kind);
  const couplesTherapyFlag = useFeatureFlag(FLAGS.ENABLE_COUPLES_THERAPY);
  const showCouplesTherapy = couplesTherapyFlag && !props.isAMinor;
  const isCouplesTherapy = kind === AppointmentKind.CouplesTherapy;
  const isMedicationManager = isMedicationManagement(kind);
  const showCancellationPolicy =
    isMedicationManager || isTherapyAppt || isCoachingProvider;
  const couplesSessionInfoRef = useRef();
  const couplesSessionInfoInViewport = useInViewportOnce(couplesSessionInfoRef);
  const [couplesSessionInfoViewedOnce, setCouplesSessionInfoViewedOnce] =
    useState(false);

  useEffect(() => {
    if (couplesSessionInfoInViewport && !couplesSessionInfoViewedOnce) {
      TRACK_EVENT.COMPONENT_VIEWED(
        window.location.pathname,
        "Learn about couples sessions button",
        {
          location: "Appointment details page",
          spring_doc_id: "couples_therapy_006",
          appointment_kind: getAppointmentKindForTracking(kind),
          appointment_medium: getAppointmentMediumForTracking(medium),
        },
      );
      setCouplesSessionInfoViewedOnce(true);
    }
  }, [couplesSessionInfoInViewport, couplesSessionInfoViewedOnce]);

  const { member } = getOr({}, "user", memberInfo);
  const { cohort } = getOr({}, "user.member", memberInfo);
  const { id: customerId } = getOr(
    {},
    "user.member.cohort.customer",
    memberInfo,
  );
  const member_cost_of_coaching =
    cohort?.contract_term?.member_cost_of_coaching;

  // This is to ensure rendering of the NoShowTerms Copy being utilized by very few customers
  const apptDate = DateTime.fromISO(time).toFormat("yyyy-LL-dd");
  const noShowTermStartDate =
    member?.cohort?.contract_term?.no_show_term?.start_date;
  const apptAfterNoShowTermStartDate = apptDate >= noShowTermStartDate;
  const apptIsTherapyAndWithinNoShowTerms =
    isTherapyAppt && apptAfterNoShowTermStartDate;
  const showNoShowTermsCopyEnabled =
    member?.cohort?.customer?.enable_member_no_show_policy &&
    member?.cohort?.contract_term?.no_show_term?.enable_members_pay &&
    apptIsTherapyAndWithinNoShowTerms;

  const handleCouplesSessionsLearnMoreClick = () => {
    openCouplesTherapyModal();

    TRACK_EVENT.BUTTON_CLICKED(
      window.location.pathname,
      "Learn about couples sessions button",
      {
        location: "Appointment details page",
        spring_doc_id: "couples_therapy_007",
        appointment_kind: getAppointmentKindForTracking(kind),
        appointment_medium: getAppointmentMediumForTracking(medium),
      },
    );
  };

  const directQuestionsToProvider =
    member?.is_private_practice || isCoachingProvider;
  // coaches still email care team.
  const makeChangeEmail = member?.is_private_practice
    ? props.provider?.email
    : envUtils.careTeamEmail;

  return (
    <div aria-busy={memberInfoLoading}>
      {member && cohort && (
        <>
          {!cancelled && (
            <>
              {medium === AppointmentMedium.Video && (
                <VideoCopy time={time} {...props} />
              )}
              {medium === AppointmentMedium.InPerson && (
                <InPersonCopy time={time} {...props} />
              )}
              {medium === AppointmentMedium.Phone && (
                <PhoneCopy time={time} {...props} />
              )}
              {getShouldShowPaymentCopy(kind) && (
                <>
                  <PaymentDetails
                    openModal={handleDispatchOpenModal}
                    coaching={isCoachingProvider}
                    therapy={isTherapyAppt}
                    isCoveredForMember={isCoveredForMember}
                    isMedicationManager={isMedicationManager}
                    appointmentId={
                      props.appointmentId ? props.appointmentId : null
                    }
                    member={member}
                    kind={kind}
                    medium={medium}
                    time={time}
                    isDedicatedCustomer={
                      !!props.provider?.dedicated_customer?.name
                    }
                    customerId={customerId}
                    memberCostOfCoaching={member_cost_of_coaching}
                  />
                  {isCouplesTherapy && showCouplesTherapy && (
                    <Box mb={20} ref={couplesSessionInfoRef}>
                      <Link
                        as="button"
                        onClick={handleCouplesSessionsLearnMoreClick}
                        data-testid="coupleSessionLearnMore"
                      >
                        {t("appointmentDetails.couplesTherapy.learnAboutLink")}
                      </Link>
                    </Box>
                  )}
                </>
              )}

              {showCancellationPolicy && (
                <CancellationPolicy
                  defaultCopy={t("cancelAppointment.avoidCancellationFee")}
                  showViewPolicyCTA={true}
                  showDollarIcon={false}
                />
              )}
              {showNoShowTermsCopyEnabled && <NoShowTermsCopy />}
              {isTherapyAppt && (
                <RebookCopy
                  bookAnotherAppointmentCb={props.bookAnotherAppointmentCb}
                />
              )}

              <MakeChangeCopy
                time={formatAppointmentTime(time)}
                directQuestionsToProvider={directQuestionsToProvider}
                contactEmail={makeChangeEmail}
              />
            </>
          )}
        </>
      )}
    </div>
  );
};

ConfirmationCopy.propTypes = {
  cancelled: PropTypes.bool.isRequired,
  duration: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  kind: PropTypes.string.isRequired,
  location: PropTypes.string,
  appointmentId: PropTypes.string,
  medium: PropTypes.string.isRequired,
  provider: PropTypes.shape({
    name: PropTypes.string,
  }).isRequired,
  time: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  memberInfo: PropTypes.any.isRequired,
  memberCohort: PropTypes.any.isRequired,
  openCouplesTherapyModal: PropTypes.func,
};

ConfirmationCopy.defaultProps = {
  location: "",
  memberInfo: {},
  memberCohort: {},
};

export default ConfirmationCopy;
