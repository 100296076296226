import { useTranslation } from "react-i18next";
import { Box, Text, Link } from "@springcare/sh-component-library";
import { formatPhoneNumber } from "components/templates/Browse/ProviderBrowsePage/utils";

export const Consultations = ({ provider, isCard = false }) => {
  const { t } = useTranslation("careProvider");
  const hasPhone = provider?.phone?.length >= 10;
  if (!hasPhone) return null;

  return (
    <Box
      pt={isCard && 24}
      textAlign={{ base: "center", sm: "start" }}
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <Text size={isCard && "body-medium-strong"} as="span">
        {t("consultation.starWith")}
      </Text>
      <Text size="body-medium" as="span">
        {t("consultation.calling", {
          // @ts-ignore
          name: provider?.first_name,
        })}
      </Text>
      <Link
        color="content-primary"
        sx={{ "text-decoration": "none" }}
        href={`tel:${provider?.phone}`}
      >
        {formatPhoneNumber(provider?.phone)}
      </Link>
      <Text size="body-medium" as="span">
        {t("consultation.orBy")}
      </Text>
      <Link href={`mailto:${provider?.email}`}>
        {t("consultation.emailing")}
      </Link>
    </Box>
  );
};
