import React, { Component } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { compose } from "redux";
import { connect } from "react-redux";
import { DateTime } from "luxon";
import {
  Fab,
  FlexRow,
  InputWrapper,
  Stout,
  Label,
  LoadingCircle,
  Section,
} from "@spring/smeargle";
import { setField } from "@spring/smeargle/actions";
import debounce from "lodash/debounce";

// @TODO: There is a bug in babel where importing `export * from`
//        does not compile to `require()` but stays `import`
//        These import the direct path to fix breaking tests
//        the `/actions` and `/types` should be removed in the future
import styles from "./styles.module.scss";
import Slot from "components/form/Slot";
import { withLazyQuery } from "./withLazyQuery";

import { formFieldSelector } from "selectors/form";
import { getAppointmentSlots } from "operations/queries/appointment";
import { track } from "utils/mixpanel";
import {
  trackTimeSlotInModalClicked,
  trackAvailabileAppointmentsViewed,
  trackSlotPaginationButtonClicked,
} from "components/templates/Browse/ProviderBrowsePage/analytics";

/**
 * Calculate the span of appointment slots to display based on the start date, the span (or how
 * many days to display), and adjustments, if any. We substract 1 because we want to include the
 * the start date in the span of dates to display.
 */
const calculateEndOfSpan = (start, span, adjustment = 0) =>
  DateTime.fromISO(start)
    .plus({ days: adjustment + span - 1 })
    .set({ hours: 23, minutes: 59, seconds: 59, milliseconds: 0 });

class AppointmentSlots extends Component {
  constructor(props) {
    super(props);

    this.fetchMore = this.fetchMore.bind(this);
    this.fetchMoreDebounced = debounce(this.fetchMore, 1000);
  }

  static defaultProps = {
    disabled: false,
    placeholder: "",
    type: "text",
    stripe: true,
  };

  state = {
    adjustment: 0,
    end_span: null,
    start_span: null,
    loading: false,
  };

  componentDidMount() {
    const urlParams = new URLSearchParams(window.location.search);
    const localISO = urlParams.get("selectedDateTime");

    if (!localISO) {
      return;
    }

    this.props.setField(
      this.props.formKey,
      this.props.fieldKey,
      { selectedDateTime: localISO, selectedMedium: this.props.medium },
      true, // sets dirty
    );

    this.updateStart();
  }

  updateStart(newStart) {
    const urlParams = new URLSearchParams(window.location.search);
    const localISO = urlParams.get("selectedDateTime") || newStart;

    if (!localISO) {
      return;
    }

    const initStart = DateTime.fromISO(this.props.start).set({
      hours: 0,
      minutes: 0,
      seconds: 0,
      milliseconds: 1,
    });
    const toStart = DateTime.fromISO(localISO).set({
      hours: 0,
      minutes: 0,
      seconds: 0,
      milliseconds: 1,
    });

    const end = calculateEndOfSpan(localISO, this.props.dayIncrement, 0);

    const adjustment = toStart.diff(initStart, "days").days;

    this.setState(
      {
        adjustment: adjustment,
        start_span: toStart,
        end_span: end,
        loading: true,
      },
      this.fetchMoreDebounced,
    );
  }

  componentDidUpdate(prevProps) {
    if (prevProps.start && prevProps.start !== this.props.start) {
      this.updateStart(this.props.start);
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    const available = this.props?.data?.appointment_slots?.available;
    // eslint-disable-next-line react/prop-types
    const nextAvailable = nextProps?.data?.appointment_slots?.available;

    const blocked = this.props?.data?.appointment_slots?.blocked?.[0];
    // eslint-disable-next-line react/prop-types
    const nextBlocked = nextProps?.data?.appointment_slots?.blocked?.[0];

    return (
      nextProps.data.loading !== this.props.data.loading ||
      nextAvailable !== available ||
      nextBlocked !== blocked ||
      nextProps.value !== this.props.value ||
      nextState.loading !== this.state.loading ||
      nextProps.start !== this.props.start
    );
  }

  componentWillUnmount() {
    this.fetchMoreDebounced.cancel();
  }

  handleChange = (dateTime) => {
    this.props.setField(
      this.props.formKey,
      this.props.fieldKey,
      { selectedDateTime: dateTime, selectedMedium: this.props.medium },
      true, // sets dirty
    );
  };

  getMore = (change) => {
    const adjustment = this.state.adjustment + change;
    const start = DateTime.fromISO(this.props.start)
      .set({ hours: 0, minutes: 0, seconds: 0, milliseconds: 1 })
      .plus({ days: adjustment });
    const end = calculateEndOfSpan(
      this.props.start,
      this.props.dayIncrement,
      adjustment,
    );

    this.setState(
      {
        adjustment,
        start_span: start,
        end_span: end,
        loading: true,
      },
      this.fetchMoreDebounced,
    );
  };

  fetchMore() {
    const { end_span, start_span } = this.state;

    this.props.data.fetchMore({
      variables: {
        end_span: end_span.toISO(),
        start_span: start_span.toISO(),
      },
    });

    this.setState({ loading: false });
  }

  get label() {
    if (this.props.label) {
      return (
        <Label
          disabled={this.props.disabled}
          formKey={this.props.formKey}
          fieldKey={this.props.fieldKey}
          theme={this.props.theme}
        >
          {this.props.label}
        </Label>
      );
    }

    return null;
  }

  get showValidation() {
    return !this.props.valid && (this.props.dirty || this.props.value);
  }

  get slots() {
    const days = {};

    if (this.state.loading || this.props.data.loading) {
      return <LoadingCircle />;
    }

    let display = DateTime.fromISO(this.props.start).plus({
      days: this.state.adjustment,
    });

    for (let i = 0; i < this.props.dayIncrement; i++) {
      const index = `${display.month}/${display.day}`;
      days[index] = {
        display: display.setLocale(this.props.locale).toFormat("EEE, LLL dd"),
        days: [],
      };
      display = display.plus({ days: 1 });
    }

    const available =
      this.props.data.appointment_slots &&
      this.props.data.appointment_slots.available;

    if (available) {
      available.forEach((appt, idx) => {
        if (
          (idx === 0 && // Track the first available time slot
            this.state.adjustment === 0) ||
          (idx === 2 && // Track the third available time slot
            this.state.adjustment === 0)
        ) {
          // Only track from the first 3 days

          const firstAvailableSlot = DateTime.fromISO(appt);
          const currentDateTimeIso = DateTime.local().toUTC();
          const diff = firstAvailableSlot.diff(currentDateTimeIso, "minutes");

          trackAvailabileAppointmentsViewed({
            index: idx,
            providerId: this.props?.providerId,
            kind: this.props.kind,
            medium: this.props.medium,
            appointment: appt,
            modalId: this.props.modal_id,
            timeDifference: diff.minutes,
            isProfilePage: window.location.pathname.includes(
              "/members/providers/",
            ),
          });
        }

        const dateTime = DateTime.fromISO(appt);
        const localTime = dateTime.toLocal();
        const index = `${localTime.month}/${localTime.day}`;
        const localIso = localTime.toISO();
        const time = dateTime.toFormat("t");

        // If the day is past member termination date, exclude dates
        const terminateAt = this.props.terminateAt
          ? DateTime.fromISO(this.props.terminateAt)
          : null;
        if (
          terminateAt &&
          terminateAt.setZone(DateTime.local().zoneName) < dateTime
        ) {
          return;
        }

        if (days[index]) {
          if (
            (!this.props.limit || days[index].days.length < this.props.limit) &&
            DateTime.fromISO(appt) > DateTime.local().toUTC()
          ) {
            days[index].days.push(
              <Slot
                key={time}
                active={this.props.value.selectedDateTime === localIso}
                day={days[index].display}
                time={time}
                dataCy="new-appointments"
                onClick={(e) => {
                  e.preventDefault();
                  this.handleChange(localIso); // Convert to ISO to keep the local timezone

                  // directsched029
                  trackTimeSlotInModalClicked({
                    appointmentKind: this?.props?.kind,
                    appointmentMedium: this?.props?.medium,
                    provider: this?.props?.provider,
                    startTime: appt,
                    modalId: this?.props?.modal_id,
                    queryRequestId: this?.props?.queryRequestId,
                    isProfilePage: window.location.pathname.includes(
                      "/members/providers/",
                    ),
                  });
                }}
              />,
            );
          }
        }
      });
    }

    const daySlots = [];
    let i = 0;

    // Draw the day header and then the slots
    for (let day in days) {
      if (
        this.props.limit &&
        this.props.showMore &&
        days[day].days.length >= this.props.limit
      ) {
        days[day].days.push(
          <Slot
            key={`${days[day].display}-more`}
            day=""
            time="More"
            onClick={(e) => {
              e.preventDefault();
              this.props.moreAction();
              track("Schedule Therapy Visit -- Clicked Time Slot", {
                deprecated: true,
                replaced_with: "N/A",
              });
            }}
          />,
        );
      }

      const daysDisplay = days[day].days.length
        ? days[day].days
        : "Unavailable";

      daySlots.push(
        <div
          key={day}
          className={classnames(styles.column, {
            [styles.odd]: this.props.stripe && i % 2,
            [styles.basic]: !this.props.stripe,
          })}
        >
          <Section size={"dayHeader"}>
            <Stout dark semibold>
              {days[day].display}
            </Stout>
          </Section>

          <div className={styles.dayDisplay}>
            <Stout>{daysDisplay}</Stout>
          </div>
        </div>,
      );

      i++;
    }

    return <div className={styles.slotsContainer}>{daySlots}</div>;
  }

  render() {
    return (
      <InputWrapper
        valid={this.showValidation}
        theme={this.props.theme}
        for="AppointmentSlots"
      >
        {this.label}
        <div className={styles.calendar}>
          <FlexRow justification="space-between" alignment="flex-start">
            <Fab
              ariaLabel="See previous availabilities"
              size="lg"
              icon="arrow-left"
              onClick={(e) => {
                e.stopPropagation();
                this.getMore(-this.props.dayIncrement);

                // Mixpanel Event
                trackSlotPaginationButtonClicked({
                  directionName: "Left Arrow",
                  providerId: this.props.providerId,
                  appointmentKind: this.props.kind,
                  appointmentMedium: this.props.medium,
                  modalId: this.props.modal_id,
                });
              }}
              square
              flat
            />
            {this.slots}
            <Fab
              ariaLabel="See next availabilities"
              size="lg"
              icon="arrow-right"
              dataCy="schedule-modal-next-page"
              onClick={(e) => {
                e.stopPropagation();
                this.getMore(this.props.dayIncrement);

                // Mixpanel Event
                trackSlotPaginationButtonClicked({
                  directionName: "Right Arrow",
                  providerId: this.props.providerId,
                  appointmentKind: this.props.kind,
                  appointmentMedium: this.props.medium,
                  modalId: this.props.modal_id,
                });
              }}
              square
              flat
            />
          </FlexRow>
        </div>
      </InputWrapper>
    );
  }
}

AppointmentSlots.propTypes = {
  dirty: PropTypes.bool,
  disabled: PropTypes.bool,
  fieldKey: PropTypes.string,
  formKey: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  setField: PropTypes.func,
  value: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
    PropTypes.string,
    PropTypes.number,
  ]),
  placeholder: PropTypes.string,
  type: PropTypes.string,
  clientValidation: PropTypes.shape({
    message: PropTypes.string,
    valid: PropTypes.bool,
  }),
  theme: PropTypes.oneOf(["material", "simple"]),
  valid: PropTypes.bool,
  close: PropTypes.func,
  start: PropTypes.string,
  end: PropTypes.string,
  increment: PropTypes.number,
  bookings: PropTypes.arrayOf(PropTypes.string),
  dayIncrement: PropTypes.number,
  terminateAt: PropTypes.string,
  kind: PropTypes.string,
  medium: PropTypes.string,
  limit: PropTypes.number, // limit results to showing <number> slots per day
  showMore: PropTypes.bool, // if limit is reached, show more slot action
  moreAction: PropTypes.func,
  requestAvailability: PropTypes.func,
  stripe: PropTypes.bool,
  consent: PropTypes.any,
  data: PropTypes.shape({
    loading: PropTypes.bool,
    fetchMore: PropTypes.func,
    appointment_slots: PropTypes.shape({
      available: PropTypes.arrayOf(PropTypes.string),
      blocked: PropTypes.arrayOf(PropTypes.string),
    }),
  }),
  provider: PropTypes.object,
  providerId: PropTypes.string,
  t: PropTypes.any,
  isACareNavigator: PropTypes.bool,
  modal_id: PropTypes.string,
  queryRequestId: PropTypes.string,
};

export { AppointmentSlots };
export default compose(
  connect((state, ownProps) => formFieldSelector(state, ownProps), {
    setField,
  }),
  withLazyQuery(
    getAppointmentSlots,
    {
      options: (ownProps) => {
        return {
          variables: {
            user_ids: ownProps.bookings,
            start_span: ownProps.start,
            slot_increment: ownProps.increment,
            kind: ownProps.kind,
            medium: ownProps.medium,
            end_span: calculateEndOfSpan(
              ownProps.start,
              ownProps.dayIncrement,
            ).toISO(),
          },
        };
      },
    },
    (apolloClient) => {
      // this is a teardown function that can be used to clean out the apollo cache when this component unloads.
      const rootQueryCache = apolloClient?.cache?.data?.data?.ROOT_QUERY ?? {};
      const appointmentSlotsKeys = Object.keys(rootQueryCache).filter((x) =>
        x.startsWith("appointment_slots("),
      );
      appointmentSlotsKeys.forEach((key) => {
        apolloClient.cache.evict({ fieldName: key });
      });
      if (appointmentSlotsKeys.length > 0) {
        apolloClient.cache.gc();
      }
    },
  ),
)(AppointmentSlots);
