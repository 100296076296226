/* eslint-disable react/prop-types */
// TODO: Remove this and fix the eslint errors
import { graphql } from "@apollo/client/react/hoc";
import { modalIds } from "@spring/constants";
import { useDetectScrollTop } from "@spring/hooks";
import Meowth from "@spring/meowth";
import {
  AddAnOverlay,
  BorderPage,
  EnvDetails,
  Section,
  UrlParamNotification,
  WideContainer,
} from "@spring/smeargle";
import { addOverlay, openModal } from "@spring/smeargle/actions";
import classnames from "classnames";
import { Flex, Navigation } from "design-system/components";
import { getOr } from "lodash/fp";
import { useMemberBalance } from "modules/MemberDashboard/Billing/hooks/useMemberBalance";
import PropTypes from "prop-types";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { compose } from "redux";

import { Link } from "components/atoms";
import { PageBase } from "components/layout";
import {
  CCPAModal,
  CheckInDetailsModal,
  MemberTerminationModal,
} from "components/modals";
import { CheckinTodo } from "components/molecules";
import { SpringLogoIcon, useMediaQuery } from "design-system/index";
import {
  HamburgerIcon,
  IconButton,
  Box,
} from "@springcare/sh-component-library";

import Menu from "./Menu";
import Retently from "./Retently";
import RightLinks from "./RightLinks";
import FilterSection from "components/templates/Browse/ProviderBrowsePage/components/BRECS/FilterSection";
import styles from "./styles.module.scss";

import { assessments as assessmentConstants } from "@spring/constants";
import { showGlobalExperience } from "actions/global/actions";
import { closeSidebar, toggleSidebar } from "actions/memberDashboard/actions";
import SimpleFooter from "components/templates/MemberDashboard/SimpleFooter";
import { useTeenAssessmentStatus } from "modules/MemberDashboard/Teen/Home/hooks/useTeenAssessmentStatus";
import Router from "next/router";
import { getUserInfo } from "operations/queries/user";
import routes from "routes";
import { FLAGS, useFeatureFlag } from "utils/launchdarkly";
import { isMinor, isT2Member, isTeen } from "utils/memberHelpers";
import Snorlax from "utils/snorlax";
import { isGlobalUser } from "utils/global";
import { isBrecsVariant } from "components/templates/Browse/ProviderBrowsePage/utils";
import { useRedirectT2MemberToHome } from "components/templates/MemberDashboard/PageWrapper/hooks/useRedirectT2MemberToHome";
import { getHomeRoute } from "shared/utils/getHomeRoute";

const LogoLink = ({ isATeen, isAMinor, t }) => {
  const canVisitTeenHomePage = useFeatureFlag(FLAGS.TEEN_HOMEPAGE);
  const getLinkInfoForSHLogo = (isATeen, isAMinor, t) => {
    if (isATeen && canVisitTeenHomePage)
      return { linkTo: t("a11y:pageTitle.teenHome"), alias: "TeenHome" };
    if (isAMinor)
      return {
        linkTo: t("a11y:pageTitle.careVisits"),
        alias: "MemberCareVisits",
      };
    return { linkTo: t("a11y:pageTitle.homepage"), alias: "MemberHome" };
  };

  const routeInfo = getLinkInfoForSHLogo(isATeen, isAMinor, t);
  return (
    <Link
      alias={routeInfo.alias}
      mpTracking={{ pageName: routeInfo.linkTo, linkName: t("a11y:shLogoAlt") }}
    >
      <Flex align="center">
        <SpringLogoIcon />
      </Flex>
    </Link>
  );
};

const Children = ({ props, isMemberT2 }) => {
  if (props.float) {
    return (
      <Section size="xlg">
        <BorderPage borderless={props.borderless} isMemberT2={isMemberT2}>
          {props.children}
        </BorderPage>
      </Section>
    );
  }

  return props.children;
};

Children.propTypes = {
  props: PropTypes.shape({
    children: PropTypes.any,
    float: PropTypes.any,
    isMemberT2: PropTypes.bool,
  }),
};

const ContentBody = ({ props }) => {
  const isAMinor = isMinor(getOr({}, "data.user.member", props));
  const isMemberT2 = isT2Member(getOr({}, "data.user.member", props));
  const showCheckIn = props.showCheckIn && !isAMinor && !isMemberT2;
  return (
    <div
      className={styles.contentWrapper}
      style={{ padding: `${isMemberT2 && props.isMd && 0}` }}
    >
      {showCheckIn ? <CheckinTodo /> : null}
      <div id="main-content" className={styles.paddingTop} tabIndex="-1">
        <Children props={props} isMemberT2={isMemberT2} />
      </div>
    </div>
  );
};

ContentBody.propTypes = {
  props: PropTypes.shape({
    showCheckIn: PropTypes.any,
  }),
};

const ContentComp = ({ props }) => {
  const contentBody = <ContentBody props={props} />;

  if (props.noContainer) {
    return contentBody;
  }

  return <WideContainer>{contentBody}</WideContainer>;
};

ContentComp.propTypes = {
  props: PropTypes.any,
};

export const MemberDashboardPageWrapper = (props) => {
  const user = props.data?.user;
  const isLoading = props.data?.loading;
  const member = user?.member || {};
  const memberDob = member?.date_of_birth;
  const isAWorkplaceManager = user?.member?.workplace_manager;
  const isAMinor = isMinor(member);
  const isATeen = isTeen(member?.date_of_birth);
  const isAGlobalMember = isGlobalUser(member?.postal_address?.country);
  const isADependent = member?.covered_life?.dependent;
  const teenAssessment = useTeenAssessmentStatus(!isATeen || !user?.id);
  const { assessmentStatuses } = assessmentConstants;
  const isAT2Member = isT2Member(member);
  /** a bamboo member with a Spring Lite Experience */
  const isPrivatePractice = member?.is_private_practice;
  const shouldShowBrowseRecs =
    isBrecsVariant(useFeatureFlag(FLAGS.BRECS_ABC_EXPERIMENT)) &&
    routes.TherapistsBrowse.as === Router.asPath &&
    !isAMinor;
  const shouldShowGlobalOne = useFeatureFlag(FLAGS.SHOW_GLOBAL_ONE_EXPERIENCE);
  const isSudSupported = member?.cohort?.contract_term?.sud_supported;
  const [isMobile] = useMediaQuery(["(max-width: 480px)"]);
  const isMobileBenefitsPage =
    Router.asPath === routes.MemberBenefits.as && isMobile;

  const teenCompletedInitialAssessment =
    teenAssessment?.assessmentStatus === assessmentStatuses.FINISHED;
  const showTeenCheckInButton = isATeen && teenCompletedInitialAssessment;
  const isAnAdultMember = !isAMinor;
  const showCheckInButton = showTeenCheckInButton || isAnAdultMember;

  const { data: balanceData } = useMemberBalance();

  const balance = balanceData?.member_balance?.balance;
  const { openModal, showGlobalExperience, closeSidebar } = props;
  const country = getOr({}, "member.postal_address.country", user);
  const shouldShowGlobal = country?.length > 0 && country !== "US";

  useEffect(() => {
    if (isAMinor && [routes.SubstanceUseSupport.as].includes(Router.asPath)) {
      Router.replace(routes.MemberCareVisits.as, routes.MemberCareVisits.as);
    }
  }, [isAMinor]);

  useEffect(() => {
    if (isAT2Member && !shouldShowGlobalOne) {
      Router.replace(routes.ComingSoon.to, routes.ComingSoon.as);
    }
  }, [isAT2Member, shouldShowGlobalOne]);

  const { redirectT2MemberToHome } = useRedirectT2MemberToHome(member);

  useEffect(() => {
    // TODO: Complete the list of routes that should be supported for a T2 Member
    if (redirectT2MemberToHome) {
      const memberHomeRoute = getHomeRoute(memberDob);
      Router.replace(memberHomeRoute.to, memberHomeRoute.as);
    }
  }, [isAT2Member]);

  useEffect(() => {
    const shouldRedirectToHome =
      !isLoading &&
      !isSudSupported &&
      Router.asPath === routes.SubstanceUseSupport.as;

    if (shouldRedirectToHome) {
      Router.replace(routes.MemberHome.to, routes.MemberHome.as);
    }
  }, [isSudSupported, isLoading]);

  useEffect(() => {
    if (typeof window !== "undefined") {
      if (typeof window.matchMedia === "undefined") {
        // eslint-disable-next-line no-console
        console.error("This browser does not support matchMedia");
      } else {
        const breakpoints = {
          sm: "(min-width : 540px)",
          md: "(min-width : 720px)",
          lg: "(min-width : 960px)",
          xlg: "(min-width : 1140px)",
        };
        const shouldCloseSidebar = (match) => {
          if (!match.matches) {
            closeSidebar();
          }
        };
        const smallScreen = window.matchMedia(breakpoints.lg);
        smallScreen.addListener(shouldCloseSidebar);

        // This timeout is for a smoother UI
        // Causes the drawer to animate when navigating between pages.
        window.setTimeout(() => {
          shouldCloseSidebar(smallScreen);
        }, 500);
      }
    }
  }, [closeSidebar]);

  useEffect(() => {
    // if the modal has already been shown once to the user during their SH session (logout deletes this item)
    if (!localStorage.getItem("hasOpened")) {
      openModal(modalIds.terminationWarning);
    }
  }, [openModal]);

  useEffect(() => {
    // based on the launchdarkly flag we show the domestic vs global experience
    if (user) {
      // if country is US then we show the domestic experience otherwise show the global experience
      showGlobalExperience({ showGlobalExperience: shouldShowGlobal });
    }
  }, [user, showGlobalExperience, shouldShowGlobal]);

  const scroll = useDetectScrollTop();

  const { t } = useTranslation("a11y");
  const [isMd] = useMediaQuery("(min-width: 540px) and (max-width: 960px)");

  return (
    <>
      <PageBase
        allowed={props.allowed}
        redirect={props.redirect || routes.SignIn}
        darkBg={props.darkBg}
        overflowVisible={props.overflowVisible}
        removePaddingBottom={props.removePaddingBottom}
        shouldShowBrowseRecs={shouldShowBrowseRecs}
        showT2Background={props.showT2Background}
      >
        {user && <Retently user={user} />}
        {!props.momentsOnly && (
          <>
            <header>
              <Navigation
                logoWrapper={() => (
                  <>
                    <div className={styles.breakpoint}>
                      <LogoLink isATeen={isATeen} isAMinor={isAMinor} t={t} />
                    </div>
                    <Box className={styles.menuIcon}>
                      <IconButton
                        aria-label="Open Sidebar"
                        icon={HamburgerIcon}
                        onClick={props.toggleSidebar}
                        variant="no-emphasis"
                        size="sm"
                        colorScheme="neutral"
                      />
                    </Box>
                  </>
                )}
                left={
                  <Menu
                    isAMinor={isAMinor}
                    isATeen={isATeen}
                    isAnAdultMember={isAnAdultMember}
                    isAGlobalMember={isAGlobalMember}
                    isAT2Member={isAT2Member}
                    shadedBackground={props.shadedBackground}
                    showCheckInButton={showCheckInButton}
                    isAWorkplaceManager={isAWorkplaceManager}
                    isADependent={isADependent}
                    isPrivatePractice={isPrivatePractice}
                  />
                }
                right={
                  <RightLinks
                    hasChildDependents={
                      member?.cohort?.child_dependents_allowed || false
                    }
                    isGlobalUser={shouldShowGlobal}
                    isAMinor={isAMinor}
                    isATeen={isATeen}
                    isAT2Member={isAT2Member}
                    isPrivatePractice={isPrivatePractice}
                    showTeenCheckInButton={showTeenCheckInButton}
                    firstName={user?.first_name || ""}
                    lastName={user?.last_name || ""}
                    shadedBackground={props.shadedBackground}
                    showBillingNotificationBubble={
                      member?.postal_address?.country === "US" && balance > 0
                    }
                  />
                }
                backgroundColor={props.headerBackground}
                initialState={!scroll}
                onScroll={scroll}
              />
            </header>
            {shouldShowBrowseRecs && <FilterSection providerType="therapist" />}
          </>
        )}

        {process.env.HIDE_ENV_DETAILS ? null : (
          <EnvDetails
            environment={process.env.APP_ENV}
            apiUrl={process.env.APP_API}
            authApiUrl={process.env.AUTH_API_DOMAIN}
          />
        )}

        <div
          className={classnames(styles.standardWrapper, {
            [styles.fullHeight]: !props.includeHelpCard,
            [styles.customFooter]: props.showCustomFooter,
          })}
          style={{ marginTop: isMobileBenefitsPage ? 0 : props.marginTop }}
        >
          <ContentComp props={{ ...props, isMd: isMd }} />
        </div>
        {props.momentsOnly && <SimpleFooter />}
        {props.showCustomFooter && props.customFooter}
        <AddAnOverlay
          overlayId="SetReminderFrequencyOverlay"
          showIf={() =>
            user?.customer?.active && member?.check_in_frequency_days === null
          }
        />

        <UrlParamNotification
          param="ref"
          value="confirmation"
          notification="Your email was successfully confirmed!"
        />

        <CheckInDetailsModal />
        <CCPAModal />
        {member?.inside_inactivation_window && (
          <MemberTerminationModal terminateAt={member.access_limit_date} />
        )}
      </PageBase>
    </>
  );
};

MemberDashboardPageWrapper.defaultProps = {
  allowed: true,
  includeHelpCard: true,
  float: true,
  darkBg: true, // darkBg is passed to PageBase, which also defaults to true for darkBg
  overflowVisible: false, // overflowVisible is passed to PageBase, which also defaults to false for overflowVisible
  borderless: false,
  showCheckIn: true,
  momentsOnly: false,
  noContainer: false,
  showCustomFooter: false,
  customFooter: null,
  showT2Background: false,
};

export default compose(
  graphql(getUserInfo, {
    options: Meowth.apolloOptionsUserId,
    skip: Meowth.apolloSkipUserId,
  }),
  connect(
    (state) => ({
      open: state.memberDashboard?.sidebarOpen,
      overlays: getOr([], "modal.overlays", state),
      allowed: () =>
        Snorlax(state)
          .loggedIn.to(routes.SignIn)
          .isMember(routes["Logout"])
          .hasCompletedAddress(routes.Register, {
            skip: Meowth.getUserScopes().includes("group:moments_only"),
          })
          .isNestedMinor()
          .customerIsLaunched(localStorage.getItem("customerId"), null, {
            skip: Meowth.getUserScopes().includes("group:moments_only"),
          })
          .hasCompletedInitialAssessment(routes.MemberExpectations),
    }),
    {
      closeSidebar,
      toggleSidebar,
      addOverlay,
      openModal,
      showGlobalExperience,
    },
  ),
)(MemberDashboardPageWrapper);
