import { FLAGS, useFeatureFlag } from "utils/launchdarkly";
import { getT2MembersSupportedRoutes } from "components/templates/MemberDashboard/PageWrapper/utils/getT2MembersSupportedRoutes";
import { useRouter } from "next/router";
import { isT2Member } from "utils/memberHelpers";

export const useRedirectT2MemberToHome = (member) => {
  const isAT2Member = isT2Member(member);

  const memberCustomerIsEnabledForManagerExperience = useFeatureFlag(
    FLAGS.ENABLE_WORKPLACE_MANAGER_NAV_LINK,
  );

  const canViewWorkplacePagesFlag = useFeatureFlag(
    FLAGS.MANAGER_EXPERIENCE_MILESTONE_3,
  );

  const router = useRouter();
  const isAWorkplaceManager = member?.workplace_manager;

  const hasAccessToManagerExperience =
    memberCustomerIsEnabledForManagerExperience &&
    canViewWorkplacePagesFlag &&
    isAWorkplaceManager;

  const T2MemberAccessibleRoutes = getT2MembersSupportedRoutes(
    hasAccessToManagerExperience,
    router.query,
  );

  return {
    redirectT2MemberToHome:
      isAT2Member && !T2MemberAccessibleRoutes.includes(router.asPath),
  };
};
