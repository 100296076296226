import { useEffect } from "react";
import {
  useLocalParticipant,
  useRemoteParticipants,
  useRoomContext,
} from "@livekit/components-react";
import { useParticipantMetadata } from "context/SessionRoomContext";
import { ParticipantType } from "components/templates/SHSessionRoom/types";
import {
  Track,
  RoomEvent,
  LocalAudioTrack,
  RemoteParticipant,
} from "livekit-client";
import { links } from "@spring/constants";
import { useToast, Box } from "@springcare/sh-component-library";
import { useTranslation } from "hooks/react-i18next";

export const RoomEventHandler = () => {
  const { t } = useTranslation("livekitExperience", {
    keyPrefix: "videoCallControls.sessionRoomUtils",
  });
  const remoteParticipants = useRemoteParticipants();
  const { participantType, localParticipantName, remoteParticipantName } =
    useParticipantMetadata();
  const joinSessionSound = new Audio(links.JoinSessionSoundURL);
  const leaveSessionSound = new Audio(links.LeaveSessionSoundURL);
  const waitingRoomSound = new Audio(links.WaitingRoomSoundURL);
  const localParticipant = useLocalParticipant();

  const toast = useToast({
    position: "top",
    isClosable: false,
  });

  const participantConnected = async (participant: RemoteParticipant) => {
    await new Promise((r) => setTimeout(r, 1600)); // needed for provider participant permissions to adjust
    const participantName = participant.isLocal
      ? localParticipantName
      : remoteParticipantName;
    // member is in waiting room. If local participant is a provider, play waiting room sound
    if (!participant.permissions?.canPublish) {
      if (participantType === ParticipantType.Provider)
        await waitingRoomSound.play();
    } else {
      // participant goes straight to the session. Only play toast for people in the session.
      if (localParticipant?.localParticipant?.permissions?.canPublish) {
        toast({
          position: "top",
          isClosable: false,
          render: () => (
            <Box
              mt="v-16"
              borderRadius="v-lg"
              px="v-8"
              py="v-4"
              w="fit-content"
              backgroundColor="neutral-bold"
              color="neutral-on-bold"
            >
              {participantName ?? t("aParticipant")} has joined the session
            </Box>
          ),
        });
        await joinSessionSound.play();
      }
    }
  };

  const participantDisconnected = async (participant) => {
    // only play the sound if the departing participant is NOT in the waiting room
    // and if the local participant is not in the waiting room
    const participantName = participant.isLocal
      ? localParticipantName
      : remoteParticipantName;
    if (
      participant.permissions?.canPublish &&
      localParticipant?.localParticipant?.permissions?.canPublish
    ) {
      toast({
        position: "top",
        isClosable: false,
        render: () => (
          <Box
            mt="v-16"
            borderRadius="v-lg"
            px="v-8"
            py="v-4"
            w="fit-content"
            backgroundColor="neutral-bold"
            color="neutral-on-bold"
          >
            {participantName ?? t("aParticipant")} has left the session
          </Box>
        ),
      });

      await leaveSessionSound.play();
    }
  };

  const localTrackPublished = async (trackPublication) => {
    const { KrispNoiseFilter } = await import("@livekit/krisp-noise-filter");
    if (
      trackPublication.source === Track.Source.Microphone &&
      trackPublication.track instanceof LocalAudioTrack
    ) {
      const krispProcessor = KrispNoiseFilter();
      await trackPublication.track.setProcessor(krispProcessor);
    }
  };

  const room = useRoomContext();

  useEffect(() => {
    if (remoteParticipants.length < 3) {
      room?.on(RoomEvent.LocalTrackPublished, localTrackPublished);
      room?.on(RoomEvent.ParticipantConnected, participantConnected);
      room?.on(RoomEvent.ParticipantDisconnected, participantDisconnected);
    }

    return () => {
      room?.off(RoomEvent.LocalTrackPublished, localTrackPublished);
      room?.off(RoomEvent.ParticipantConnected, participantConnected);
      room?.off(RoomEvent.ParticipantDisconnected, participantDisconnected);
    };
  }, [room, remoteParticipants]);

  return null;
};
