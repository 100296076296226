import { useEffect } from "react";
import {
  LiveKitRoom,
  RoomAudioRenderer,
  LiveKitRoomProps,
} from "@livekit/components-react";
import { Box, useMediaQuery } from "@springcare/sh-component-library";
import { datadogAddAction } from "lib/datadog-setup";
import ParticipantPermissionsChecker from "components/templates/SHSessionRoom/components/ParticipantPermissionsChecker";
import PreJoin from "components/templates/SHSessionRoom/views/PreJoin";
import SessionRoomStage from "components/templates/SHSessionRoom/components/SessionRoomStage";
import SessionEndedMember from "components/templates/VirtualSessionView/components/EndSessionView";
import SessionEndedProvider from "components/templates/SHSessionRoom/views/SessionEndedProvider";
import {
  useSessionViewStatus,
  useSessionConnectStatus,
  useParticipantMetadata,
} from "context/SessionRoomContext";
import { SessionViewStatus } from "components/templates/SHSessionRoom/types";
import { ParticipantType } from "components/templates/SHSessionRoom/types";
import { RoomEventHandler } from "components/templates/SHSessionRoom/utils/SHSessionRoomUtils";

const TypedLiveKitRoom = LiveKitRoom as React.ComponentType<LiveKitRoomProps>;

// NOTE: _loading and _error should pass lint while unused until future
const SHSessionRoom = ({
  appointmentId,
  sessionData,
  loading: _loading,
  error: _error,
}) => {
  const { sessionViewStatus } = useSessionViewStatus();
  const { shouldConnect, isConnected, setIsConnected, handleDisconnect } =
    useSessionConnectStatus();

  const [isMobile] = useMediaQuery("(max-width: 450px)");

  const {
    livekit_url,
    jwt,
    member_name,
    participant_type,
    participant_name,
    provider_name,
  } = sessionData;

  const {
    setParticipantType,
    setLocalParticipantName,
    setRemoteParticipantName,
    setAppointmentId,
  } = useParticipantMetadata();
  useEffect(() => {
    setParticipantType(participant_type);
    setLocalParticipantName(participant_name);
    if (participant_type === ParticipantType.Member) {
      setRemoteParticipantName(provider_name);
    } else {
      setRemoteParticipantName(member_name);
    }
    const urlPaths = window.location.pathname.split("/");
    // url is: <envUrl>[0]/session[1]/<appointmentId>[2]/<participantId>[3]
    const appointmentId = urlPaths[2];
    setAppointmentId(appointmentId);
  }, [sessionData]);

  const PREJOIN_ROOM =
    sessionViewStatus === SessionViewStatus.BrowserPermissionsDenied ||
    sessionViewStatus === SessionViewStatus.NotStarted ||
    sessionViewStatus === SessionViewStatus.MemberIsWaiting ||
    sessionViewStatus === SessionViewStatus.ProviderNoShow ||
    sessionViewStatus === SessionViewStatus.Connecting;

  const SESSION_IN_PROGRESS =
    isConnected && sessionViewStatus === SessionViewStatus.InProgress;

  const SESSION_ENDED = sessionViewStatus === SessionViewStatus.Ended;
  const SESSION_ENDED_MEMBER =
    SESSION_ENDED && participant_type === ParticipantType.Member;
  const SESSION_ENDED_PROVIDER =
    SESSION_ENDED && participant_type === ParticipantType.Provider;

  const handleError = (error) => {
    datadogAddAction("join_meeting_error", {
      error,
      appointmentId,
      jwt,
      virtualSessionType: "livekit",
    });
  };

  return (
    <>
      {jwt && livekit_url && (
        <TypedLiveKitRoom
          data-lk-theme="default"
          token={jwt}
          serverUrl={livekit_url}
          connect={shouldConnect}
          onConnected={() => setIsConnected(true)}
          onDisconnected={handleDisconnect}
          onError={handleError}
        >
          {/* NOTE: These are non-UI components that handle the prejoin/room lifecycle */}
          <RoomEventHandler />
          <ParticipantPermissionsChecker />

          {PREJOIN_ROOM && (
            <PreJoin
              sessionData={sessionData}
              isMobile={isMobile}
              appointmentId={appointmentId}
            />
          )}

          {SESSION_IN_PROGRESS && (
            <Box margin="0 auto" h="100vh" bg="#000" role="main">
              <SessionRoomStage isMobile={isMobile} />
              <RoomAudioRenderer />
            </Box>
          )}
        </TypedLiveKitRoom>
      )}
      {SESSION_ENDED_MEMBER && !isConnected && (
        <SessionEndedMember appointmentId={appointmentId} />
      )}
      {SESSION_ENDED_PROVIDER && !isConnected && (
        <Box role="main">
          <SessionEndedProvider />
        </Box>
      )}
    </>
  );
};

export default SHSessionRoom;
