// TODO: remove this eslint-disable when ready
// TODO: Handle error state
/* eslint-disable no-unused-vars */
import { useEffect, useState } from "react";
import { Box, Spinner, Flex } from "design-system/components";
import { Button, Heading } from "@springcare/sh-component-library";
import { ChevronDown } from "design-system/assets";
import { useLazyQuery } from "@apollo/client";
import { useTranslation } from "react-i18next";

import Pagination from "./Pagination";

import { FLAGS, useFeatureFlag } from "utils/launchdarkly";
import {
  searchCareProviders,
  getRecommendedTherapists,
} from "operations/queries/careProvider";
import Meowth from "@spring/meowth";
import { useProviderBrowseContext } from "context/ProviderBrowseContext";

import { isMinor } from "utils/memberHelpers";
import { ProviderRole } from "@spring/constants";

import ProviderCard from "components/templates/Browse/ProviderBrowsePage/components/ProviderCard/ProviderCard";
import {
  trackProviderCardsLoaded,
  trackBrecsSeeMoreTherapistsClicked,
} from "components/templates/Browse/ProviderBrowsePage/analytics";
import {
  prepareQueryFilters,
  dedupeProviders,
  BrecsVariants,
  isBrecsVariant,
} from "components/templates/Browse/ProviderBrowsePage/utils";

const ProvidersList = () => {
  const {
    memberInfo,
    brecsProviderList,
    setBrecsProviderList,
    providerList,
    providerListLoading,
    setProviderListLoading,
    setProviderList,
    paging,
    setPaging,
    selectedFilters,
    queriedFilters,
    setPossibleFilters,
    queryRequestId,
    setQueryRequestId,
    isCompanyExclusive,
    possibleFilters,
    providerType,
    addressUpdated,
  } = useProviderBrowseContext();
  const { t } = useTranslation(["careProvider", "common"]);
  const [isSeeMoreExpanded, setIsSeeMoreExpanded] = useState(false);
  const brecsVariantFlag = useFeatureFlag(FLAGS.BRECS_ABC_EXPERIMENT);
  const isBrecsVariants = isBrecsVariant(brecsVariantFlag);
  const isAMinor = isMinor(memberInfo?.user?.member);
  const showBrecs =
    isBrecsVariants && providerType === ProviderRole.Therapist && !isAMinor;
  const isMoreTherapistsButton =
    brecsVariantFlag === BrecsVariants.MoreTherapistsButton && showBrecs;
  const isCombinedList =
    brecsVariantFlag === BrecsVariants.CombinedList && showBrecs;
  const showFakeBadges =
    useFeatureFlag(FLAGS.FAKE_PROVIDER_BADGES) && showBrecs;

  const showDedicatedProviderToggle = possibleFilters?.allow_company_exclusive;
  const preparedFilters = prepareQueryFilters(selectedFilters, providerType);
  preparedFilters["version"] = "v2";

  const [
    doSearchCareProviders,
    { data: searchCareProviderData, loading: searchCareProviderLoading },
  ] = useLazyQuery(searchCareProviders, {
    fetchPolicy: "no-cache",
    notifyOnNetworkStatusChange: true,
  });

  const [
    doGetRecommendedTherapists,
    { data: recommendedProviderData, loading: recommendedProviderLoading },
  ] = useLazyQuery(getRecommendedTherapists, {
    fetchPolicy: "no-cache",
  });

  const areFiltersEmpty = Object.values(queriedFilters.filters).every(
    (filterArray) => filterArray.length === 0,
  );

  useEffect(() => {
    // make sure the providerType is passed through for filtering providers
    if (providerType) {
      let preparedFilters = prepareQueryFilters(selectedFilters, providerType);

      if (preparedFilters) {
        if (isCompanyExclusive) {
          preparedFilters = prepareQueryFilters(
            { filters: { mediums: possibleFilters?.mediums } },
            providerType,
          );
          preparedFilters.filters["dedicated_only"] = true;
        }
        preparedFilters["version"] = "v2";
        doSearchCareProviders({ variables: preparedFilters });

        if (showBrecs && areFiltersEmpty && !isCompanyExclusive) {
          const apolloOptionsUserId = Meowth.apolloOptionsUserId();
          apolloOptionsUserId.variables.version = "browse_recs_v1";
          apolloOptionsUserId.variables.filters = preparedFilters?.filters;
          doGetRecommendedTherapists({ ...apolloOptionsUserId });
        }
      }
    }
  }, [addressUpdated, isCompanyExclusive, providerType]);

  useEffect(() => {
    if (searchCareProviderData) {
      const browseTherapists =
        searchCareProviderData?.care_providers_search?.providers;
      setProviderList(browseTherapists);
      setPossibleFilters(
        searchCareProviderData?.care_providers_search?.possible_filters,
      );
      setQueryRequestId(
        searchCareProviderData?.care_providers_search?.query_request_id,
      );
      if (isCompanyExclusive) {
        setBrecsProviderList([]);
      }

      const paging = {
        ...searchCareProviderData?.care_providers_search?.paging,
      };

      if (showBrecs && paging?.page === 1 && !isCompanyExclusive) {
        const recommendedData =
          recommendedProviderData?.user?.member?.recommended_therapists;
        const recommendedTherapists = (recommendedData || []).map((item) => ({
          ...item.therapist,
          group_name: item.group_name,
          isBrecsRecommended: true,
        }));
        setBrecsProviderList(recommendedTherapists);

        const duplicatesRemoved = dedupeProviders(
          browseTherapists,
          recommendedData,
          providerList,
          setProviderList,
          isCombinedList,
          true, // isInitialLoad
          showFakeBadges,
        );

        const totalProviderCount =
          recommendedTherapists?.length + paging?.total - duplicatesRemoved;
        paging.total = totalProviderCount;

        setPaging(paging);
      } else {
        setPaging((prevState: any) => {
          if (prevState?.total) {
            paging.total = prevState?.total;
            return paging;
          } else {
            return paging;
          }
        });
      }
    }
  }, [searchCareProviderData, recommendedProviderData]);

  useEffect(() => {
    if (providerList?.length) {
      trackProviderCardsLoaded(
        selectedFilters,
        paging,
        showDedicatedProviderToggle,
        isCompanyExclusive,
        queryRequestId,
      );
    }
  }, [providerList, paging]);

  useEffect(() => {
    if (searchCareProviderLoading || recommendedProviderLoading) {
      setProviderListLoading(true);
    } else if (!searchCareProviderLoading && !recommendedProviderLoading) {
      setProviderListLoading(false);
    }
  }, [searchCareProviderLoading, recommendedProviderLoading]);

  const seeMoreClick = () => {
    trackBrecsSeeMoreTherapistsClicked();
    setIsSeeMoreExpanded((prevState) => !prevState);
  };

  return (
    <>
      {providerListLoading ? (
        <Box mt={32}>
          <Flex h="72px" align="center" justify="center">
            <Spinner speed="1s" size="xl" />
          </Flex>
        </Box>
      ) : (
        <>
          {isMoreTherapistsButton &&
          brecsProviderList?.length > 0 &&
          paging?.page === 1 ? (
            <>
              {brecsProviderList?.map((provider, index) => (
                <ProviderCard
                  //@ts-ignore
                  key={provider.id}
                  //@ts-ignore
                  provider={provider}
                  index={index}
                  pageNumber={paging?.page}
                  member={memberInfo?.user?.member}
                  queryRequestId={queryRequestId}
                />
              ))}

              {!isSeeMoreExpanded && (
                <Button
                  variant="high-emphasis"
                  colorScheme="base"
                  size="md"
                  icon={ChevronDown}
                  onClick={() => seeMoreClick()}
                  mb="100px"
                >
                  {t("providerAmount.moreTherapists", {
                    total: paging?.total <= 1000 ? paging?.total : "1000",
                  })}
                </Button>
              )}

              {isSeeMoreExpanded && (
                <>
                  {providerList?.map((provider, index) => (
                    <ProviderCard
                      //@ts-ignore
                      key={provider.id}
                      //@ts-ignore
                      provider={provider}
                      index={index}
                      pageNumber={paging?.page}
                      member={memberInfo?.user?.member}
                      queryRequestId={queryRequestId}
                    />
                  ))}

                  {paging && providerList?.length !== 0 && (
                    <Box mt={32}>
                      <Pagination
                        //@ts-ignore
                        refetch={doSearchCareProviders}
                        loading={searchCareProviderLoading}
                        payload={preparedFilters}
                        currentPage={paging?.page}
                        totalPages={paging?.pages}
                        limit={paging?.limit}
                        totalProviderCount={paging?.total}
                      />
                    </Box>
                  )}
                </>
              )}
            </>
          ) : (
            <>
              {providerList?.map((provider, index) => (
                <ProviderCard
                  //@ts-ignore
                  key={provider.id}
                  //@ts-ignore
                  provider={provider}
                  index={index}
                  pageNumber={paging?.page}
                  member={memberInfo?.user?.member}
                  queryRequestId={queryRequestId}
                />
              ))}

              {paging && providerList?.length !== 0 && (
                <Box mt={32}>
                  <Pagination
                    //@ts-ignore
                    refetch={doSearchCareProviders}
                    loading={searchCareProviderLoading}
                    payload={preparedFilters}
                    currentPage={paging?.page}
                    totalPages={paging?.pages}
                    limit={paging?.limit}
                    totalProviderCount={paging?.total}
                  />
                </Box>
              )}
            </>
          )}
        </>
      )}
    </>
  );
};

export default ProvidersList;
