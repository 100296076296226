import { useState, useRef, useEffect } from "react";
import { useRouter } from "next/router";
import NextLink from "next/link";
import { useToast, SHNotification } from "design-system/components";
import { useQuery } from "@apollo/client";
import { graphql } from "@apollo/client/react/hoc";
import { connect, useSelector } from "react-redux";
import { Trans, useTranslation } from "react-i18next";
import { DateTime } from "luxon";
import { getOr } from "lodash/fp";
import { compose } from "redux";
import {
  hasHadMedManagerAppt,
  hasHadSpecialtyCareNavigationAppointment,
} from "utils/providers/";
import { useTeenAssessmentStatus } from "modules/MemberDashboard/Teen/Home/hooks/useTeenAssessmentStatus";
import {
  assessments as assessmentConstants,
  ProviderStatus,
} from "@spring/constants";
import { getIterableCampaignInfo } from "utils/localStorage";

import {
  AppointmentAction,
  AppointmentKind,
  AppointmentMedium,
  CareProviderTagKind,
  getAppointmentKindForTracking,
  getAppointmentMediumForTracking,
  getModalNameForTracking,
  inPersonDistance,
  isCoaching,
  isMedicationManagement,
  isMedicationManagerRole,
  isTherapy,
  modalIds,
  RequestableAppointmentKind,
  ProviderRole,
} from "@spring/constants";
import routes from "routes";
import Meowth from "@spring/meowth";
import schema from "schemas/scheduleModal";

import envUtils from "utils/environment";
import {
  formatMiles,
  formatKilometers,
  formatMemberExactAge,
} from "utils/displayHelpers";
import { getFirstError } from "utils/apollo/errorHandler";
import { sanitizeEnum } from "@spring/immutability";

import { track, TRACK_EVENT } from "utils/mixpanel";
import { track24HourWarning } from "components/templates/Browse/ProviderBrowsePage/analytics";
import { useInViewportOnce } from "hooks";

import { isLegacyMinor, isMinor } from "utils/memberHelpers";
import {
  addNotification,
  closeModal,
  openModal,
} from "@spring/smeargle/actions";
import { includeTagsSubset, shouldShowTags } from "@spring/smeargle/src/utils";
import NonAcuityContent from "components/NonAcuityContent";

import {
  getMemberCarePlanProgress,
  getMemberConsent,
} from "operations/queries/member";
import { getAppointments } from "operations/queries/appointment";
import { updateMember } from "operations/mutations/member";
import { getUserInfo } from "operations/queries/user";
import { getCareTeam } from "operations/queries/careProvider";
import { memberConsentSelector } from "selectors/form";
import {
  createAppointment,
  rescheduleAppointment,
} from "operations/mutations/appointment";
import { useSyncPreviousTruthyValue } from "hooks/useSyncPreviousValue";
import {
  isACareAdvocate,
  isACareNavigator,
  isATherapist,
  isPeerRecoverySpecialist,
  routeToConfirmationPage,
} from "./CareProviderScheduleContent.utils";
import { trackConfirmAppointmentoInModalClicked } from "components/templates/Browse/ProviderBrowsePage/analytics";

import {
  Bolded,
  Content,
  DedicatedIcon,
  FlexRow,
  Form,
  OpenInNewTab,
  Section,
  Stout,
  Table,
  Tag,
  Tooltip,
} from "@spring/smeargle";
import {
  Center,
  Spinner,
  useMediaQuery,
  VBulletIcon,
} from "@springcare/sh-component-library";
import {
  AppointmentNoCostBadge,
  InNetworkStatusDisclaimer,
  CelebrateIcon,
  Link,
  OnsiteBadge,
  WarningIcon,
} from "components";
import { AppointmentSlots, AppointmentTabs } from "components/form";
import { MemberConsent, MinorAppointmentCallout } from "components/molecules";
import {
  Box,
  Button as SHButton,
  Flex,
  Heading,
  Text,
} from "design-system/components";
import { TherapyTypeSection } from "shared/components/TherapyTypeSection";
import styles from "./styles.module.scss";
import { normalizeToName } from "utils/global";
import { AcknowledgeLateCancelContent } from "components/templates/NoShowReschedulePage/AcknowledgeLateCancelContent";
import { ReasonForScheduling } from "components/templates/CareProviderSchedule/components/CareProviderScheduleContent/components/ReasonForScheduling";
import { FLAGS, useFeatureFlag } from "utils/launchdarkly";
import { isTeen, isDemoEnvironment } from "utils/memberHelpers";
import { useCustomToast } from "@springcare/sh-component-library";
import { Button, VArrowRightIcon } from "@springcare/sh-component-library";
import { money_format } from "modules/MemberDashboard/Billing/utils";
import ComingSoonBanner from "./ComingSoonBanner";
import { ConfirmButton } from "./ConfirmButton";
import { AttendedStatuses } from "constants/appointments";
import { COST_ESTIMATE_DISPLAY_STATES } from "components/templates/CostEstimation/constants";
import { useCanAccessGatedBooking } from "modules/MemberDashboard/GatedBooking/hooks";
import { SpecializedCoachingCardAnalyticsMapping } from "modules/MemberDashboard/SpecializeCoach/constants/analyticsMapping";
import { RequestableAppointmentKindEnum } from "modules/shared/graphql-codegen/graphql";
import CostEstimateDisclaimer, {
  CostEstimateDisclaimerTypes,
} from "components/molecules/CostEstimateDisclaimer/CostEstimateDisclaimer";
import { SeasonalityWarning } from "./SeasonalityWarning";
import ProviderDetailsV2 from "./ProviderDetailsV2";
import { CancellationPolicy } from "components/templates/CareVisitDetails/components";
import CareProviderOverview from "./CareProviderOverview";

const customerSiteAddress = (customerSite) => {
  if (!customerSite) return null;

  const { address, appointment_directions, city, postal_code, state } =
    customerSite;

  return [address, city, state, postal_code, appointment_directions]
    .filter(Boolean)
    .join(", ");
};

const CareProviderScheduleContent = ({
  modalUUID,
  memberInfo,
  userInfo,
  careProvider,
  providerRole,
  cnAppointments,
  mmAppointments,
  scnAppointments,
  appointmentKind,
  memberConsentForm,
  action,
  consent,
  medium,
  createAppointment,
  addNotification,
  updateMember,
  initialStartTime,
  upcomingAppointments,
  openModal,
  closeModal,
  rescheduleAppointment,
  appointmentId,
  appointmentLocation,
  buttonText,
  providerOrder,
  queryRequestId,
  showGlobalExperience,
  pageNumber,
  previousReasonForScheduling,
  providerType,
  isCoveredForMember,
  isReschedule,
  customerSite,
  locale,
  displayState,
  setDisplayState,
  defaultTab,
  handleCCANext,
  upcomingAppointmentSlotsLoading,
}) => {
  const router = useRouter();
  const [isMobile] = useMediaQuery("(max-width: 767px)");
  const { t } = useTranslation([
    "careProvider",
    "memberTermination",
    "memberDashboard",
    "displayHelper",
  ]);

  const [appointmentCreationPending, setAppointmentCreationPending] =
    useState(false);
  const [reasonForScheduling, setReasonForScheduling] = useState(
    previousReasonForScheduling || "",
  );
  const [showComingSoonBanner, setShowComingSoonBanner] = useState(false);

  // RequestableAppointmentKindEnum.CouplesTherapy | RequestableAppointmentKindEnum.Therapy
  const [selectedTherapyType, setSelectedTherapyType] = useState("");

  const enableInPersonGlobal = useFeatureFlag(FLAGS.IN_PERSON_GLOBAL);
  const currentRoute = router?.asPath;
  const isBrowseOrRecs =
    currentRoute === routes.TherapistsBrowse.as ||
    currentRoute === routes.RecommendedTherapists.as;

  const isModal = !currentRoute?.includes("/members/providers/");
  const showDetails = isModal
    ? currentRoute === routes.MemberCareVisits.as
    : true;

  const couplesTherapyFlag = useFeatureFlag(FLAGS.ENABLE_COUPLES_THERAPY);
  const showInPersonMedMan = useFeatureFlag(
    FLAGS.IN_PERSON_MED_MANAGER_RELEASE,
  );

  const member = getOr(memberInfo, "user.member", memberInfo); // check if nested member, else use top level object

  const showAcknowledgeLateCancelFlag = useFeatureFlag(
    FLAGS.ACKNOWLEDGE_LATE_CANCEL_ON_CREATE,
  );
  const supportsProviderConsultation =
    useFeatureFlag(FLAGS.PROVIDER_CONSULTATION) &&
    careProvider?.roles?.includes("Therapist");
  const showCouplesTherapy =
    couplesTherapyFlag && !isMinor(member) && !isReschedule;

  const showTrialBookingToast = useCustomToast({
    type: "error",
    message: t("trialBooking.toast"),
    action: "off",
  });

  const { assessmentStatus: teenAssessmentStatus } =
    useTeenAssessmentStatus(!memberInfo);

  const isATeen = isTeen(member?.date_of_birth);
  const isCoachingAppointment = isCoaching(appointmentKind);

  const { assessmentStatuses } = assessmentConstants;
  const teenAssessmentIsNotFinished =
    teenAssessmentStatus !== assessmentStatuses.FINISHED;
  const isATherapyAppointment = isTherapy(appointmentKind);
  const routeTeenToInitialAssessment =
    isATeen && teenAssessmentIsNotFinished && isATherapyAppointment;

  const handleReasonForSchedulingChange = (event) =>
    setReasonForScheduling(event.target.value);

  const excludeAttended = Object.values(AttendedStatuses)
    .filter((status) => status !== AttendedStatuses.AppointmentKept)
    .map((status) => status.toUpperCase().replace(/ /g, "_"));

  const formData = useSelector((state) =>
    getOr({}, `care-provider-schedule-${careProvider.id}`, state.form),
  );

  const getTherapistCategory = () => {
    if (router.query.therapistCategory) {
      return router.query.therapistCategory;
    } else if (
      currentRoute === routes.TherapistsBrowse.as &&
      careProvider?.isBrecsRecommended
    ) {
      return "recommended";
    } else if (currentRoute === routes.TherapistsBrowse.as) {
      return "browse";
    } else if (currentRoute === routes.RecommendedTherapists.as) {
      return "recommended";
    } else {
      return "";
    }
  };

  const { data: careTeamData } = useQuery(getCareTeam, {
    variables: {
      id: Meowth.getUserId(),
    },
    skip: !memberInfo,
  });

  const { data: getAppointmentsData, loading: getAppointmentsLoading } =
    useQuery(getAppointments, {
      variables: {
        limit: 1,
        member_id: member?.id,
        care_provider_id: careProvider?.id,
        kind: ["Coaching Appointment"],
        exclude_status: "Cancelled",
        exclude_attended: excludeAttended,
        sort_by: [
          {
            column: "appointments.start_at",
            direction: "asc",
          },
        ],
      },
      skip: !isCoachingAppointment || !careProvider,
    });

  const {
    data: accessGatedBookingData,
    loading: canAccessGatedBookingLoading,
  } = useCanAccessGatedBooking(appointmentKind, member);

  const canAccessGatedBooking =
    accessGatedBookingData?.show_gated_booking_flow?.show_gated_booking;

  const isCoachingFollowup =
    isCoachingAppointment &&
    getAppointmentsData?.appointments?.data?.length > 0 &&
    appointmentId !== getAppointmentsData?.appointments?.data[0]?.id; // reschedule edge-case

  // preserving the initial start time passed in on modal load
  // i.e the initialStartTime value passed in for the OPEN_MODAL Redux action
  // without it, we lose the value when we click a slot and it causes MXENG-3452
  const prevInitialTime = useSyncPreviousTruthyValue(initialStartTime);

  const isTherapyOrMedManagerModal =
    isTherapy(appointmentKind) || isMedicationManagement(appointmentKind);
  const isMedicationManagementAppt = isMedicationManagement(appointmentKind);
  const isALegacyMinor = isLegacyMinor(getOr({}, "user.member", memberInfo));
  const appointmentKindFromProps = careProvider && appointmentKind;
  const inNetwork = member?.cohort?.in_network;
  const memberCostOfCoaching =
    member?.cohort?.contract_term?.member_cost_of_coaching;
  const isCostTransparencyEnabled = getOr(
    false,
    "cohort.contract_term.virtual_session_cost_term.enable_show",
    member,
  );

  // New endpoints for getting covered and remaining sessions
  const visitsCoveredSpecialist = getOr(0, "visits_covered_specialist", member);
  const visitsRemainingSpecialist = getOr(
    0,
    "visits_remaining_specialist",
    member,
  );
  const visitsCoveredTotal = getOr(0, "visits_covered_total", member);
  const visitsRemainingTotal = getOr(0, "visits_remaining_total", member);
  const visitsCoveredCoaching = getOr(0, "visits_covered_coaching", member);
  const visitsRemainingCoaching = getOr(0, "visits_remaining_coaching", member);

  const shouldShowNoCostBadge = (() => {
    if (isCostTransparencyEnabled) {
      return false;
    }

    if (isMedicationManagementAppt) {
      return visitsCoveredSpecialist > 0 && visitsRemainingSpecialist > 0;
    } else if (isTherapy(appointmentKind)) {
      return visitsCoveredTotal > 0 && visitsRemainingTotal > 0;
    } else if (isCoaching(appointmentKind)) {
      return (
        visitsCoveredCoaching !== null &&
        visitsCoveredCoaching > 0 &&
        visitsRemainingCoaching > 0
      );
    } else {
      // This means session is care navigation (or some other type added later)
      return false;
    }
  })();

  const isCoveredReschedule = isCoveredForMember && isReschedule;
  // check if sessions are covered or not
  const paidCoachingExperience =
    visitsCoveredCoaching !== null &&
    visitsCoveredCoaching >= 0 &&
    isCoachingAppointment;

  // Determines whether to show reason for scheduling input field
  const memberAge = formatMemberExactAge(member?.date_of_birth);
  const showReasonForSchedulingInput =
    isMinor(member) &&
    memberAge <= 5 &&
    (appointmentKindFromProps === AppointmentKind.InitialCareNavigation ||
      appointmentKindFromProps === AppointmentKind.FollowUpCareNavigation);

  // session is free if the user has remaining covered sessions
  const coachingSessionIsFree =
    (visitsCoveredCoaching > 0 && visitsRemainingCoaching > 0) ||
    Boolean(isCoveredReschedule);

  const isSUDcn = careProvider.care_provider_tags.some(
    (tag) =>
      tag.kind === CareProviderTagKind.Specialty &&
      tag.name === "Substance Use Disorder",
  );

  const providerLicensedCountries = careProvider?.licensed_countries
    ?.map((country) => normalizeToName(country))
    .join(", ");
  const memberCountry = normalizeToName(member?.postal_address?.country);
  const navigatorLink = routes.ScheduleAppointmentProviderDetail.as.replace(
    ":id",
    careTeamData?.user?.member?.care_team?.care_navigator?.id,
  );
  // We want to block therapy and/or med manager scheduling if either of them are not licensed in member country
  const isProviderLicensed =
    (!careProvider?.roles?.includes("Therapist") &&
      !isMedicationManagerRole(careProvider?.roles)) ||
    providerLicensedCountries?.includes(memberCountry);

  // New Top Specialties added to Provider Card
  const therapyTopSpecialtiesFlag =
    useFeatureFlag(FLAGS.THERAPY_TOP_SPECIALTIES) &&
    providerType === ProviderRole.Therapist;

  const isTimeSlotSelected = !!formData.data?.startTime?.selectedDateTime;

  const isFromSUDSignalExperience =
    router?.query?.isFromSUDSignalExperience ?? false;
  const sudSignalExperimentValue =
    router?.query?.sudSignalExperimentValue ?? false;

  const isSpecialtyCareNavigationAppointment =
    appointmentKindFromProps ===
      AppointmentKind.InitialSpecialtyCareNavigation ||
    appointmentKindFromProps ===
      AppointmentKind.FollowUpSpecialtyCareNavigation;

  // `kind` is used for *fetching* appointment slots
  const kind = () => {
    if (appointmentKindFromProps === AppointmentKind.InitialCareNavigation) {
      if (cnAppointments?.length > 0) {
        return AppointmentKind.FollowUpCareNavigation;
      }

      return AppointmentKind.InitialCareNavigation;
    }

    if (
      appointmentKindFromProps ===
        AppointmentKind.InitialMedicationManagement ||
      appointmentKindFromProps === AppointmentKind.FollowUpMedicationManagement
    ) {
      if (hasHadMedManagerAppt(careProvider, mmAppointments)) {
        return AppointmentKind.FollowUpMedicationManagement;
      }

      return AppointmentKind.InitialMedicationManagement;
    }

    if (appointmentKindFromProps === AppointmentKind.Therapy && isAMinor) {
      return AppointmentKind.MinorTherapy;
    }
    if (
      appointmentKindFromProps ===
      AppointmentKind.FollowUpPeerRecoverySpecialist
    ) {
      return AppointmentKind.FollowUpPeerRecoverySpecialist;
    }

    if (isSpecialtyCareNavigationAppointment) {
      return hasHadSpecialtyCareNavigationAppointment(
        careProvider,
        scnAppointments,
      )
        ? AppointmentKind.FollowUpSpecialtyCareNavigation
        : AppointmentKind.InitialSpecialtyCareNavigation;
    }

    return appointmentKindFromProps;
  };

  // `requestedKind` is used for *booking* and *requesting* appointments
  const requestedKind = () => {
    const isTherapyOrCouplesTherapyAppointmentKind =
      appointmentKindFromProps === AppointmentKind.Therapy ||
      appointmentKindFromProps === AppointmentKind.CouplesTherapy;

    if (
      appointmentKindFromProps === AppointmentKind.InitialCareNavigation ||
      appointmentKindFromProps === AppointmentKind.FollowUpCareNavigation
    ) {
      return RequestableAppointmentKind.CareNavigation;
    }

    if (
      appointmentKindFromProps ===
        AppointmentKind.InitialMedicationManagement ||
      appointmentKindFromProps === AppointmentKind.FollowUpMedicationManagement
    ) {
      return RequestableAppointmentKind.MedicationManagement;
    }

    if (appointmentKindFromProps === AppointmentKind.Therapy && isAMinor) {
      return RequestableAppointmentKind.MinorTherapy;
    }

    if (
      isTherapyOrCouplesTherapyAppointmentKind &&
      selectedTherapyType === RequestableAppointmentKindEnum.CouplesTherapy
    ) {
      return RequestableAppointmentKind.CouplesTherapy;
    }
    if (
      isTherapyOrCouplesTherapyAppointmentKind &&
      selectedTherapyType === RequestableAppointmentKindEnum.Therapy
    ) {
      return RequestableAppointmentKind.Therapy;
    }

    if (
      appointmentKindFromProps ===
        AppointmentKind.InitialPeerRecoverySpecialist ||
      appointmentKindFromProps ===
        AppointmentKind.FollowUpPeerRecoverySpecialist
    ) {
      return RequestableAppointmentKind.PeerRecoverySpecialist;
    }

    if (isSpecialtyCareNavigationAppointment) {
      return RequestableAppointmentKind.SpecialtyCareNavigation;
    }

    return appointmentKindFromProps;
  };

  const appointmentLengthInMinutes = () => {
    switch (kind()) {
      case AppointmentKind.InitialCareNavigation:
        return 20;
      case AppointmentKind.FollowUpCareNavigation:
        return 10;
      case AppointmentKind.InitialMedicationManagement:
        return 40;
      case AppointmentKind.FollowUpMedicationManagement:
        return 15;
      case AppointmentKind.Coaching:
        return isCoachingFollowup ? 30 : 45;
      case AppointmentKind.WellnessAndSocialSupportSession:
        return 35;
      case AppointmentKind.InitialCoaching:
        return 45;
      case AppointmentKind.FollowUpCoaching:
        return 30;
      default:
        return 55; // all other appointment types are 55 minutes
    }
  };

  const supportsInPerson = (provider) => {
    const member = getOr(memberInfo, "user.member", memberInfo); // check if nested member, else use top level object
    const country = member.postal_address?.country || "";
    const showGlobalExperience =
      country && country.length > 0 && country !== "US";

    const cohortSupportsInPerson =
      member?.cohort?.in_person_supported ||
      (showInPersonMedMan &&
        member?.cohort?.in_person_med_management_supported);
    const globalSupportsInPerson =
      !showGlobalExperience || enableInPersonGlobal;
    const ignoreDistanceForInPerson =
      isACareAdvocate(provider) || isMedicationManagerRole(provider?.roles);
    const providerRoleSupportsInPerson =
      isATherapist(provider) ||
      isACareAdvocate(provider) ||
      (isMedicationManagerRole(provider?.roles) && showInPersonMedMan);
    const providerSupportsInPerson = provider.in_person_supported;
    const withinDistanceLimit =
      provider.distance_miles <= inPersonDistance.limit35;

    return (
      (withinDistanceLimit || ignoreDistanceForInPerson) &&
      cohortSupportsInPerson &&
      globalSupportsInPerson &&
      providerRoleSupportsInPerson &&
      providerSupportsInPerson
    );
  };

  const supportsVirtual = (provider) => {
    return provider.virtual_supported;
  };

  const toast = useToast();
  const createToast = (text, status) => {
    toast({
      duration: 5000,
      containerStyle: {
        width: "100%",
      },
      render: () => (
        <SHNotification
          notification={{ text: text, status: status }}
          setNotification={() => true}
        />
      ),
    });
  };

  const create = async (start_at, selected_medium) => {
    const specializedTopics = () => {
      const personalDevelopmentParam =
        router.query["speciality-personalDevelopment"];
      const professionalGrowthParam =
        router.query["speciality-professionalGrowth"];
      const parentingParam = router.query["speciality-parenting"];
      const healthWellnessParam = router.query["speciality-healthWellness"];
      if (
        router.query.personalDevelopment === "true" ||
        personalDevelopmentParam === "true"
      ) {
        return SpecializedCoachingCardAnalyticsMapping.personalDevelopment;
      }
      if (
        router.query.professionalGrowth === "true" ||
        professionalGrowthParam === "true"
      ) {
        return SpecializedCoachingCardAnalyticsMapping.professionalGrowth;
      }
      if (router.query.parenting === "true" || parentingParam === "true") {
        return SpecializedCoachingCardAnalyticsMapping.parenting;
      }
      if (
        router.query.healthWellness === "true" ||
        healthWellnessParam === "true"
      ) {
        return SpecializedCoachingCardAnalyticsMapping.healthWellness;
      }
      return "";
    };
    try {
      const user = {
        user_id: Meowth.getUserId(),
        role: "Member",
      };

      const provider = {
        user_id: careProvider.user_id,
        role: providerRole,
      };
      const payload = {
        variables: {
          input: {
            put: {
              start_at,
              time_zone: DateTime.local().zoneName,
              kind: requestedKind(),
              medium: selected_medium || medium,
              bookings: [user, provider],
              reason_for_scheduling: reasonForScheduling,
              campaign: getIterableCampaignInfo(),
              specialty: specializedTopics(),
              ...(isFromSUDSignalExperience && {
                sud_signal_flag: sudSignalExperimentValue,
              }),
            },
          },
        },
      };

      const {
        data: { createAppointment: createdAppointment },
      } = await createAppointment({
        ...payload,
        // TODO: refactor
        refetchQueries: [
          {
            query: getAppointments,
            variables: {
              upcoming: true,
              past: false,
              id: Meowth.getUserId(),
            },
          },
          {
            query: getMemberCarePlanProgress,
            variables: {
              member_id: consent.user.member.id,
            },
          },
        ],
      });

      track("Appointment Directly Scheduled", {
        "Appointment Kind": requestedKind(),
        query_request_id: queryRequestId,
        spring_doc_id: "directsched032",
        appointment_id: createdAppointment.appointment.id,
        provider_id: createdAppointment.appointment.careProviderId,
        page: window.location.pathname,
        topic: specializedTopics(),
      });

      const { id } = createdAppointment.appointment;

      setAppointmentCreationPending(false);

      if (!routeTeenToInitialAssessment) {
        addNotification(
          t("scheduleModal.schedule.successNotification"),
          "success",
        );
      }
      closeModal(modalIds.careProviderScheduleModal);
      routeToConfirmationPage(routeTeenToInitialAssessment, id);
    } catch (error) {
      setAppointmentCreationPending(false);
      createToast(getFirstError(error), "error");
    }
  };

  const reschedule = async (start_at) => {
    try {
      const {
        data: { rescheduleAppointment: rescheduledAppointment },
      } = await rescheduleAppointment({
        id: appointmentId,
        start_at,
        reason_for_scheduling: reasonForScheduling,
        campaign: getIterableCampaignInfo(),
      });

      track("Appointment Directly Rescheduled", {
        "Appointment Kind": requestedKind(),
      });

      if (rescheduledAppointment.success) {
        setAppointmentCreationPending(false);

        closeModal(modalIds.careProviderScheduleModal);
        addNotification(
          t("scheduleModal.reschedule.successNotification"),
          "success",
        );
        const isRescheduled = true;
        routeToConfirmationPage(
          routeTeenToInitialAssessment,
          appointmentId,
          isRescheduled,
        );
      }
    } catch (error) {
      setAppointmentCreationPending(false);

      addNotification(getFirstError(error), "error");
    }
  };

  const handleSubmit = async () => {
    // Check if the member is a trial customer then show a toast that booking is not allowed
    const isTrialCustomer = member?.cohort?.customer?.trial;

    if (isTrialCustomer) {
      return showTrialBookingToast();
    }

    setAppointmentCreationPending(true);
    const startTime =
      formData?.data && formData?.data.startTime.selectedDateTime;
    const selectedMedium =
      formData?.data && formData?.data?.startTime?.selectedMedium;

    if (!startTime) {
      setAppointmentCreationPending(false);
      return addNotification(
        t("scheduleModal.submit.infoNotification"),
        "info",
      );
    }

    // If the member hasn't previously accepted out consent forms
    // we have to require them to do so and then save the careProvider
    if (
      memberConsentForm?.informed_consent ||
      memberConsentForm?.hipaa_privacy_policy
    ) {
      await updateMember({
        id: consent.user.member.id,
        patch: {
          informed_consent: memberConsentForm.informed_consent,
          hipaa_privacy_policy: memberConsentForm.hipaa_privacy_policy,
        },
      });
    }

    let topTags = "";
    if (therapyTopSpecialtiesFlag && careProvider?.top_tags?.length > 0) {
      topTags = careProvider?.top_tags?.sort()?.join(", ");
    }

    // directsched030
    trackConfirmAppointmentoInModalClicked({
      appointmentKind: kind(),
      appointmentMedium: selectedMedium,
      provider: careProvider,
      providerIndex: providerOrder + 1,
      pageNumber,
      queryRequestId,
      startTime,
      modalId: modalUUID,
      isSUDcn,
      visits_remaining_total: visitsRemainingTotal,
      inNetwork,
      topTags: topTags,
      profileTabFlag: currentRoute?.includes("/members/providers/"),
      is_associate: careProvider?.associate_status === "active",
      category: getTherapistCategory(),
      isFromSUDSignalExperience,
      sudSignalExperimentValue,
    });

    if (action === AppointmentAction.Reschedule) {
      return reschedule(startTime);
    }

    const { isCoachingConsultation } = careProvider;
    if (isCoachingConsultation) {
      track("Coaching -- Member scheduled initial coaching consultation");
    }

    return create(startTime, selectedMedium);
  };

  const handleShiftTab = (e) => {
    e.preventDefault();
    const selectedDateTime = formData?.data?.startTime?.selectedDateTime;
    const selectedDateTimeElement = document.querySelector(
      `[data-id="${selectedDateTime}"]`,
    );

    selectedDateTimeElement.focus();
  };

  const address = () => {
    if (!careProvider) return "";
    let address = null;

    if (isACareAdvocate(careProvider)) {
      // The address for a Care Advocate is either already on the appointment
      // Or can be pulled from the Customer Site (if present)
      address = appointmentLocation || customerSiteAddress(customerSite);
    }

    // if not a care advocate or if for some reason the customer site is broken
    // use the address of the care provider's user
    if (!address) {
      const { street_address_1, street_address_2, city, state, zip_code } =
        careProvider;
      const userAddress = [street_address_1, street_address_2, city, state]
        .filter(Boolean)
        .join(", ");

      address = `${userAddress} ${zip_code}`;
    }

    return address;
  };

  // Checks to see if the member has consented to our policies
  const hasConsent = () => {
    const previousConsent = getOr({}, "user.member", consent);
    const formConsent = memberConsentForm;
    const isInformedConsentChecked =
      previousConsent?.informed_consent || formConsent?.informed_consent;
    const isHipaaConsentChecked =
      previousConsent?.hipaa_privacy_policy ||
      formConsent?.hipaa_privacy_policy;

    if (showGlobalExperience) {
      return isInformedConsentChecked;
    }

    return isInformedConsentChecked && isHipaaConsentChecked;
  };

  const isAMinor = isMinor(member);
  const minorAppointmentCallout = () => {
    const firstName = getOr("the child", "user.first_name", userInfo);

    if (isAMinor) {
      return (
        <MinorAppointmentCallout
          isACareNavigator={isACareNavigator(careProvider)}
          firstName={firstName}
        />
      );
    }

    return null;
  };

  const appointmentSlots = () => {
    const member = memberInfo?.user?.member || {};
    const {
      inside_inactivation_window: insideInactivationWindow,
      access_limit_date: terminateAt,
    } = member;
    const terminateAtFormatted = terminateAt
      ? DateTime.fromISO(terminateAt).toLocaleString(DateTime.DATE_FULL)
      : null;

    if (
      careProvider.acuity_calendar_id ||
      careProvider?.status === ProviderStatus.Approved ||
      careProvider?.status === ProviderStatus.NoNewPatients
    ) {
      const luxonDateTime = DateTime.local().setLocale(locale);
      const timeZoneOffsetName = getOr(
        "your local time zone",
        "offsetNameLong",
        luxonDateTime,
      );

      const selectedStartTime =
        formData?.data?.initialStartTime?.selectedDateTime || prevInitialTime;
      const selectedMedium = formData?.data?.initialStartTime?.selectedMedium;

      const mediumVal = selectedMedium || medium;
      const startTime = selectedStartTime || initialStartTime;

      const show24HourWarning = () => {
        let warning24Time = new Date();
        warning24Time.setHours(warning24Time.getHours() + 24);
        const selectedDateTime = formData.data?.startTime?.selectedDateTime;
        const startTime24 = new Date(selectedDateTime);

        return selectedDateTime && warning24Time > startTime24;
      };

      return (
        <Box>
          <div
            className={styles.form}
            style={isMobile && isBrowseOrRecs ? { marginTop: "-10px" } : {}}
          >
            <Section size="sm">
              <FlexRow alignment="center">
                <Heading variant="sm_v1" color="#182023" pb={8}>
                  {t("scheduleModal.appointmentSlots.scheduleVisit")}
                </Heading>
              </FlexRow>
              {careProvider.on_site && (
                <Content>
                  <OnsiteBadge />
                </Content>
              )}
              {insideInactivationWindow && terminateAt && (
                <div className={styles.memberTerming}>
                  <FlexRow alignment="center" justification="center">
                    <div className={styles.warningIcon}>
                      <WarningIcon />
                    </div>
                    <Bolded>
                      {t(
                        "memberTermination:scheduleModal.approachingTerminationDate",
                        { terminateAtFormatted },
                      )}
                    </Bolded>
                  </FlexRow>
                </div>
              )}

              <div className={styles.timeZoneInfo}>
                {isACareNavigator(careProvider) && (
                  <Trans
                    ns="careProvider"
                    i18nKey="scheduleModal.appointmentSlots.careNavigator.visitDuration"
                  >
                    All times are in <b>{{ timeZoneOffsetName }}</b>. Care calls
                    are
                    <b>
                      {{
                        appointmentLengthInMinutes:
                          appointmentLengthInMinutes(),
                      }}
                      mins
                    </b>
                    . Your care navigator will call you at your number we have
                    on file..
                  </Trans>
                )}
                {!isACareNavigator(careProvider) && getAppointmentsLoading && (
                  <Spinner size="xl" />
                )}
                {!isACareNavigator(careProvider) && !getAppointmentsLoading && (
                  <Trans
                    ns="careProvider"
                    i18nKey="scheduleModal.appointmentSlots.therapist.visitDuration"
                  >
                    All times are in <b>{{ timeZoneOffsetName }}</b>. Sessions
                    are
                    <b>
                      {{
                        appointmentLengthInMinutes:
                          appointmentLengthInMinutes(),
                      }}
                      mins
                    </b>
                    .
                  </Trans>
                )}
              </div>

              {!isACareNavigator(careProvider) && (
                <div className={styles.cnMinorCallout}>
                  {minorAppointmentCallout()}
                </div>
              )}
            </Section>

            {isACareAdvocate(careProvider) && showComingSoonBanner && (
              <ComingSoonBanner />
            )}

            {upcomingAppointmentSlotsLoading && (
              <Center>
                <Spinner size="xl" />
              </Center>
            )}

            {!upcomingAppointmentSlotsLoading && (
              <Form
                preserve
                submitText={t("common:form.submitText")}
                schema={schema}
                formKey={`care-provider-schedule-${careProvider.id}`}
              >
                {!isACareNavigator(careProvider) &&
                  action !== AppointmentAction.Reschedule && (
                    <AppointmentTabs
                      fieldKey="startTime"
                      bookings={[Meowth.getUserId(), careProvider.user_id]}
                      kind={kind()}
                      start={
                        startTime ||
                        DateTime.local()
                          .plus({ days: 2 })
                          .set({ minutes: 0, seconds: 0, milliseconds: 0 })
                          .toISO()
                      }
                      medium={mediumVal}
                      dayIncrement={3}
                      stripe={false}
                      terminateAt={terminateAt}
                      providerId={careProvider.id}
                      provider={careProvider}
                      isALegacyMinor={isALegacyMinor}
                      isACareNavigator={isACareNavigator(careProvider)}
                      supportsInPerson={supportsInPerson(careProvider)}
                      supportsVirtual={supportsVirtual(careProvider)}
                      t={t}
                      modal_id={modalUUID}
                      queryRequestId={queryRequestId}
                      showGlobalExperience={showGlobalExperience}
                      setShowComingSoonBanner={setShowComingSoonBanner}
                      locale={locale}
                      defaultTab={defaultTab}
                    />
                  )}

                {(isACareNavigator(careProvider) ||
                  action === AppointmentAction.Reschedule) && (
                  <AppointmentSlots
                    fieldKey="startTime"
                    bookings={[Meowth.getUserId(), careProvider.user_id]}
                    kind={kind()}
                    medium={mediumVal}
                    start={
                      startTime ||
                      DateTime.local()
                        .plus({ hours: 25 })
                        .set({ minutes: 0, seconds: 0, milliseconds: 0 })
                        .toISO()
                    }
                    dayIncrement={3}
                    stripe={false}
                    terminateAt={terminateAt}
                    providerId={careProvider.id}
                    provider={careProvider}
                    isACareNavigator={isACareNavigator(careProvider)}
                    modal_id={modalUUID}
                    queryRequestId={queryRequestId}
                    locale={locale}
                  />
                )}
              </Form>
            )}

            {showReasonForSchedulingInput && (
              <ReasonForScheduling
                reasonForScheduling={reasonForScheduling}
                onChange={handleReasonForSchedulingChange}
              />
            )}
          </div>
          <Box
            className={
              !modalUUID && isTimeSlotSelected ? styles.stickyContainer : ""
            }
          >
            {isACareNavigator(careProvider) && (
              <Box className={styles.cnMinorCallout}>
                {minorAppointmentCallout()}
              </Box>
            )}

            <Section size="sm">
              <SeasonalityWarning
                appointmentTime={formData?.data?.startTime?.selectedDateTime}
              />
              <MemberConsent showHipaaCheckbox={!showGlobalExperience} />
              {show24HourWarning() && <Warning24Hour />}
              <ScheduleButton />
            </Section>
          </Box>

          {isACareNavigator(careProvider) && !isAMinor && !isSUDcn && (
            <Section size="xlg">
              <div className={styles.footerText}>
                <Content dark>
                  If you prefer to pair with a different Care Navigator,
                  <SHButton
                    style={{ boxShadow: "none", width: "auto" }}
                    variant="link"
                    display="inline"
                    onClick={() => handleChangeNavigator()}
                    mx={"4px"}
                    colorScheme="primary"
                    textDecoration="underline"
                  >
                    request a change
                  </SHButton>
                  and we'll find a better match.
                </Content>
              </div>
            </Section>
          )}
        </Box>
      );
    } else if (envUtils.isInternalEnv()) {
      return <NonAcuityContent providerData={careProvider} isModal={true} />;
    }

    return null;
  };

  const getAppointmentMediumText = () => {
    const selectedMedium = formData?.data?.startTime?.selectedMedium;
    if (isACareNavigator(careProvider)) return t("scheduleModal.phoneCall");

    if (selectedMedium === AppointmentMedium.Video) {
      return t("scheduleModal.virtualSession");
    }
    return t("scheduleModal.inPersonSession");
  };

  const getAppointmentInstructions = () => {
    const selectedMedium = formData?.data?.startTime?.selectedMedium;

    if (
      isACareNavigator(careProvider) ||
      isCoachingAppointment ||
      isPeerRecoverySpecialist(careProvider) ||
      (isACareAdvocate(careProvider) &&
        selectedMedium === AppointmentMedium.Video) ||
      (showGlobalExperience && selectedMedium === AppointmentMedium.Video)
    ) {
      // Don't need the bottom appt instructions for these types of appts
      return;
    }

    if (
      selectedMedium === AppointmentMedium.Video &&
      !!careProvider.states.length
    ) {
      return (
        <>
          {appointmentKindFromProps !== "FOLLOW_UP_MEDICATION_MANAGEMENT" &&
            appointmentKindFromProps !== "INITIAL_MEDICATION_MANAGEMENT" && (
              <Trans
                ns="careProvider"
                i18nKey="scheduleModal.appointmentSlots.virtualStateDetails"
              >
                To legally receive therapy from this provider you need to be
                located in one of these states during the appointment time:{" "}
                {{ states: careProvider.states.join(", ") }}.
              </Trans>
            )}
          {(appointmentKindFromProps === "FOLLOW_UP_MEDICATION_MANAGEMENT" ||
            appointmentKindFromProps === "INITIAL_MEDICATION_MANAGEMENT") && (
            <>
              <Trans
                ns="careProvider"
                i18nKey="scheduleModal.appointmentSlots.medicationManagerVirtualStateDetails"
              >
                To legally receive care from this provider you need to be
                located in one of these states during the appointment time:{" "}
                {{ states: careProvider.states.join(", ") }}.
              </Trans>
              <br />
              <Trans
                ns="careProvider"
                i18nKey="scheduleModal.medicationManager.disclaimer"
              >
                Please note that Spring Health does not prescribe Controlled
                Substance medications (stimulants and benzodiazepines) through
                tele-health appointments due to federal and state regulations.
                Alternative medications are likely available for your situation.
              </Trans>
            </>
          )}
        </>
      );
    }

    return (
      <div className={styles.appointmentInstructions}>
        <Trans
          ns="careProvider"
          i18nKey="scheduleModal.appointmentSlots.inPersonDetails"
        >
          You will be meeting {{ firstName: careProvider.first_name }} at{" "}
          {{ address: address() }}. Please arrive at least 5 mins before your
          session.
        </Trans>
      </div>
    );
  };

  const Warning24Hour = () => {
    const ref = useRef();
    const inViewport = useInViewportOnce(ref, "0px");

    useEffect(() => {
      if (inViewport) {
        track24HourWarning({ providerId: careProvider.id });
      }
    }, [inViewport]);

    return (
      <>
        {isTimeSlotSelected && (
          <Flex
            ref={ref}
            pb={30}
            pt={24}
            direction={["column", "row"]}
            bg="white"
            justify="center"
          >
            <Box pb={[8, 0]} pr={[0, 8]}>
              <CelebrateIcon
                width="24"
                height="24"
                borderRadius="8px"
                fillColor="#045642"
              />
            </Box>
            <Box>
              <Text as="span" fontWeight={600}>
                {t("scheduleModal.appointmentSlots.bold24hours")}
              </Text>
              <Text
                as="span"
                fontWeight={400}
                fontSize="16px"
                lineHeight="24px"
                color="#171E22"
                pl={[8]}
                aria-live="polite"
              >
                {t("scheduleModal.appointmentSlots.warning24Hour")}
              </Text>
            </Box>
          </Flex>
        )}
      </>
    );
  };

  const ContinueToBookButtonLink = () => {
    return (
      <NextLink
        href={{
          pathname: `/members/providers/${careProvider.id}/finish-booking`,
          query: {
            appointmentTime: `${formData.data?.startTime?.selectedDateTime}`,
            appointmentMedium: `${formData?.data?.startTime?.selectedMedium}`,
            appointmentKind: kind(),
            ...(pageNumber && { pageNumber: pageNumber }),
            ...(++providerOrder && { providerIndex: providerOrder }),
          },
        }}
        passHref
      >
        <Button
          variant="high-emphasis"
          w="100%"
          h="58px"
          type="submit"
          fontWeight={600}
          as="a"
          role="link"
        >
          {t("scheduleModal.continueToBook")}
        </Button>
      </NextLink>
    );
  };

  const BookingOrSubmitButton = () => {
    const isCareNavigatorAppt = isACareNavigator(careProvider);

    const hasFreeOrCoveredCoaching =
      !isCostTransparencyEnabled &&
      paidCoachingExperience &&
      (coachingSessionIsFree || memberCostOfCoaching == 0);

    if (canAccessGatedBookingLoading) return <Spinner size="xl" />;

    // Direct Schedule:
    // 1. For covered reschedule sessions
    // 2. For covered coaching
    // 3. showGatedBooking request returns false
    if (
      isCoveredReschedule ||
      !canAccessGatedBooking ||
      hasFreeOrCoveredCoaching
    ) {
      return (
        <ConfirmButton
          appointmentCreationPending={appointmentCreationPending}
          handleSubmit={handleSubmit}
          handleShiftTab={handleShiftTab}
          buttonText={buttonText}
          hasConsent={hasConsent}
          showCancelApptPolicy={
            showAcknowledgeLateCancelFlag &&
            member?.missed_late_cancel_policy_ack_required
          }
          setDisplayState={setDisplayState}
          isCareNavigatorAppt={isCareNavigatorAppt}
        />
      );
    } else if (canAccessGatedBooking) {
      return <ContinueToBookButtonLink />;
    }
  };

  const ScheduleButton = () => {
    return (
      <section id="schedule-button">
        {isTimeSlotSelected && (
          <>
            <Box className={styles.scheduleButtonWrapper}>
              <Box className={styles.appointmentDetails} color={"black"}>
                <FlexRow alignment="center" justification="center">
                  <Text size="body-medium-strong">
                    {getAppointmentMediumText()}
                    <VBulletIcon style={{ "vertical-align": "middle" }} />
                    {DateTime.fromISO(
                      formData.data?.startTime?.selectedDateTime,
                    )
                      .setLocale(locale)
                      .toLocaleString(DateTime.DATE_MED)}
                    <VBulletIcon style={{ "vertical-align": "middle" }} />
                    {DateTime.fromISO(
                      formData.data?.startTime?.selectedDateTime,
                    ).toFormat("h:mm")}
                    &nbsp;{"\u002D"}&nbsp;
                    {DateTime.fromISO(
                      formData.data?.startTime?.selectedDateTime,
                    )
                      .plus({ minutes: appointmentLengthInMinutes() })
                      .toFormat("h:mm a")}
                  </Text>
                </FlexRow>
              </Box>
              <FlexRow alignment="center" justification="center">
                <BookingOrSubmitButton />
              </FlexRow>
              {paidCoachingExperience && (
                <FlexRow alignment="center" justification="center">
                  {!isCostTransparencyEnabled && (
                    <div className={styles.appointmentCostInfo}>
                      {(coachingSessionIsFree || memberCostOfCoaching === 0) &&
                      !canAccessGatedBooking ? (
                        <>
                          <div className={styles.appointmentCostIcon}>
                            <CelebrateIcon ariaHidden fillColor="#182023" />
                          </div>
                          {t("scheduleModal.appointmentCost.free")}
                        </>
                      ) : (
                        <span className={styles.costInfo}>
                          <strong>
                            {t("scheduleModal.appointmentCost.paid", {
                              cost: money_format(memberCostOfCoaching),
                            })}
                          </strong>{" "}
                          {t("scheduleModal.appointmentCost.alt_paid")}
                        </span>
                      )}
                    </div>
                  )}
                </FlexRow>
              )}

              {!paidCoachingExperience &&
                ((visitsRemainingTotal > 0 && !isMedicationManagementAppt) ||
                  (visitsRemainingSpecialist > 0 &&
                    isMedicationManagementAppt)) && (
                  <FlexRow alignment="center" justification="center">
                    {!isCostTransparencyEnabled && (
                      <div className={styles.appointmentCostInfo}>
                        <>
                          <div className={styles.appointmentCostIcon}>
                            <CelebrateIcon ariaHidden fillColor="#182023" />
                          </div>
                          {t("scheduleModal.appointmentCost.free")}
                        </>
                      </div>
                    )}
                  </FlexRow>
                )}

              {visitsRemainingSpecialist === 0 &&
                isMedicationManagementAppt && (
                  <FlexRow alignment="center" justification="center">
                    {!isCostTransparencyEnabled && (
                      <div className={styles.appointmentCostInfo}>
                        <span>
                          {t("scheduleModal.appointmentCost.medicationPaid")}
                        </span>
                      </div>
                    )}
                  </FlexRow>
                )}

              <FlexRow
                alignment="center"
                justification="center"
                alignItems="center"
              >
                <Text
                  color="black"
                  paddingTop={8}
                  paddingBottom={8}
                  textAlign="center"
                  variant={"body_v1"}
                  maxW={533}
                >
                  {isTherapyOrMedManagerModal &&
                    !careProvider.dedicated_customer?.name && (
                      <InNetworkStatusDisclaimer
                        showIcon={false}
                        showCTA={false}
                        inNetwork={member?.cohort?.in_network}
                        internationalCohort={member?.cohort?.international}
                        customerName={member?.cohort?.customer?.name}
                        hasFreeSessions={!!member?.visits_covered_total}
                        hasMedManagementFreeSessions={
                          !!member?.visits_covered_specialist
                        }
                        medicationManagement={isMedicationManagerRole(
                          careProvider?.roles,
                        )}
                        virtualSessionCostTerm={
                          member?.cohort?.contract_term
                            ?.virtual_session_cost_term
                        }
                        inNetworkDisclaimerConfig={{
                          inNetworkFreeSessions: t("inNetworkFreeSessions", {
                            customerName: member?.cohort?.customer?.name,
                          }),
                          inNetworkNoFreeSessions: t(
                            "inNetworkNoFreeSessions",
                            {
                              customerName: member?.cohort?.customer?.name,
                            },
                          ),
                          notInNetworkFreeSessions: null,
                          notInNetworkNoFreeSessions: null,
                          default: null,
                        }}
                      />
                    )}
                </Text>
              </FlexRow>
              <Flex>
                {!isACareNavigator(careProvider) && <CancellationPolicy />}
              </Flex>
            </Box>
            <Flex py={16} fontSize={12} backgroundColor="platform.on">
              {getAppointmentInstructions()}
            </Flex>
          </>
        )}
      </section>
    );
  };

  const providerTags = () =>
    careProvider?.care_provider_tags
      ? includeTagsSubset(careProvider?.care_provider_tags)
      : [];

  const handleChangeNavigator = () => {
    TRACK_EVENT.BUTTON_CLICKED(
      window.location.pathname,
      "Request Care Navigator Change",
      {
        spring_doc_id: "cnsched015",
        location: getModalNameForTracking(modalIds.careProviderScheduleModal),
        provider_id: careProvider.id,
        appointment_type: getAppointmentKindForTracking(careProvider.kind),
        appointment_medium: getAppointmentMediumForTracking(
          careProvider.medium,
        ),
      },
    );
    openModal(modalIds.changeCareProviderModal, {
      careProviderId: careProvider.id,
      role: careProvider.roles[0],
    });
    closeModal(modalIds.careProviderScheduleModal);
  };

  if (cnAppointments?.loading) {
    return null;
  }

  const distanceResult =
    enableInPersonGlobal && showGlobalExperience
      ? formatKilometers(careProvider?.distance_kilometers, false)
      : formatMiles(careProvider?.distance_miles, false);

  const memberAddressState = member?.postal_address?.state;

  let licensedStatesToShow = careProvider?.states
    ? [...careProvider.states]
    : [];
  if (isDemoEnvironment() && memberAddressState) {
    licensedStatesToShow = [memberAddressState];
  } else if (memberAddressState === "CA" && careProvider?.license_number) {
    const indexOfCA = licensedStatesToShow?.indexOf("CA");
    if (indexOfCA > -1) {
      licensedStatesToShow?.splice(indexOfCA, 1);
      licensedStatesToShow?.push(`CA (#${careProvider?.license_number})`);
    }
  }

  const showLicensedInStates =
    !isACareNavigator(careProvider) &&
    !isACareAdvocate(careProvider) &&
    !!licensedStatesToShow?.length &&
    (showDetails ||
      (memberAddressState === "CA" && careProvider?.license_number));

  if (displayState === COST_ESTIMATE_DISPLAY_STATES.CANCELLATION_POLICY) {
    return (
      <AcknowledgeLateCancelContent
        memberId={member?.id}
        onComplete={handleSubmit}
        buttonText={t("cancellationPolicy.bookAppointment")}
        trackingType="Book Appointment (Cancel Policy Acknowledged)"
        trackingProps={{
          location: "Provider Schedule Page",
          to: "Appointment Confirmation Page",
        }}
      />
    );
  }

  return (
    <div className={styles.body}>
      {careProvider.dedicated_customer?.name && (
        <DedicatedIcon
          dedicatedText={`${t("dedicatedText")} ${careProvider.dedicated_customer.name}`}
        />
      )}

      {shouldShowNoCostBadge && <AppointmentNoCostBadge />}
      <CareProviderOverview
        isBrowseOrRecs={isBrowseOrRecs}
        shouldShowNoCostBadge={shouldShowNoCostBadge}
        careProvider={careProvider}
        therapyTopSpecialtiesFlag={therapyTopSpecialtiesFlag}
        providerRole={providerRole}
        supportsProviderConsultation={supportsProviderConsultation}
      />
      <div>
        {careProvider.bio && showDetails && (
          <Section size="lg">
            {supportsProviderConsultation && (
              <Heading size="heading-small" pb={16}>
                {t("aboutBio", { name: careProvider?.first_name })}
              </Heading>
            )}
            <Content dark>{careProvider.bio}</Content>
          </Section>
        )}
      </div>

      {supportsInPerson(careProvider) && showDetails && (
        <>
          <div>
            <Stout dark semibold>
              {t("located")}{" "}
              {distanceResult.distance +
                " " +
                distanceResult.distanceUnit +
                " " +
                t("away")}
              :
            </Stout>
            <Section size="sm">
              <Link
                aria-label={address()}
                to={"https://www.google.com/maps/search/"}
                queries={{ api: 1, query: `${address()}` }}
                mpTracking={{
                  page: window.location.pathname,
                  type: "Provider Address",
                  location: getModalNameForTracking(
                    modalIds.careProviderScheduleModal,
                  ),
                  modal_id: modalUUID,
                  spring_doc_id: "directsched002",
                }}
              >
                <Content dark underline>
                  {address()}
                </Content>
                &nbsp;
                <span>
                  <OpenInNewTab />
                </span>
              </Link>
            </Section>
            {customerSite?.care_providers.length > 1 && (
              <Section>
                <Text size="body-medium-strong" mt={24} mb={16}>
                  {t("multiCCAProviderContent.content")}
                </Text>
                <Button
                  colorScheme="primary"
                  variant="medium-emphasis"
                  onClick={handleCCANext}
                  rightIcon={<VArrowRightIcon />}
                >
                  {t("multiCCAProviderContent.button")}
                </Button>
              </Section>
            )}
          </div>
        </>
      )}

      {!isModal ? (
        <ProviderDetailsV2
          careProvider={careProvider}
          showLicensedInStates={showLicensedInStates}
          licensedStatesToShow={licensedStatesToShow}
          showTags={shouldShowTags(careProvider)}
          member={member}
          supportsInPerson={supportsInPerson(careProvider)}
        />
      ) : (
        <>
          {showDetails && !!careProvider?.languages?.length && (
            <Section size="lg">
              <Heading as={"h3"} fontSize={16} color="#182023">
                <Content bold>{t("language")}:</Content>
              </Heading>
              {careProvider.languages && (
                <Section size="sm">
                  <Content dark>{careProvider.languages.join(", ")}</Content>
                </Section>
              )}
            </Section>
          )}

          {showLicensedInStates && (
            <>
              <Section size="lg">
                <Heading as={"h3"} fontSize={16} color="#182023">
                  <Content bold>{t("detailModal.licensedIn")}:</Content>
                </Heading>
                <Section size="sm">
                  <Content dark>{licensedStatesToShow.join(", ")}</Content>
                  <span className={styles.tooltip}>
                    <Tooltip
                      text="To legally receive therapy from this provider you need to be located in one of these states during the appointment time."
                      placement={
                        isMobile &&
                        licensedStatesToShow?.join(", ")?.length > 25
                          ? "left"
                          : "right"
                      }
                      isTabIndex={true}
                      role="button"
                    />
                  </span>
                </Section>
              </Section>
            </>
          )}

          {!!providerTags()?.length &&
            shouldShowTags(careProvider) &&
            showDetails && (
              <>
                <div className={styles.tagsContainer}>
                  <Heading as={"h3"} fontSize={16} color="#182023">
                    <Content bold>{t("scheduleModal.canHelpYouWith")}</Content>
                  </Heading>
                  <div className={styles.tags}>
                    <div className={styles.conditionsTags}>
                      {providerTags().map((tag) => (
                        <Tag key={tag.id} text={tag.name} />
                      ))}
                    </div>
                  </div>
                </div>
              </>
            )}
        </>
      )}

      <div style={{ padding: !isModal ? "8px 0 16px" : "24px 0 0" }}>
        <CostEstimateDisclaimer
          variant={CostEstimateDisclaimerTypes.HALF}
          isModal={isModal}
        />
      </div>

      {isTherapyOrMedManagerModal && !careProvider.dedicated_customer?.name && (
        <Section alignment="left">
          <InNetworkStatusDisclaimer
            showCTA={false}
            inNetwork={member?.cohort?.in_network}
            hasFreeSessions={!!member?.visits_covered_total}
            internationalCohort={member?.cohort?.international}
            customerName={member?.cohort?.customer?.name}
            virtualSessionCostTerm={
              member?.cohort?.contract_term?.virtual_session_cost_term
            }
            inNetworkDisclaimerConfig={{
              inNetworkFreeSessions: t("inNetworkFreeSessions", {
                customerName: member?.cohort?.customer.name,
              }),
              inNetworkNoFreeSessions: t("inNetworkNoFreeSessions", {
                customerName: member?.cohort?.customer.name,
              }),
              notInNetworkFreeSessions: null,
              notInNetworkNoFreeSessions: null,
              default: null,
              dontShowHighmarkCopy: true,
            }}
          />
        </Section>
      )}

      {upcomingAppointments?.length > 0 && isProviderLicensed && (
        <>
          <div>
            <Section size="lg">
              <Heading
                as={"h3"}
                fontSize={16}
                color="#182023"
                fontWeight={"semibold"}
              >
                Upcoming Appointments:
              </Heading>
              <Table
                tableKey="UpcomingAppointments"
                data={upcomingAppointments}
                keys={{
                  date: t("upcomingAppointments.date", {
                    ns: "memberDashboard",
                  }),
                  time: t("upcomingAppointments.time", {
                    ns: "memberDashboard",
                  }),
                  kind: t("upcomingAppointments.kind", {
                    ns: "memberDashboard",
                  }),
                  status: t("upcomingAppointments.status", {
                    ns: "memberDashboard",
                  }),
                }}
                hideAt={{
                  md: ["time"],
                  lg: ["kind"],
                }}
                mutators={{
                  date: (_, data) =>
                    DateTime.fromISO(data.start_at)
                      .setLocale(locale)
                      .toFormat("LLL dd, yyyy"),
                  time: (_, data) =>
                    DateTime.fromISO(data.start_at)
                      .toLocal()
                      .toFormat("h:mm a"),
                  kind: (_, data) => {
                    if (!data.kind.toLowerCase().includes("therapy"))
                      return sanitizeEnum(data.kind);

                    const medium = data.medium.toLowerCase().includes("person")
                      ? t("upcomingAppointments.inPerson", {
                          ns: "memberDashboard",
                        })
                      : t("upcomingAppointments.virtual", {
                          ns: "memberDashboard",
                        });
                    return `${sanitizeEnum(data.kind)} - ${medium}`;
                  },
                  status: (s) => {
                    if (s === "Cancelled") {
                      return <Content status="error">{s}</Content>;
                    }
                    return s;
                  },
                }}
              />
            </Section>
          </div>
        </>
      )}

      {(isACareNavigator(careProvider) ||
        supportsVirtual(careProvider) ||
        supportsInPerson(careProvider)) &&
        isProviderLicensed && (
          <>
            {showCouplesTherapy &&
              careProvider?.roles.includes("Therapist") && (
                <TherapyTypeSection
                  careProviderTags={careProvider.care_provider_tags}
                  setSelectedTherapyType={setSelectedTherapyType}
                />
              )}
            {/* The param value below will be dynamic once we add In-Person Appointment slots */}
            {appointmentSlots()}
          </>
        )}

      {!isProviderLicensed && (
        <Box borderRadius={7} mt={50} p={16} background="tertiary.50">
          <Trans
            i18nKey="careProvider:scheduleModal.unsupportedCountry"
            values={{
              providerLicensedCountries: providerLicensedCountries,
              memberCountry: memberCountry,
            }}
            components={[
              <br key={"0"} />,
              <br key={"1"} />,
              <Link key={"2"} to={navigatorLink} />,
            ]}
          />
        </Box>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  memberConsentForm: memberConsentSelector(state).data,
  showGlobalExperience: state.global?.showGlobalExperience,
  locale: state.global?.lang,
});

export default compose(
  connect(mapStateToProps, { addNotification, openModal, closeModal }),
  graphql(createAppointment, {
    name: "createAppointment",
    refetchQueries: ["getMemberCarePlanProgress"],
  }),
  graphql(updateMember, {
    props: ({ mutate }) => ({
      updateMember: (careProvider) =>
        mutate({ variables: { input: { ...careProvider } } }),
    }),
  }),
  graphql(getUserInfo, {
    options: Meowth.apolloOptionsUserId,
    name: "userInfo",
  }),
  graphql(getMemberConsent, {
    name: "consent",
    options: Meowth.apolloOptionsUserId,
  }),
  graphql(rescheduleAppointment, {
    props: ({ mutate }) => ({
      rescheduleAppointment: (data) =>
        mutate({ variables: { input: { ...data } } }),
    }),
  }),
)(CareProviderScheduleContent);
