import { MouseEventHandler } from "react";
import { useLocalParticipant } from "@livekit/components-react";
import {
  IconButton,
  Tooltip,
  VMicOffIcon,
  VMicOnIcon,
  VPresentIcon,
  VSmsIcon,
  VVideoChatIcon,
  VVideoOffIcon,
  VIndividualIcon,
  NotificationBadge,
  Box,
} from "@springcare/sh-component-library";
import {
  ToggleButtonTypes,
  DrawerTabTypes,
} from "components/templates/SHSessionRoom/types";
import {
  useSessionDevices,
  useSessionChatMessages,
  useSessionRoomDrawer,
  useSessionScreenSharingMetadata,
} from "context/SessionRoomContext";
import {
  trackDeviceToggleState,
  trackSessionDrawerToggle,
} from "components/templates/SHSessionRoom/telemetry/analytics";
import { useTranslation } from "hooks/react-i18next";

interface ToggleButtonProps {
  type: ToggleButtonTypes;
}

export const ToggleButton = ({ type }: ToggleButtonProps) => {
  const { t } = useTranslation("livekitExperience", {
    keyPrefix: "videoCallControls.toggleButton",
  });

  const {
    localParticipant,
    isCameraEnabled,
    isMicrophoneEnabled,
    isScreenShareEnabled,
  } = useLocalParticipant();

  const { numberOfUnreadChatMessages, setNumberOfUnreadChatMessages } =
    useSessionChatMessages();

  const { isRemoteParticipantSharingScreen } =
    useSessionScreenSharingMetadata();

  const {
    isSessionRoomDrawerOpen,
    setIsSessionRoomDrawerOpen,
    activeDrawerTab,
    setActiveDrawerTab,
  } = useSessionRoomDrawer();

  const { setSessionDevices } = useSessionDevices();

  let icon: object,
    toggleFunction: MouseEventHandler<HTMLButtonElement> | undefined,
    ariaLabel: string;
  let tooltipLabel = t("toggleButton");

  switch (type) {
    case ToggleButtonTypes.Microphone:
      icon = isMicrophoneEnabled ? VMicOnIcon : VMicOffIcon;
      toggleFunction = () => {
        localParticipant.setMicrophoneEnabled(!isMicrophoneEnabled);
        setSessionDevices((prevState) => ({
          ...prevState,
          isMicrophoneEnabled: !prevState.isMicrophoneEnabled,
        }));
        trackDeviceToggleState(
          ToggleButtonTypes.Microphone,
          !isMicrophoneEnabled,
          "meeting_room",
        );
      };
      ariaLabel = isMicrophoneEnabled
        ? t("turnMicrophoneOff")
        : t("turnMicrophoneOn");
      tooltipLabel = t("audio");
      break;
    case ToggleButtonTypes.Camera:
      icon = isCameraEnabled ? VVideoChatIcon : VVideoOffIcon;
      toggleFunction = () => {
        localParticipant.setCameraEnabled(!isCameraEnabled);
        trackDeviceToggleState(
          ToggleButtonTypes.Camera,
          !isCameraEnabled,
          "meeting_room",
        );
      };
      ariaLabel = isCameraEnabled ? t("turnCameraOff") : t("turnCameraOn");
      tooltipLabel = t("video");
      break;
    case ToggleButtonTypes.Chat:
      icon = VSmsIcon;
      toggleFunction = () => {
        if (!isSessionRoomDrawerOpen) {
          setIsSessionRoomDrawerOpen(true);
          setActiveDrawerTab(DrawerTabTypes.Chat);
          setNumberOfUnreadChatMessages(0);
          trackSessionDrawerToggle(ToggleButtonTypes.Chat, true, false);
        } else if (activeDrawerTab !== DrawerTabTypes.Chat) {
          setActiveDrawerTab(DrawerTabTypes.Chat);
          setNumberOfUnreadChatMessages(0);
          trackSessionDrawerToggle(ToggleButtonTypes.Chat, true, true);
        } else {
          setIsSessionRoomDrawerOpen(false);
          trackSessionDrawerToggle(ToggleButtonTypes.Chat, false, false);
        }
      };
      ariaLabel = t("openCloseChat");
      tooltipLabel = t("chat");
      break;
    case ToggleButtonTypes.Participants:
      icon = VIndividualIcon;
      toggleFunction = () => {
        if (!isSessionRoomDrawerOpen) {
          setIsSessionRoomDrawerOpen(true);
          setActiveDrawerTab("participants");
          trackSessionDrawerToggle(ToggleButtonTypes.Participants, true, false);
        } else if (activeDrawerTab !== DrawerTabTypes.Participants) {
          setActiveDrawerTab("participants");
          trackSessionDrawerToggle(ToggleButtonTypes.Participants, true, true);
        } else {
          setIsSessionRoomDrawerOpen(false);
          trackSessionDrawerToggle(
            ToggleButtonTypes.Participants,
            false,
            false,
          );
        }
      };
      ariaLabel = t("openCloseParticipantList");
      tooltipLabel = t("participants");
      break;
    case ToggleButtonTypes.Screensharing:
      icon = VPresentIcon;
      toggleFunction = () => {
        localParticipant.setScreenShareEnabled(!isScreenShareEnabled);
        trackSessionDrawerToggle(
          ToggleButtonTypes.Screensharing,
          !isScreenShareEnabled,
          false,
        );
      };
      ariaLabel = isScreenShareEnabled
        ? t("stopScreenSharing")
        : t("startScreenSharing");
      tooltipLabel = isRemoteParticipantSharingScreen
        ? t("disabledBecauseAnotherParticipantIsSharing")
        : t("screensharing");
      break;
  }

  return (
    <Tooltip label={tooltipLabel} aria-label={tooltipLabel}>
      <Box position="relative" w="fit-content" data-tip-disable={false}>
        <IconButton
          icon={icon}
          aria-label={ariaLabel}
          aria-live="polite"
          variant="medium-emphasis"
          bg="white"
          color="black"
          onClick={toggleFunction}
          disabled={
            type === ToggleButtonTypes.Screensharing &&
            isRemoteParticipantSharingScreen
          }
          sx={{
            _disabled: {
              // manually styling the disabled state due to weird default disabled styles
              opacity: 0.5,
              _hover: {
                backgroundColor: "white !important",
                color: "black !important",
                cursor: "not-allowed",
                opacity: "0.5 !important",
              },
            },
            _focus: {
              color: "black",
            },
            _active: {
              color: "black",
            },
          }}
        />
        {type === ToggleButtonTypes.Chat && numberOfUnreadChatMessages > 0 && (
          <NotificationBadge
            type="numeric"
            variant="high-emphasis"
            colorScheme="positive"
            position="absolute"
            transform="translate(calc(-50% - 4px), calc(-50% + 4px))"
          >
            {numberOfUnreadChatMessages}
          </NotificationBadge>
        )}
      </Box>
    </Tooltip>
  );
};

ToggleButton.displayName = "ToggleButton";
