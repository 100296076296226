import React, { useEffect, useRef, useState } from "react";
import { useRouter } from "next/router";
import { useQuery } from "@apollo/client";
import PropTypes from "prop-types";
import { compose } from "redux";
import { connect } from "react-redux";
import { DateTime } from "luxon";
import { camelCase } from "lodash";
import { VVideoChatIcon } from "@springcare/sh-component-library";
import {
  Content,
  FlexRow,
  InputWrapper,
  LoadingCircle,
  LocationPin,
  Tab,
  Tabs,
} from "@spring/smeargle";
import { setField } from "@spring/smeargle/actions";
import {
  AppointmentKind,
  AppointmentMedium,
  getAppointmentKindForTracking,
  getAppointmentMediumForTracking,
  inPersonDistance,
} from "@spring/constants";
import { withTranslation } from "react-i18next";
import { FLAGS, useFeatureFlag } from "utils/launchdarkly";
import {
  Box,
  Button,
  Center,
  Spinner,
  useMediaQuery,
} from "design-system/components";

import Slot from "./Slot";
import ProviderOfficeLocation from "./ProviderOfficeLocation";
import RequestAvailability from "./RequestAvailability";
import styles from "./styles.module.scss";
import { useInViewportOnce } from "hooks";

import { formFieldSelector } from "selectors/form";
import { getAppointmentSlots } from "operations/queries/appointment";
import {
  EVENT_TYPE,
  makeEventString,
  track,
  TRACK_EVENT,
} from "utils/mixpanel";
import routes from "routes";

import { MobileTimeSlots } from "components/molecules/MobileTimeSlots";
import { Call } from "design-system/assets";
import { formatDateWithPascalCaseMonth } from "modules/MemberDashboard/CareVisits/AppointmentDetails/utils/appointmentDetails.util";

const virtualLabel = "Virtual";
const inPersonLabel = "In-Person";
const phoneLabel = "Phone";

let UID = 0;

const NewAppointmentSlots = (props) => {
  let [tabCount, setTabCount] = useState(0);
  let [activeTab, setActiveTab] = useState(0);
  const [alreadyTrackedSlots, setAlreadyTrackedSlots] = useState([]);
  let [hasTracked, setHasTracked] = useState({
    VIDEO: false,
    IN_PERSON: false,
  });

  const isPeerRecoverySpecialistAppointment =
    props.kind === AppointmentKind.FollowUpPeerRecoverySpecialist;
  const isSpecialtyCareNavigationAppointment =
    props.kind === AppointmentKind.InitialSpecialtyCareNavigation ||
    props.kind === AppointmentKind.FollowUpSpecialtyCareNavigation;

  const [isMobileSize] = useMediaQuery("(max-width: 960px)");
  const shouldShowMobileSlots = isMobileSize || props.isVertical;
  const [trackedOnce, setTrackedOnce] = useState(false);

  const ref = useRef();
  const inViewport = useInViewportOnce(ref, "0px"); // Trigger as soon as the element becomes visible

  const enableInPersonGlobal = useFeatureFlag(FLAGS.IN_PERSON_GLOBAL);
  const router = useRouter();
  const currentRoute = router?.asPath;
  const isBrowseOrRecs =
    currentRoute === routes.TherapistsBrowse.as ||
    currentRoute === routes.RecommendedTherapists.as;
  const isMedicationManager =
    props?.modalData?.providerRole === "Medication Manager";

  const allowInPerson = () =>
    !props.isALegacyMinor &&
    (!props.showGlobalExperience || enableInPersonGlobal) &&
    props.supportsInPerson &&
    (isMedicationManager ||
      props.provider.distance_miles <= inPersonDistance.limit35);

  useEffect(() => {
    if (props.supportsVirtual) setTabCount((tabCount += 1));
    if (allowInPerson()) setTabCount((tabCount += 1));
  }, []);

  const setInitialStartTimeField = (initialStartTime, appointmentMedium) => {
    props.setField(
      props.formKey,
      props.initialStartTimeFieldKey,
      {
        selectedDateTime: initialStartTime,
        selectedMedium: appointmentMedium,
      },
      true, // sets dirty
    );
  };

  const handleChange = (dateTime, appointmentMedium) => {
    props.setField(
      props.formKey,
      props.fieldKey,
      {
        selectedDateTime: dateTime,
        selectedMedium: appointmentMedium,
      },
      true, // sets dirty
    );
  };

  const showValidation = () => {
    return !props.valid && (props.dirty || props.value);
  };

  const smallScreenSlotOptions = (
    virtualAppointments,
    inPersonAppointments,
    phoneAppointments,
    loading,
  ) => {
    return (
      <>
        {loading ? (
          <Center pb={20}>
            <Spinner speed="1s" size="xl" />
          </Center>
        ) : (
          showMobileViewSlots(
            virtualAppointments,
            inPersonAppointments,
            phoneAppointments,
          )
        )}
      </>
    );
  };

  const showMobileViewSlots = (
    virtualAppointments,
    inPersonAppointments,
    phoneAppointments,
  ) => {
    const mobileViewSlots = [];
    if (phoneAppointments && props.phoneSupported) {
      mobileViewSlots.push(
        appointmentSlotsSection(
          phoneAppointments,
          AppointmentMedium.Phone,
          true,
        ),
      );

      if (props.isCNAvailabilityModal) {
        return mobileViewSlots;
      }
    }
    if (virtualAppointments) {
      mobileViewSlots.push(
        appointmentSlotsSection(
          virtualAppointments,
          AppointmentMedium.Video,
          true,
        ),
      );
    }
    if (inPersonAppointments && allowInPerson()) {
      mobileViewSlots.push(
        appointmentSlotsSection(
          inPersonAppointments,
          AppointmentMedium.InPerson,
          true,
        ),
      );
    }
    // Brecs-specific logic to remove slots based on filters
    if (
      props.isVertical &&
      props.inPersonFilterSelected &&
      mobileViewSlots.length > 1
    ) {
      // remove the virtual slots if in person is selected
      mobileViewSlots.splice(0, 1);
    }
    if (
      props.isVertical &&
      !props.inPersonFilterSelected &&
      mobileViewSlots.length > 1
    ) {
      // remove the in-person slots if in person is not selected
      mobileViewSlots.splice(0, 1);
    }

    return mobileViewSlots;
  };

  const appointmentSlotsSection = (
    appointments,
    appointmentMedium,
    isMobile = false,
  ) => {
    const daySlots = [];
    const available = appointments && appointments.appointment_slots.available;
    const inPersonMedium = appointmentMedium === AppointmentMedium.InPerson;

    let initialStartTime = null;

    const buttonClickLocationForTracking =
      props.isProviderASpecializedCareNavigator
        ? "Specialized Care Navigator Availability Modal"
        : props.isRecsOrBrecs
          ? "Recommended Provider Card"
          : "Provider Card";

    function showSeeMoreTimesBtn() {
      if (props.isRecsOrBrecs) {
        return false;
      } else if (inPersonMedium && !props.isRecsOrBrecs) {
        return props.t("newAppointmentSlots.seeMoreInPersonTimes");
      }
      return props.t("newAppointmentSlots.seeMoreVirtualTimes");
    }

    function trackMobileSlotClick(localDateTime, index) {
      if (props.shouldTrack) {
        track("Browse Provider Page - Virtual Appointment Slot Clicked", {
          deprecated: true,
          replaced_with: makeEventString(EVENT_TYPE.BUTTON_CLICKED, {
            page: window.location.pathname,
            type: "Appointment Selected",
          }),
        });

        TRACK_EVENT.BUTTON_CLICKED(
          window.location.pathname,
          "Appointment Selected",
          {
            spring_doc_id: "directsched018",
            location: buttonClickLocationForTracking,
            to: "Care Provider Schedule Modal",
            provider_id: props.provider.id,
            appointment_type: getAppointmentKindForTracking(props.kind),
            appointment_medium:
              getAppointmentMediumForTracking(appointmentMedium),
            appointment_time: localDateTime.toUTC().toISO(),
            browse_page_number: props.pageNumber,
            provider_order: props.providerIndex + 1,
            order: index + 1,
            query_request_id: props.queryRequestId,
          },
        );
      }
    }

    if (Array.isArray(available) && !!available.length) {
      for (const [index, slot] of available.entries()) {
        const localDateTime = DateTime.fromISO(slot).setLocale(props.locale);
        const localTime = localDateTime.toLocal();
        const localIso = localTime.toISO();
        let time = localDateTime.toFormat("EEEE, LLLL dd - h:mm a");
        if (props.isCNAvailabilityModal) {
          time = localDateTime.toFormat("LLLL dd - h:mm a");
        }
        time = formatDateWithPascalCaseMonth(time);

        if (slot) {
          if (index === 0) {
            initialStartTime = localIso;
          }

          const key = `${props.provider.id}-${time}-${appointmentMedium}-${index}`;

          daySlots.push(
            <Slot
              slotIndex={index}
              dataCy="new-appointments"
              providerIndex={props.providerIndex}
              key={key}
              active={props.value === localIso}
              timeFormatted={time}
              slot={slot}
              medium={appointmentMedium}
              kind={props.kind}
              providerId={props.provider.id}
              pageNumber={props.pageNumber}
              smallSlotStyle={props.smallSlotStyle}
              queryRequestId={props.queryRequestId}
              isAssignedCN={props.isAssignedCN}
              isCNAvailabilityModal={props.isCNAvailabilityModal}
              isProviderASpecializedCareNavigator={
                props.isProviderASpecializedCareNavigator
              }
              disableTracking={
                props.disableTracking || alreadyTrackedSlots.includes(key)
              }
              onTrack={() => {
                setAlreadyTrackedSlots([...alreadyTrackedSlots, key]);
              }}
              onClick={(e) => {
                e.stopPropagation();
                if (props.shouldTrack) {
                  track(
                    "Browse Provider Page - Virtual Appointment Slot Clicked",
                    {
                      deprecated: true,
                      replaced_with: makeEventString(
                        EVENT_TYPE.BUTTON_CLICKED,
                        {
                          page: window.location.pathname,
                          type: "Appointment Selected",
                        },
                      ),
                    },
                  );

                  TRACK_EVENT.BUTTON_CLICKED(
                    window.location.pathname,
                    "Appointment Selected",
                    {
                      spring_doc_id: "directsched018",
                      location: buttonClickLocationForTracking,
                      to: "Care Provider Schedule Modal",
                      provider_id: props.provider.id,
                      appointment_type: getAppointmentKindForTracking(
                        props.kind,
                      ),
                      appointment_medium:
                        getAppointmentMediumForTracking(appointmentMedium),
                      appointment_time: localDateTime.toUTC().toISO(),
                      browse_page_number: props.pageNumber,
                      provider_order: props.providerIndex + 1,
                      order: index + 1,
                      query_request_id: props.queryRequestId,
                    },
                  );
                }
                initialStartTime = localDateTime
                  .set({ hours: 0, minutes: 0, seconds: 0, milliseconds: 0 })
                  .toISO();
                setInitialStartTimeField(initialStartTime, appointmentMedium);
                handleChange(localIso, appointmentMedium); // Convert to ISO to keep the local timezone
              }}
            />,
          );
        }
      }

      const seeMoreTimes = () => {
        setInitialStartTimeField(initialStartTime, appointmentMedium);
        handleChange(null, appointmentMedium);
        props.moreAction();
        if (props.shouldTrack) {
          track('Browse Provider Page - "See All Virtual Options" Clicked', {
            deprecated: true,
            replaced_with: makeEventString(EVENT_TYPE.BUTTON_CLICKED, {
              page: window.location.pathname,
              type: "See All Appointments",
            }),
          });

          const location = props.isProviderASpecializedCareNavigator
            ? "Specialized Care Navigator Availability Modal"
            : isBrowseOrRecs
              ? "Provider Card"
              : "Provider Profile";

          TRACK_EVENT.BUTTON_CLICKED(
            window.location.pathname,
            "See All Appointments",
            {
              spring_doc_id: "directsched015",
              location,
              to: "Care Provider Schedule Modal",
              provider_id: props.provider.id,
              appointment_type: getAppointmentKindForTracking(props.kind),
              appointment_medium:
                getAppointmentMediumForTracking(appointmentMedium),
              browse_page_number: props.pageNumber,
              provider_order: props.providerIndex + 1,
              query_request_id: props.queryRequestId,
            },
          );
        }
      };

      const isPhoneMedium = appointmentMedium === AppointmentMedium.Phone;

      let timeSlotText;
      if (inPersonMedium) {
        timeSlotText = inPersonLabel;
      } else if (isPhoneMedium) {
        timeSlotText = phoneLabel;
      } else {
        timeSlotText = virtualLabel;
      }

      return (
        <Box
          {...(props.isCNAvailabilityModal && { pt: 2 })}
          className={
            props.smallSlotStyle
              ? styles.smallTabContainer
              : styles.tabContainer
          }
          {...(isMobile && { key: "mobileSlot" })}
        >
          {inPersonMedium && !props.isVertical && (
            <ProviderOfficeLocation {...props} />
          )}

          {!shouldShowMobileSlots ? (
            <>
              <div className={styles.slotsContainer}>{daySlots}</div>

              <FlexRow justification="center">
                <Content>
                  <Button
                    id={`see-more-${props.provider.id}`}
                    data-cy={`${camelCase(
                      props.modalData?.providerRole,
                    )}-see-more-times`}
                    variant="link"
                    _focusVisible={{ boxShadow: "0 0 0 3px black" }}
                    fontWeight="normal"
                    colorScheme="gray.dark"
                    onClick={(e) => {
                      e.stopPropagation();
                      seeMoreTimes();
                    }}
                    _hover={{ textColor: "primary.700" }}
                    aria-label={props.t("newAppointmentSlots.seeMoreTimes")}
                  >
                    {props.t("newAppointmentSlots.seeMoreTimes")}
                  </Button>
                </Content>
              </FlexRow>
            </>
          ) : (
            <MobileTimeSlots
              providerId={props.provider.id}
              providerIndex={props.providerIndex}
              isPhoneMedium={isPhoneMedium}
              inPerson={inPersonMedium}
              timeSlotText={timeSlotText}
              timeSlot={DateTime.fromISO(initialStartTime)
                .toLocal()
                .toFormat("LLLL dd • h:mm a")}
              customIcon={
                inPersonMedium ? (
                  <LocationPin aria-hidden />
                ) : (
                  <VVideoChatIcon aria-hidden />
                )
              }
              openSchedulingModal={(event) => {
                const localDateTime = DateTime.fromISO(
                  initialStartTime,
                ).setLocale(props.locale);
                trackMobileSlotClick(localDateTime, 0);
                event.stopPropagation();
                setInitialStartTimeField(initialStartTime, appointmentMedium);
                handleChange(initialStartTime, appointmentMedium);
                props.moreAction(initialStartTime);
              }}
              seeMoreTimesLinkText={showSeeMoreTimesBtn()}
              openSeeMoreTimes={seeMoreTimes}
              queryRequestId={props.queryRequestId}
              isRecsOrBrecs={props.isRecsOrBrecs}
              isVertical={props.isVertical}
            />
          )}
        </Box>
      );
    }

    return (
      <RequestAvailability
        setHasTracked={setHasTracked}
        hasTracked={hasTracked}
        inPersonMedium={inPersonMedium}
        medium={appointmentMedium}
        {...props}
      />
    );
  };

  const useAppointmentSlots = (payload, medium, skip = false) => {
    let variables = { ...payload, medium: medium || AppointmentMedium.Video };

    if (
      shouldInferKind &&
      props.notTherapist &&
      !isPeerRecoverySpecialistAppointment &&
      !isSpecialtyCareNavigationAppointment
    ) {
      variables["should_infer_kind"] = true;
      delete variables["kind"]; // won't throw if it doesn't exist
    }

    const { data, loading } = useQuery(getAppointmentSlots, {
      fetchPolicy: "network-only",
      variables,
      skip,
    });

    return { data, loading };
  };

  const useAppointmentSlotsByMedium = () => {
    const payload = {
      user_ids: props.bookings,
      start_span: props.start,
      slot_increment: props.increment,
      kind: props.kind,
      end_span: DateTime.fromISO(props.start) // Set the end span based off how many days the dev wants to show
        .plus({ days: 29 })
        .set({ hours: 23, minutes: 59, seconds: 59, milliseconds: 0 })
        .toISO(),
      upcoming_slots_only: props.upcomingSlotsOnly,
      provider_index: props.providerIndex,
      page_number: props.pageNumber,
    };

    let inPersonSkip = !allowInPerson();
    let virtualSkip = !props.supportsVirtual;
    const skipPhoneAppointments = !(
      props.kind === AppointmentKind.InitialCareNavigation ||
      props.kind === AppointmentKind.FollowUpCareNavigation
    );

    const { data: virtualAppointments, loading: virtualLoading } =
      useAppointmentSlots(payload, AppointmentMedium.Video, virtualSkip);
    const { data: inPersonAppointments, loading: inPersonLoading } =
      useAppointmentSlots(payload, AppointmentMedium.InPerson, inPersonSkip);
    const { data: phoneAppointments, loading: phoneLoading } =
      useAppointmentSlots(
        payload,
        AppointmentMedium.Phone,
        skipPhoneAppointments,
      );

    return [
      virtualAppointments,
      inPersonAppointments,
      phoneAppointments,
      virtualLoading || inPersonLoading || phoneLoading,
    ];
  };

  const getTabs = (
    virtualAppointments,
    inPersonAppointments,
    phoneAppointments,
  ) => {
    const tabs = [];

    if (phoneAppointments && props.phoneSupported) {
      tabs.push({
        label: "Phone",
        child: appointmentSlotsSection(
          phoneAppointments,
          AppointmentMedium.Phone,
        ),
      });
    }

    if (
      virtualAppointments &&
      props.supportsVirtual &&
      !props.isCNAvailabilityModal
    ) {
      tabs.push({
        label: "Virtual",
        child: appointmentSlotsSection(
          virtualAppointments,
          AppointmentMedium.Video,
        ),
      });
    }

    {
      /* We only show the in-person section when the following conditions are met:
      - the member is *not* a legacy minor; managed minors *can* request in-person appointments
      - the member is *not* viewing the global experience
      - the `supportsInPerson` boolean, which is passed from `Listing`, is `true`
      - the provider's office address is 35 miles or less away from the member's address
    */
    }
    const isMedicationManager =
      props.modalData.providerRole === "Medication Manager";
    if (
      inPersonAppointments &&
      !props.isALegacyMinor &&
      (!props.showGlobalExperience || enableInPersonGlobal) &&
      props.supportsInPerson &&
      (isMedicationManager ||
        props.provider.distance_miles <= inPersonDistance.limit35) &&
      !props.isCNAvailabilityModal
    ) {
      tabs.push({
        label: inPersonLabel,
        child: appointmentSlotsSection(
          inPersonAppointments,
          AppointmentMedium.InPerson,
        ),
      });
    }

    const appointmentMediumType = (label) => {
      if (label === virtualLabel) {
        return getAppointmentMediumForTracking(AppointmentMedium.Video);
      } else if (label === inPersonLabel) {
        return getAppointmentMediumForTracking(AppointmentMedium.InPerson);
      }
      return undefined;
    };

    return tabs.map((tab) => {
      const tracking = () => {
        if (props.shouldTrack) {
          const location = props.isProviderASpecializedCareNavigator
            ? "Specialized Care Navigator Availability Modal"
            : "Provider Profile";

          TRACK_EVENT.BUTTON_CLICKED(
            routes.TherapistsBrowse.as,
            "Appointment Medium Tab",
            {
              spring_doc_id: "directsched04",
              location: location,
              provider_id: props.provider.id,
              appointment_type: getAppointmentKindForTracking(
                AppointmentKind.Therapy,
              ),
              appointment_medium: appointmentMediumType(tab.label),
            },
          );
        }
      };
      let icon;
      if (tab.label === "Virtual") {
        icon = <VVideoChatIcon aria-hidden />;
      } else if (tab.label === "Phone") {
        icon = <Call color="black" w={18} h={18} />;
      } else {
        icon = <LocationPin />;
      }
      return (
        <Tab
          key={`tab_${tab.label}_${props.provider.id}`}
          label={tab.label}
          tracking={tracking}
          transparent
          width="100"
          icon={icon}
        >
          {tab.child}
        </Tab>
      );
    });
  };

  const shouldInferKind = useFeatureFlag(
    FLAGS.INFER_KINDS_CARE_PROVIDER_SCHEDULE,
  );
  const [
    virtualAppointments,
    inPersonAppointments,
    phoneAppointments,
    loading,
  ] = useAppointmentSlotsByMedium();
  const tabs = getTabs(
    virtualAppointments,
    inPersonAppointments,
    phoneAppointments,
  );
  const trackAppointmentSlotsViewed = props.trackAppointmentSlotsViewed;

  useEffect(() => {
    if (!loading && !phoneAppointments && props.isCNAvailabilityModal) {
      props.onNoPhoneAppointments();
    }
  }, [loading]);

  // Effect should only really run once since we'd be fetching appointments once\

  useEffect(() => {
    const getActiveTab = (virtualAppointments, inPersonAppointments) => {
      const virtualFirstSlot =
        virtualAppointments &&
        virtualAppointments.appointment_slots.available[0];
      const inPersonFirstSlot =
        inPersonAppointments &&
        inPersonAppointments.appointment_slots.available[0];

      if (tabCount === 1) return 0;
      if (props.inPersonFilterSelected) return 1;
      if (virtualFirstSlot < inPersonFirstSlot) return 0;
      if (virtualFirstSlot > inPersonFirstSlot) return 1;
      if (virtualFirstSlot === inPersonFirstSlot) return 0;
    };

    setActiveTab(getActiveTab(virtualAppointments, inPersonAppointments));
    // Only tracking virtual appointments, not in person.
    if (
      inViewport &&
      virtualAppointments?.appointment_slots?.available &&
      trackAppointmentSlotsViewed &&
      !trackedOnce
    ) {
      trackAppointmentSlotsViewed(
        virtualAppointments?.appointment_slots?.available[0],
      );
      setTrackedOnce(true);
    }
  }, [
    tabCount,
    trackAppointmentSlotsViewed,
    props.trackAppointmentSlotsViewed,
    props.inPersonFilterSelected,
    props.provider.distance_miles,
    virtualAppointments,
    inPersonAppointments,
    inViewport,
  ]);

  const RequestAvailabilityTab = () => {
    const tabs = [];
    const isMedicationManager =
      props.modalData.providerRole === "Medication Manager";
    const requestAvailabilityContent = (
      <RequestAvailability
        setHasTracked={setHasTracked}
        hasTracked={hasTracked}
        inPersonMedium={false}
        {...props}
      />
    );

    // We only want to add the virtual tab for medication managers for now
    if (!isMedicationManager) {
      return requestAvailabilityContent;
    }

    let label = "";
    if (props.supportsVirtual) {
      label = virtualLabel;
    } else if (
      inPersonAppointments &&
      !props.isALegacyMinor &&
      (!props.showGlobalExperience || enableInPersonGlobal) &&
      props.supportsInPerson &&
      (isMedicationManager ||
        props.provider.distance_miles <= inPersonDistance.limit35)
    ) {
      label = inPersonLabel;
    }

    if (label === "") {
      return requestAvailabilityContent;
    }

    tabs.push(
      <Tab
        key={`tab_${label}_${props.provider.id}`}
        label={label}
        transparent
        width="100"
        icon={<VVideoChatIcon />}
      >
        {requestAvailabilityContent}
      </Tab>,
    );

    return (
      <Tabs
        key={props.provider.id}
        activeTab={activeTab}
        justification="flex-start"
        panelId={`panelid-${++UID}-${props.provider.id}`}
        fixedTabWidth
        stopPropagation
      >
        {tabs}
      </Tabs>
    );
  };

  if (!props.showDirectScheduling) {
    return <RequestAvailabilityTab />;
  }

  return (
    <div ref={ref}>
      {!shouldShowMobileSlots ? (
        <InputWrapper
          valid={showValidation()}
          theme={props.theme}
          for="AppointmentSlots"
        >
          {loading && <LoadingCircle />}
          {!loading &&
            (tabs.length !== 0 ? (
              <Tabs
                key={props.provider.id}
                activeTab={activeTab}
                justification="flex-start"
                panelId={`panelid-${++UID}-${props.provider.id}`}
                fixedTabWidth
                stopPropagation
                showSingleTab={props.isCNAvailabilityModal}
                singleTabTitle={
                  phoneAppointments?.appointment_slots?.available?.length !== 0
                    ? props.singleTabTitle
                    : ""
                }
              >
                {tabs}
              </Tabs>
            ) : (
              <RequestAvailabilityTab />
            ))}
        </InputWrapper>
      ) : (
        <>
          <div>
            {phoneAppointments?.appointment_slots?.available?.length !== 0 &&
              props.singleTabTitle}
          </div>
          {smallScreenSlotOptions(
            virtualAppointments,
            inPersonAppointments,
            phoneAppointments,
            loading,
          )}
        </>
      )}
    </div>
  );
};

NewAppointmentSlots.propTypes = {
  clientValidation: PropTypes.shape({
    message: PropTypes.string,
    valid: PropTypes.bool,
  }),
  bookings: PropTypes.arrayOf(PropTypes.string),
  close: PropTypes.func,
  dayIncrement: PropTypes.number,
  end: PropTypes.string,
  increment: PropTypes.number,
  isALegacyMinor: PropTypes.bool,
  inPersonFilterSelected: PropTypes.bool,
  kind: PropTypes.string,
  limit: PropTypes.number, // limit results to showing <number> slots per day
  locale: PropTypes.string,
  medium: PropTypes.string,
  moreAction: PropTypes.func,
  provider: PropTypes.any,
  providerIndex: PropTypes.number,
  requestAvailability: PropTypes.func,
  setField: PropTypes.func,
  showDirectScheduling: PropTypes.bool,
  showGlobalExperience: PropTypes.any,
  showMore: PropTypes.bool, // if limit is reached, show more slot action
  start: PropTypes.string,
  stripe: PropTypes.bool,
  supportsInPerson: PropTypes.bool,
  supportsVirtual: PropTypes.any,
  t: PropTypes.any,
  theme: PropTypes.oneOf(["material", "simple"]),
  type: PropTypes.string,
  upcomingSlotsOnly: PropTypes.bool,
  valid: PropTypes.bool,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.number,
    PropTypes.array,
  ]),
  modalData: PropTypes.shape({
    id: PropTypes.string,
    coordinates: PropTypes.shape({
      latitude: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      longitude: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
  }),
  placeholder: PropTypes.string,
  smallSlotStyle: PropTypes.bool,
  shouldTrack: PropTypes.bool,
  isRecsOrBrecs: PropTypes.bool,
  isVertical: PropTypes.bool,
  isProviderASpecializedCareNavigator: PropTypes.bool,
};

export { NewAppointmentSlots };
export default compose(
  connect(
    (state, ownProps) => ({
      ...formFieldSelector(state, ownProps),
      locale: state.global.lang,
    }),
    { setField },
  ),
)(withTranslation("careProvider")(NewAppointmentSlots));
