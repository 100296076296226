import React, { useRef, useState } from "react";
import { Box, Heading, Text, Link } from "@springcare/sh-component-library";
import { useTranslation, Trans } from "react-i18next";
import { HighmarkInsuranceForm, InsuranceForm } from "./shared";
import { TRACK_EVENT } from "utils/mixpanel";
import { useInViewportOnce } from "hooks";
import { FLAGS, useFeatureFlag } from "utils/launchdarkly";
import routes from "routes";
import { InsuranceValidationForm } from "components/templates/CostEstimation/components/shared/InsuranceValidationForm";

export const RequestCostEstimate = ({
  memberInsuranceInfo,
  changeDisplay,
  displayState,
  isHighmark,
}) => {
  const isInsuranceValidationEnabled = useFeatureFlag(
    FLAGS.INSURANCE_VALIDATION,
  );
  const { t } = useTranslation("insurance");
  const ref = useRef();
  const inViewport = useInViewportOnce(ref, "0px");
  const [trackedOnce, setTrackedOnce] = useState(false);
  const isNewHighmarkFormFlagOn = useFeatureFlag(FLAGS.HIGHMARK_INSURANCE_FORM);
  const showNewForm = isNewHighmarkFormFlagOn && isHighmark;
  const isPayer =
    memberInsuranceInfo?.user?.member?.cohort?.customer?.is_health_plan;

  if (inViewport && !trackedOnce) {
    TRACK_EVENT.PAGE_VERSION_VIEWED(
      window.location.pathname,
      "Add insurance info",
      {},
    );
    setTrackedOnce(true);
  }

  return (
    <>
      {isInsuranceValidationEnabled ? (
        <InsuranceValidationForm />
      ) : (
        <Box
          width="100%"
          maxWidth="728px"
          minHeight={["100vh", "768px", "768px"]}
          paddingX={[0, 8, 8]}
          mx="auto"
          ref={ref}
        >
          <Heading size="heading-medium" as="h2" marginBottom={"2rem"}>
            {t("title")}
          </Heading>

          {showNewForm ? (
            <Trans
              ns="insurance"
              i18nKey="highmarkSubtitle"
              components={[
                <Link
                  key="0"
                  href={routes.MemberSettings.as}
                  style={{ fontWeight: "normal", textDecoration: "unset" }}
                />,
              ]}
            />
          ) : (
            <Text>{isPayer ? t("payerSubtitle") : t("subTitle")}</Text>
          )}

          <Heading size="heading-small" as="h3" fontSize={16} marginTop={20}>
            {showNewForm ? t("form.highmarkHeading") : t("form.heading")}
          </Heading>
          <Text marginBottom={16}>{!showNewForm && t("form.subHeading")}</Text>

          {showNewForm ? (
            <HighmarkInsuranceForm
              memberInsuranceInfo={memberInsuranceInfo}
              changeDisplay={changeDisplay}
              displayState={displayState}
            />
          ) : (
            <InsuranceForm
              memberInsuranceInfo={memberInsuranceInfo}
              changeDisplay={changeDisplay}
              displayState={displayState}
              renderInputProps={{
                border: "hidden",
                variant: "medium-emphasis",
              }}
            />
          )}
        </Box>
      )}
    </>
  );
};
