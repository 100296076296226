import { gql } from "@apollo/client";

import {
  experienceState,
  postalAddress,
  contractTerm,
} from "operations/fragments/member";

const getMemberInfo = gql`
  query getMemberInfo($id: ID!) {
    user(id: $id) {
      id
      first_name
      last_name
      created_at
      member {
        id
        email
        date_of_birth
        preferred_language
        country_code
        apero_record_id
        is_peer_recovery_specialist_supported
        # New endpoints - Use these instead of the above endpoints
        visits_covered_total # Includes Therapy (basic), Specialist (MM), and sponsored covered Sessions
        visits_covered_coaching # Only coaching covered
        visits_covered_specialist # Only Specialist covered
        visits_used_total # Includes Therapy, Specialist (MM), and sponsored used sessions
        visits_used_basic # Only Therapy (basic) used sessions
        visits_used_specialist # Only Specialist (MM) used sessions
        visits_used_coaching # Only Coaching used sessions
        visits_remaining_coaching # Only Coaching remaining sessions
        visits_remaining_total # Includes Therapy (basic), Specialist (MM), and sponsored remaining sessions
        visits_remaining_specialist # Only Specialist (MM) remaining sessions
        visits_eligibility_limit_date # The contract term expiration date
        # -----------------------------
        access_limit_date
        inside_inactivation_window
        terminate_reason
        within_aging_out
        recently_transitioned_account
        workplace_manager
        is_global_t2
        is_private_practice
        is_past_cancellation_threshold
        covered_life {
          country
          dependent
        }
        first_upcoming_appointments {
          therapy {
            id
            kind
            provider_id
            start_at
            end_at
          }
          coaching {
            id
            kind
            provider_id
            start_at
            end_at
          }
          medication_management {
            id
            kind
            provider_id
            start_at
            end_at
          }
          care_navigation {
            id
            kind
            provider_id
            start_at
            end_at
          }
        }

        visits_eligibility_limit_date

        postal_address {
          ...postalAddress
        }

        minor

        manager {
          id
          first_name
          last_name
          email
        }

        is_work_life_supported
        no_show_late_cancel_acknowledged_at

        managed_dependents {
          id
          first_name
          last_name
          email
          date_of_birth
          within_aging_out
          recently_transitioned_account
        }

        check_in_frequency_days
        full_address_available
        chart {
          medication_manager_recommended
          care_navigator_call_recommended
        }
        todos {
          id
          detail
          title
          kind
          data
        }
        experience_state {
          ...experienceState
        }
        payment_preference
        optimism
        previsit {
          id
          status
          can_schedule
        }
        cohort {
          id
          benefits_summary
          basic_visits_covered
          specialist_visits_covered
          all_basic_visits_covered
          all_specialist_visits_covered
          in_person_supported
          in_person_med_management_supported
          dependents_allowed
          child_dependents_allowed
          coaching_visits_covered
          cohort_eap_link
          cohort_eap_text
          eap_intro_text
          eap_access_code_text
          eap_freephone_number
          eap_direct_dial_number
          eap_sms_number
          eap_email
          has_dedicated_providers
          unlimited_coaching_direct_visits
          unlimited_coaching_cn_visits
          in_network
          international
          contract_term {
            ...contractTerm
          }
          customer {
            id
            name
            enable_member_no_show_policy
            trial
            is_health_plan
            care_navigation_contract_type
          }
        }

        aged_out_consent_fields {
          created_at
          terms_of_service_agreed_at
        }

        # SDOH fields (Target, Boeing, Delta, etc.)
        care_advocate_supported
        care_consultant_supported
        customer_site_id
        workplace_consultation_supported
        community_care_supported

        missed_late_cancel_policy_ack_required
      }
    }
  }
  ${experienceState}
  ${postalAddress}
  ${contractTerm}
`;

export default getMemberInfo;
