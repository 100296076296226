import { Box, Flex } from "@springcare/sh-component-library";
import { VideoTrack, VideoTrackProps } from "@livekit/components-react";
import { Track } from "livekit-client";
import CameraPlaceholder from "components/templates/SHSessionRoom/components/ParticipantTile/CameraPlaceholder";
import MicrophoneIndicator from "components/templates/SHSessionRoom/components/ParticipantTile/MicrophoneIndicator";
import ParticipantNameTag from "components/templates/SHSessionRoom/components/ParticipantTile/ParticipantNameTag";
import { useParticipantMetadata } from "context/SessionRoomContext";

const TypedVideoTrack = VideoTrack as React.ComponentType<VideoTrackProps>;

const ASPECT_RATIO_16_9 = 16 / 9; // Used for dynamic width calculation
const ASPECT_RATIO_9_16 = 9 / 16; // Used for dynamic height calculation
const SMALL_THRESHHOLD = "20vh"; // Height threshold for small styling

const ParticipantTile = ({
  key,
  isMobile,
  track,
  isMirrorVideo = false,
  maxHeight,
  maxWidth,
}) => {
  const { participant } = track;
  const { isCameraEnabled, isMicrophoneEnabled } = participant;
  const isScreenshare = track.source === Track.Source.ScreenShare;
  const { localParticipantName, remoteParticipantName } =
    useParticipantMetadata();
  const name =
    participant.isLocal && !isScreenshare
      ? localParticipantName
      : !isScreenshare
        ? remoteParticipantName
        : undefined;
  // Determine if the tile should use small styling based on maxHeight
  const isHeightLessThan20Vh = maxHeight < SMALL_THRESHHOLD;
  const initials = name
    ?.split(" ")
    .map((n) => n[0])
    .join("")
    .toUpperCase();

  return (
    <Flex
      key={key}
      alignItems="flex-end"
      position="relative"
      overflow="hidden"
      borderRadius="v-lg"
      height={`calc(${maxWidth} * ${ASPECT_RATIO_9_16})`}
      width={maxWidth}
      maxWidth={
        isMobile ? maxWidth : `calc(${maxHeight} * ${ASPECT_RATIO_16_9})`
      }
      maxHeight={maxHeight}
    >
      <Box w="full" h="full" background="content-secondary">
        {isCameraEnabled || isScreenshare ? (
          <TypedVideoTrack
            title={`${name ? name + "'s" : ""} video stream`}
            aria-live="polite"
            trackRef={track}
            style={{
              height: "100%",
              width: "100%",
              transform: isMirrorVideo ? "scaleX(-1)" : "",
            }}
            tabIndex={0}
          />
        ) : (
          <CameraPlaceholder initials={initials} />
        )}
      </Box>
      <Flex
        pos="absolute"
        justifyContent={name ? "space-between" : "end"}
        alignItems="end"
        width="full"
        p={isHeightLessThan20Vh ? "v-8" : "v-12"}
      >
        {name && (
          <ParticipantNameTag isSmall={isHeightLessThan20Vh} text={name} />
        )}
        {!isScreenshare && (
          <MicrophoneIndicator
            isSmall={isHeightLessThan20Vh}
            isMicrophoneEnabled={isMicrophoneEnabled}
          />
        )}
      </Flex>
    </Flex>
  );
};

export default ParticipantTile;
