//

import React from "react";
import {
  Button,
  FlexRow,
  Content,
  Headline,
  Subtitle,
  Section,
  HorizontalRule,
} from "@spring/smeargle";
import { useTranslation } from "react-i18next";

import { HelpCards } from "components/molecules";
import { SpringPhone } from "components/atoms";

import styles from "./styles.module.scss";
import useChatBotHandler from "utils/chatbot/useChatBotHandler";

const Contact = () => {
  const { t } = useTranslation("limitedLangHelp");
  const { handleOpenChat } = useChatBotHandler();

  if (typeof window !== "undefined") {
    return (
      <FlexRow justification="center" alignment="baseline">
        <Button
          flat
          secondary
          icon="arrow-right"
          text={t("helpCenter.contactButtonText")}
          onClick={handleOpenChat}
        />

        <div className={styles.separator}>
          <Content>{t("helpCenter.or")}</Content>
        </div>

        <SpringPhone />
      </FlexRow>
    );
  }

  return <SpringPhone />;
};
const HelpPage = () => {
  const { t } = useTranslation("help");
  return (
    <>
      <Headline noMargin>{t("helpCenter.headline")}</Headline>
      <Subtitle noMargin>{t("helpCenter.subtitle")}</Subtitle>
      <Section size="xlg">
        <HorizontalRule />
      </Section>

      <Section>
        <Contact />
      </Section>

      <Section>
        <Subtitle bold>{t("helpCenter.faq")}</Subtitle>
      </Section>
      <Section>
        <HelpCards />
      </Section>
    </>
  );
};

export default HelpPage;
