import {
  Flex,
  IconButton,
  VMicOffIcon,
  VMicOnIcon,
  VVideoChatIcon,
  VVideoOffIcon,
} from "@springcare/sh-component-library";
import { MediaType } from "components/templates/SHSessionRoom/types";
import { useTranslation } from "hooks/react-i18next";

interface PreJoinVideoCallControlsProps {
  isMobile: boolean;
  isCameraEnabled?: boolean;
  isMicrophoneEnabled?: boolean;
  toggleVideo: () => void;
  toggleAudio: () => void;
}
// TODO: start taking props out which are in context
const PreJoinVideoCallControls = ({
  isCameraEnabled,
  isMicrophoneEnabled,
  toggleVideo,
  toggleAudio,
}: PreJoinVideoCallControlsProps) => {
  const toggleMediaEnabled = (mediaType: MediaType) => {
    if (mediaType === MediaType.Microphone) {
      toggleAudio();
    } else if (mediaType === MediaType.Camera) {
      toggleVideo();
    }
  };

  const { t } = useTranslation("livekitExperience", {
    keyPrefix: "preJoinVideoCallControls",
  });

  return (
    <Flex
      mt={0}
      p="v-8"
      gap="v-8"
      justify="center"
      position="relative"
      bg="black"
      borderBottomRadius={16}
    >
      <IconButton
        variant="medium-emphasis"
        onClick={() => toggleMediaEnabled(MediaType.Microphone)}
        icon={isMicrophoneEnabled ? VMicOnIcon : VMicOffIcon}
        aria-label={`${t("toggle")} ${MediaType.Microphone}`}
        bg={"white"}
        color={"black"}
      />
      <IconButton
        variant="medium-emphasis"
        onClick={() => toggleMediaEnabled(MediaType.Camera)}
        icon={isCameraEnabled ? VVideoChatIcon : VVideoOffIcon}
        aria-label={`${t("toggle")} ${MediaType.Camera}`}
        bg={"white"}
        color={"black"}
      />
    </Flex>
  );
};

export default PreJoinVideoCallControls;
