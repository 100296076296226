export enum SessionViewStatus {
  BrowserPermissionsDenied = "browser_permissions_denied",
  NotStarted = "not_started",
  Connecting = "connecting",
  MemberIsWaiting = "member_is_waiting",
  ProviderNoShow = "provider_no_show",
  InProgress = "in_progress",
  Ended = "ended",
}

// used for defining the type of media
export enum MediaType {
  Microphone = "microphone",
  Camera = "camera",
}

export enum ParticipantType {
  Member = "member",
  Provider = "provider",
  Unknown = "unknown",
}

// used for defining the type of LiveKit's VideoTrack
export interface TrackType {
  participant: {
    isLocal: boolean;
    sid: string;
  };
}

export enum DeviceTypes {
  AudioOutput = "audiooutput",
  AudioInput = "audioinput",
  VideoInput = "videoinput",
}

export enum ToggleButtonTypes {
  Camera = "camera",
  Chat = "chat",
  Microphone = "microphone",
  Participants = "participants",
  Screensharing = "screensharing",
}

export enum DrawerTabTypes {
  Participants = "participants",
  Chat = "chat",
}

// Safari doesn't support audio output devices, this is copy for a fake device
export enum SafariAudioOutputDevice {
  SystemDefaultSpeaker = "System Default Speaker Device",
  DeviceId = "fake",
}
