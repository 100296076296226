import React, { useState } from "react";
import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  IconButton,
  VLogOutIcon,
  VArrowRightIcon,
  VXIcon,
  Tooltip,
  Spinner,
} from "@springcare/sh-component-library";
import { useLocalParticipant } from "@livekit/components-react";
import { useLazyQuery } from "@apollo/client";
import { datadogAddAction } from "lib/datadog-setup";
import { sessionEndForAll } from "operations/queries/appointment";
import {
  useSessionConnectStatus,
  useParticipantMetadata,
} from "context/SessionRoomContext";
import { ParticipantType } from "components/templates/SHSessionRoom/types";
import {
  trackExitMenuOpened,
  trackExitSessionClick,
} from "components/templates/SHSessionRoom/telemetry/analytics";
import { useTranslation } from "hooks/react-i18next";

export const ExitButton = () => {
  const { handleDisconnect } = useSessionConnectStatus();
  const { participantType, appointmentId } = useParticipantMetadata();
  const { localParticipant } = useLocalParticipant();
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation("livekitExperience", {
    keyPrefix: "videoCallControls.exitButton",
  });
  const leaveLabel = t("leave");
  const leaveSessionLabel = t("leaveSession");
  const endSessionForAllLabel = t("endSessionForAll");
  const handleOpenExitMenu = () => {
    setIsOpen(!isOpen);
    trackExitMenuOpened(!isOpen);
  };

  const handleExitSession = (type: string) => {
    trackExitSessionClick(type);
    handleDisconnect();
  };

  const [loadSessionEndForAll, { error }] = useLazyQuery(sessionEndForAll);
  if (error) {
    setIsLoading(false);
    datadogAddAction("session_end_for_all_error", {
      error,
      appointmentId,
      virtualSessionType: "livekit",
    });
  }

  const endSessionForAll = async (participantId) => {
    setIsLoading(true);
    try {
      await loadSessionEndForAll({
        variables: {
          appointmentId,
          participantId,
        },
      });
    } catch (error) {
      datadogAddAction("session_end_for_all_error", {
        error,
        appointmentId,
        participantId,
        virtualSessionType: "livekit",
      });
    } finally {
      setIsLoading(false);
    }
    trackExitSessionClick("end_session");
  };

  return (
    <Menu>
      <Tooltip label={leaveLabel} aria-label={leaveLabel}>
        <MenuButton
          as={IconButton}
          icon={VLogOutIcon}
          aria-haspopup="true"
          aria-expanded={isOpen}
          aria-label={
            participantType === ParticipantType.Provider
              ? t("optionsToLeaveOrEnd")
              : t("optionsToLeaveOrStay")
          }
          onClick={handleOpenExitMenu}
          variant="high-emphasis"
          colorScheme="negative"
          _focus={{}} // handle styling issues that probably need to be fixed in the component-library
        >
          {t("exit")}
        </MenuButton>
      </Tooltip>
      <MenuList zIndex={10}>
        <MenuItem
          icon={<VArrowRightIcon />}
          onClick={() => handleExitSession("leave")}
          aria-label={leaveSessionLabel}
          _focus={{ outline: "0px solid transparent !important" }}
          _focusVisible={{ outline: "2px solid black !important" }}
          _hover={{ bg: "hover-base-low-emphasis" }}
        >
          {leaveSessionLabel}
        </MenuItem>
        {participantType === ParticipantType.Provider && (
          <MenuItem
            icon={isLoading ? <Spinner speed="1s" size="xs" /> : <VXIcon />}
            onClick={() => endSessionForAll(localParticipant?.identity)}
            aria-label={endSessionForAllLabel}
            _focus={{ outline: "0px solid transparent !important" }}
            _focusVisible={{ outline: "2px solid black !important" }}
            _hover={{ bg: "hover-base-low-emphasis" }}
          >
            {endSessionForAllLabel}
          </MenuItem>
        )}
      </MenuList>
    </Menu>
  );
};

export default ExitButton;

ExitButton.displayName = "ExitButton";
