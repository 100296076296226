import React from "react";
import { Trans, useTranslation } from "react-i18next";
import { Box, Text } from "design-system/components";
import { Link } from "@springcare/sh-component-library";

export const MakeChangeCopy = ({
  time,
  directQuestionsToProvider,
  contactEmail,
}: {
  /** for describing the appointment to the provider. */
  time: string;
  /** Direct the member to email the provider directly
   * in the case of coaches, we still direct them to the care team email
   * but the copy is different
   */
  directQuestionsToProvider: boolean;
  /**
   * Email member should contact.
   * Coaches should be directed to care team email.
   */
  contactEmail: string;
}) => {
  const { t } = useTranslation("careVisits");
  const subject = t("appointmentDetails.makeChangeCopy.subject");
  const mailToURL =
    `mailto:${contactEmail}?subject=` + encodeURIComponent(`${subject}${time}`);

  return (
    <Box as="section" my={16}>
      <Text variant="body_v1">
        <Trans
          ns={"careVisits"}
          i18nKey={
            directQuestionsToProvider
              ? "appointmentDetails.makeChangeCopy.coachCopyContent"
              : "appointmentDetails.makeChangeCopy.content"
          }
          values={{
            kind: "your Care Navigator at",
            careTeamEmail: contactEmail,
          }}
        >
          <Text as="span">
            <Link href={mailToURL} />
          </Text>
        </Trans>
      </Text>
    </Box>
  );
};
