import React from "react";
import { useTranslation } from "react-i18next";
import {
  Box,
  Flex,
  useDisclosure,
  useMediaQuery,
  Menu,
  MenuItem,
  MenuList,
} from "@springcare/sh-component-library";
import { ProfileAvatar } from "design-system/components/custom/navigation-legacy";
import { NotificationSvgIcon } from "components/atoms";
import { TakeCheckinButton, HelpButton } from "components/molecules";
import { useCanChangeUser } from "lib/rememberUser";
import { HelpFlyout } from "components/flyouts/HelpFlyout";
import { useFeatureFlag, FLAGS } from "utils/launchdarkly";
import routes from "routes";
import { MessagesButton } from "./MessagesButton";

interface RightLinksProps {
  isGlobalUser: boolean;
  hasChildDependents: boolean;
  isAMinor: boolean;
  isATeen: boolean;
  isAT2Member: boolean;
  /** Indicates a bamboo member with a Spring Lite experience */
  isPrivatePractice: boolean;
  firstName: string;
  lastName: string;
  showBillingNotificationBubble: boolean;
  showTeenCheckInButton?: boolean;
}

const RightLinks = ({
  isGlobalUser,
  hasChildDependents,
  isAMinor,
  isATeen,
  isAT2Member,
  isPrivatePractice,
  firstName,
  lastName,
  showBillingNotificationBubble,
  showTeenCheckInButton = false,
}: RightLinksProps) => {
  const { t } = useTranslation(["limitedLangMemberHelpCard", "common"]);

  const memberPaymentCollectionLDFlag = useFeatureFlag(
    FLAGS.MEMBER_PAYMENT_COLLECTION_M1,
  );

  const providerMemberMessagingLDFlag = useFeatureFlag(
    FLAGS.PROVIDER_MEMBER_MESSAGING,
  );

  // Control the help flyout state
  const { isOpen, onOpen, onClose } = useDisclosure();
  const canChangeUser = useCanChangeUser();
  const [isMobile] = useMediaQuery("(max-width: 767px)");

  const links = [
    !isGlobalUser &&
      !isAT2Member && {
        alias: "MemberLearningCenter",
        linkPath: "/members/learning-center",
        linkName: "Learning Center",
        translatedName: t("common:navigation.links.learningCenter"),
        showNotificationBubble: false,
      },
    !isPrivatePractice && {
      alias: "MemberBenefits",
      linkPath: "/members/benefits",
      linkName: "Your Benefits",
      translatedName: t("common:navigation.links.yourBenefits"),
      showNotificationBubble: false,
    },
    {
      alias: "MemberSettings",
      linkPath: "/members/settings",
      linkName: "Settings",
      translatedName: t("common:navigation.links.settings"),
      showNotificationBubble: false,
    },
    !isGlobalUser &&
      !isAT2Member &&
      memberPaymentCollectionLDFlag && {
        alias: "MemberBilling",
        linkPath: routes.Billing.as,
        linkName: "Billing",
        translatedName: t("common:navigation.links.billing"),
        showNotificationBubble: showBillingNotificationBubble,
      },
    !isPrivatePractice &&
      canChangeUser &&
      !isAT2Member && {
        alias: hasChildDependents ? "ChooseUser" : "InviteDependents",
        linkPath: isAMinor
          ? `/members/choose_user/password`
          : "/members/choose_user",
        linkName: "Change User",
        translatedName: t("common:navigation.links.manageDependents"),
      },
    {
      alias: "Logout",
      linkPath: "/logout",
      linkName: "Logout",
      translatedName: t("common:navigation.links.logout"),
      showNotificationBubble: false,
    },
  ].filter((_) => _); // remove null links

  return (
    <Flex
      display="flex"
      justifyContent="flex-end"
      alignItems="center"
      gap="8px"
      flex="1 0 0"
      alignSelf="stretch"
    >
      {((showTeenCheckInButton && !isMobile) || (!isAMinor && !isMobile)) &&
        !isAT2Member && <TakeCheckinButton isATeen={isATeen} />}
      {!isGlobalUser && providerMemberMessagingLDFlag && <MessagesButton />}
      <HelpButton setOnOpen={onOpen} isAT2Member={isAT2Member} />

      <Menu>
        <ProfileAvatar
          firstName={firstName}
          lastName={lastName}
          isMobile={isMobile}
          isAT2Member={isAT2Member}
          isAMinor={isAMinor}
          links={links}
        />
        <MenuList>
          {links.map((data) => (
            <MenuItem
              key={data.linkName}
              data-cy={`member-right-dropdown-${data.alias}-link`}
              as="a"
              href={data.linkPath}
              _focus={{ bg: "none" }}
              _hover={{ bg: "tertiary.50" }}
              _focusVisible={{
                outline: "2px solid black",
                outlineOffset: "-1px",
              }}
              fontWeight="bold"
            >
              <Flex justify="space-between" align="center" width="full">
                <Box>{data.translatedName}</Box>
                {data.showNotificationBubble && <NotificationSvgIcon />}
              </Flex>
            </MenuItem>
          ))}
        </MenuList>
      </Menu>

      <HelpFlyout isOpen={isOpen} onOpen={onOpen} onClose={onClose} />
    </Flex>
  );
};

RightLinks.defaultProps = {
  isAMinor: false,
  firstName: "there",
  lastName: "",
};

export default RightLinks;
