import { Start, Center, End } from "./VideoCallControls/Group";
import { ToggleButton } from "./VideoCallControls/ToggleButton";
import { DeviceMenuChevron } from "./VideoCallControls/DeviceMenuChevron";
import { ExitButton } from "./VideoCallControls/ExitButton";
import { AdmitParticipantPopover } from "./VideoCallControls/AdmitParticipantPopover";
import {
  ToggleButtonTypes,
  DeviceTypes,
  ParticipantType,
} from "components/templates/SHSessionRoom/types";
import { Flex } from "@springcare/sh-component-library";
import {
  useParticipantMetadata,
  useSessionRoomDrawer,
} from "context/SessionRoomContext";

interface VideoCallControlsProps {
  isMobile: boolean;
}

const DesktopControls = () => (
  <>
    <Start>
      <ToggleButton type={ToggleButtonTypes.Microphone} />
      <DeviceMenuChevron
        types={[DeviceTypes.AudioInput, DeviceTypes.AudioOutput]}
      />
      <ToggleButton type={ToggleButtonTypes.Camera} />
      <DeviceMenuChevron types={[DeviceTypes.VideoInput]} />
    </Start>
    <Center>
      <ToggleButton type={ToggleButtonTypes.Participants} />
      <ToggleButton type={ToggleButtonTypes.Chat} />
      <ToggleButton type={ToggleButtonTypes.Screensharing} />
    </Center>
    <End>
      <ExitButton />
    </End>
  </>
);

const MobileControls = () => (
  <Center>
    <ExitButton />
    <ToggleButton type={ToggleButtonTypes.Microphone} />
    <ToggleButton type={ToggleButtonTypes.Camera} />
    <ToggleButton type={ToggleButtonTypes.Participants} />
    <ToggleButton type={ToggleButtonTypes.Chat} />
  </Center>
);

export const VideoCallControls = ({ isMobile }: VideoCallControlsProps) => {
  const { participantType } = useParticipantMetadata();
  const { isSessionRoomDrawerOpen, drawerWidth } = useSessionRoomDrawer();
  const DEFAULT_MAX_WIDTH = `calc(100vw - ${isSessionRoomDrawerOpen ? 30 : 48}px)`;
  const DEFAULT_MAX_HEIGHT = "90vh";
  const DEFAULT_MIN_WIDTH = `calc(100vw - 48px)`;

  const aspectRatio = 16 / 9;
  const width = `calc(${DEFAULT_MAX_HEIGHT} * ${aspectRatio})`;
  const maxWidth = `calc(${DEFAULT_MAX_WIDTH} - ${isSessionRoomDrawerOpen ? drawerWidth : 0}px)`;

  return (
    <Flex
      justifyContent="center"
      width={width}
      maxW={maxWidth}
      minW={{ base: DEFAULT_MIN_WIDTH, md: "auto" }}
      direction="column"
      ps={isSessionRoomDrawerOpen ? "v-16" : "v-none"}
    >
      {participantType === ParticipantType.Provider && (
        <AdmitParticipantPopover />
      )}
      <Flex
        position="relative"
        justifyContent={isMobile ? "center" : "space-between"}
        width="100%"
      >
        {isMobile ? <MobileControls /> : <DesktopControls />}
      </Flex>
    </Flex>
  );
};

export default VideoCallControls;
