import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useRouter } from "next/router";
import App from "next/app";
import Head from "next/head";
import Script from "next/script";
import { Provider, connect } from "react-redux";
import { ApolloProvider } from "@apollo/client";
import { Notifications } from "@spring/smeargle";
import { get } from "lodash/fp";
import { withLDProvider, useLDClient } from "launchdarkly-react-client-sdk";
import { I18nextProvider, useTranslation } from "react-i18next";
import { ChakraProvider } from "@springcare/sh-component-library";
import { updateIterableCampaignInfo } from "utils/localStorage";

import { setUserIdentifiers, verifyToken } from "actions/auth/actions";
import i18n from "i18n";
import { integrationsIdentify } from "utils/integrations/integrations";
import { formatPageTitle } from "utils/displayHelpers";

import LocaleWrapper from "components/layout/global/LocaleWrapper";
import { LoadingPage } from "components";
import withReduxStore from "utils/redux/wrapper";
import { initApollo } from "utils/apollo/init";
import isSSRRoute from "utils/ssr/ssr";
import {
  SYSTEM_TRACKED_EVENTS,
  TRACK_EVENT,
  track,
  time_event,
  register,
  addCustomFlag,
} from "utils/mixpanel";
import { launchdarklyIdentify, useFeatureFlag } from "utils/launchdarkly";
import { FLAGS } from "utils/launchdarkly/flags";
import "lib/datadog-setup";
import "styles/root.scss";
import useMaintenanceMode from "hooks/useMaintenanceMode";
import { RegisterContext } from "context/RegisterContext";
import pichu from "constants/pichu";
import routes from "routes";
import { useIdleTimer } from "react-idle-timer";

import { mxTheme, rtlTheme } from "design-system";
import ErrorBoundary from "components/ErrorBoundary";
import { isT2MemberPreSignup } from "utils/memberHelpers";
import { Show, useMediaQuery } from "design-system/components";
import { useInitializeMessaging } from "@springcare/sh-messaging-library";
import { useApiClient } from "hooks/useApiClient";

const apolloClient = initApollo();

const Initialize = (props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isMobile] = useMediaQuery(
    "(max-width: 767px) and (orientation: portrait)",
  );

  useMaintenanceMode();

  const router = useRouter();
  const shouldSSR = isSSRRoute(router.route, props?.userAgent);

  let recentRouteChangeStack = {};
  const removeRecentRoutesChangesBefore = (t) => {
    for (var key in recentRouteChangeStack) {
      if (key < t) {
        delete recentRouteChangeStack[key];
      }
    }
  };

  const handleRouteChange = () => {
    SYSTEM_TRACKED_EVENTS.PAGE_VIEWED(window.location.pathname);
    const timeNow = Date.now();

    recentRouteChangeStack[timeNow] = window.location.pathname;

    removeRecentRoutesChangesBefore(timeNow - 5000);

    if (Object.keys(recentRouteChangeStack).length >= 15) {
      TRACK_EVENT.ERROR("Too Many Page Redirects", {
        routeStack: recentRouteChangeStack,
      });
      router.replace("/error-too-many-redirects");
      SYSTEM_TRACKED_EVENTS.PAGE_VIEWED("/error-too-many-redirects");
    }
  };

  useEffect(() => {
    const handleStorageChange = () => {
      // NOTE: When Zoom use is fully replaced, remove this
      let activeZoomSession = false;
      const storedTimestamp = localStorage.getItem("active_zoom_session");

      // Checks that the ~1 hour zoom session is still active and not stale
      // Otherwise, we keep the timer running
      if (storedTimestamp) {
        const sessionExpiration = new Date(parseInt(storedTimestamp, 10));

        sessionExpiration.setTime(sessionExpiration.getTime() + 60 * 60 * 1000);

        activeZoomSession = sessionExpiration.getTime() > Date.now();
      }

      if (activeZoomSession) {
        timer.pause();
      } else {
        timer.start();
      }
    };

    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  const onIdle = () => {
    // NOTE: When Zoom use is fully replaced, remove this
    let activeZoomSession = false;
    const storedTimestamp = localStorage.getItem("active_zoom_session");

    // Checks that the ~1 hour zoom session is still active and not stale
    // Otherwise, we keep the timer running
    if (storedTimestamp) {
      const sessionExpiration = new Date(parseInt(storedTimestamp, 10));

      sessionExpiration.setTime(sessionExpiration.getTime() + 60 * 60 * 1000);

      activeZoomSession = sessionExpiration.getTime() > Date.now();
    }

    if (!activeZoomSession) {
      localStorage.setItem("session_timeout", true);
      router.push(routes.Logout.as);
    }
  };

  const DEFAULT_TIMEOUT_MS = 1000 * 60 * 30;
  const sessionTimeoutMs =
    parseInt(process.env.SESSION_TIMEOUT_MINUTE_VALUE) * 60 * 1000;

  // crossTab && syncTimers both required to sync timer across tabs
  const timer = useIdleTimer({
    onIdle,
    timeout: !isNaN(sessionTimeoutMs) ? sessionTimeoutMs : DEFAULT_TIMEOUT_MS,
    throttle: 500,
    crossTab: true,
    syncTimers: 200,
  });

  useEffect(() => {
    if (props.loggedIn) {
      return timer.start();
    }

    if (!props.loggedIn) {
      return timer.pause();
    }
  }, [props.loggedIn]);

  useEffect(() => {
    document.title = `${formatPageTitle(router?.route)}`;
    // if current route is for logged in members only
    try {
      if (
        !router.pathname.includes("Public") &&
        !router.pathname.includes("self_help")
      ) {
        pichu.validateAccessToken(localStorage.getItem("access_token"));
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.info(error);
      router.push(routes.Logout.as);
    }
  }, [router.pathname]);

  useEffect(() => {
    //Track landing page
    SYSTEM_TRACKED_EVENTS.PAGE_VIEWED(window.location.pathname);

    // When the component is mounted, subscribe to router changes
    // and log those page views
    router.events.on("routeChangeComplete", handleRouteChange);

    // If the component is unmounted, unsubscribe
    // from the event with the `off` method
    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
    };
  }, []);

  useEffect(() => {
    if (!router.pathname.includes("feedback")) {
      verifyToken()
        .then((userIdentifiers) => {
          props.setUserIdentifiers(userIdentifiers);
        })
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.error(err);
          throw err;
        })
        .finally(() => {
          setIsLoading(false);
        });
      return;
    }
    setIsLoading(false);
  }, []);

  const userIdentifiers = props.userIdentifiers;
  const ldClient = useLDClient();

  const setupCustomFlag = (flagName, flags) => {
    const flagValue = localStorage.getItem(flagName);
    if (flagValue) {
      addCustomFlag(flagName, flags, flagValue === "true");
    }
  };

  const cacheLDFlagsForTracking = () => {
    const allFlagsResult = ldClient.allFlags();
    let feature_flags = [];

    for (const key of Object.keys(allFlagsResult)) {
      const flagDesc = Object.values(FLAGS).filter(
        (flagDesc) => flagDesc.name === key,
      )[0];

      //turns on flag evaluation reason for ld data export
      if (flagDesc?.track) {
        ldClient.variationDetail(key, flagDesc?.default || false);
      }

      flagDesc?.track
        ? feature_flags.push(`${flagDesc.trackingName}: ${allFlagsResult[key]}`)
        : null;
    }

    if (new Blob([feature_flags.toString()]).size > 1110) {
      //If we get this error, we need to turn off more feauture flags for tracking,
      // or break out the feature flags into event params and not store on cookie
      // check on snowflake data export limitations first, downside is a wide sparse dataset as flags are added and removed)
      throw new Error(
        "Feature flags are too large to be stored on mixpanel cookie.",
      );
    }

    setupCustomFlag(
      FLAGS.LOB_COACHING_ASSESSMENT_RESULTS_CTA.trackingName,
      feature_flags,
    );
    setupCustomFlag(
      FLAGS.LOB_SUD_ASSESSMENT_RESULTS_CTA.trackingName,
      feature_flags,
    );
    setupCustomFlag(
      FLAGS.SKIP_WARM_MOBILE_WELCOME_SCREEN.trackingName,
      feature_flags,
    );
    setupCustomFlag(
      FLAGS.SKIP_COLD_MOBILE_WELCOME_SCREEN.trackingName,
      feature_flags,
    );

    setupCustomFlag(FLAGS.COLD_SIGNUP_FORM.trackingName, feature_flags);

    {
      /* setupCustomFlag(FLAGS.COLD_SIGNUP_MOBILE_FORM.trackingName, feature_flags); */
    }

    register({ feature_flags });
  };

  const providerMemberMessagingLDFlag = useFeatureFlag(
    FLAGS.PROVIDER_MEMBER_MESSAGING,
  );
  const enableMessagingWebsockets = useFeatureFlag(
    FLAGS.MESSAGING_WEBSOCKETS_ENABLED,
  );
  const messagingPollingThrottled = useFeatureFlag(
    FLAGS.MESSAGING_THROTTLED_POLLING,
  );
  const [messagingApiClient, isLoggedIn, API_WEBSOCKET] = useApiClient();

  useInitializeMessaging({
    enabled: providerMemberMessagingLDFlag && isLoggedIn,
    userId: userIdentifiers?.user?.id || "",
    apiClient: messagingApiClient,
    websocket: {
      url: API_WEBSOCKET,
      active: enableMessagingWebsockets,
    },
    polling: {
      active: !enableMessagingWebsockets,
      throttled: messagingPollingThrottled,
    },
  });

  useEffect(() => {
    if (!ldClient) return;
    cacheLDFlagsForTracking();
  }, [ldClient?.allFlags()]);

  useEffect(() => {
    register({ customer_name: "unknown" });
    //handle custom iterable campaign tracking
    updateIterableCampaignInfo(router);
  }, []);

  useEffect(() => {
    if (!userIdentifiers?.user?.id) {
      register({ is_t2_signup: isT2MemberPreSignup() });
    }
  }, [userIdentifiers]);

  useEffect(() => {
    register({ platform: isMobile ? "Mobile Web" : "Desktop Web" });
  }, [isMobile]);

  useEffect(() => {
    // this is causing an 'undefined' mp event somehow
    if (
      userIdentifiers?.user?.id &&
      userIdentifiers?.user?.member?.postal_address?.country
    ) {
      integrationsIdentify(props.userIdentifiers, {
        ldClient,
      });
    } else if (ldClient) {
      const anonUser = { user: { id: localStorage.getItem("ld:$anonUserId") } };
      const isAnonUser = true;
      launchdarklyIdentify(anonUser, ldClient, isAnonUser);
    }
  }, [userIdentifiers, ldClient]);

  if (shouldSSR) {
    return <>{props.children}</>;
  } else if (isLoading) {
    return <LoadingPage componentFrom="app" />;
  }

  return (
    <>
      <Script id="branch-io-banner" strategy="lazyOnload">
        {`
        (function(b,r,a,n,c,h,_,s,d,k){if(!b[n]||!b[n]._q){for(;s<_.length;)c(h,_[s++]);d=r.createElement(a);d.async=1;d.src="https://cdn.branch.io/branch-latest.min.js";k=r.getElementsByTagName(a)[0];k.parentNode.insertBefore(d,k);b[n]=h}})(window,document,"script","branch",function(b,r){b[r]=function(){b._q.push([r,arguments])}},{_q:[],_v:1},"addListener applyCode autoAppIndex banner closeBanner closeJourney creditHistory credits data deepview deepviewCta first getCode init link logout redeem referrals removeListener sendSMS setBranchViewData setIdentity track validateCode trackCommerceEvent logEvent disableTracking qrCode".split(" "), 0);

        const appleAppSiteAssociationPaths = [
            "members/billing-and-payments",
            "members/care_visits",
            "members/home",
            "members/moments",
            "members/assessments",
            "members/recommendations",
            "members/teen/assessment",
            "no_show_reschedule",
            "register",
            "reset_password",
            "sign_in",
            "sso_sign_in",
            "verification",
            "create_account",
            "feedback",
        ];

        const isMobileDevice = /Mobi/i.test(navigator.userAgent);
        const couldBeIos = /iPhone|iPad|iPod/i.test(navigator.userAgent);
        const couldBeAndroid = /Android/i.test(navigator.userAgent);
        const couldBeWebview = /WebView|wv/i.test(navigator.userAgent);
        const couldBeAndroidWebview = couldBeAndroid && couldBeWebview;
        const nonSafariMobileOsRegex = /((CriOS)|(FxiOS)|(EdgiOS)|(OPR))/i;
        const couldBeSafari = /Safari/i.test(navigator.userAgent) && !(nonSafariMobileOsRegex.test(navigator.userAgent));
        const couldBeIosSafari = couldBeIos && couldBeSafari;

        let options = { no_journeys: false };
        if (isMobileDevice && (couldBeIosSafari || couldBeAndroidWebview)) {
            for (const path of appleAppSiteAssociationPaths) {
                const isPathInUrl = new RegExp(path, 'i').test(window.location.href);
                if (isPathInUrl) {
                    options['no_journeys'] = isPathInUrl;
                    break;
                }
            }
        }

        branch.init('key_live_iFkcclpqr8zKUyAAhu325kccuxm3AXPl', options);

        if (navigator.languages && navigator.languages.length>0) { lang_code = navigator.languages[0]; } else if (navigator.language) { lang_code = navigator.language; } // Change Journeys text if language is Spanish (ES) if (lang_code == 'es') { branch.setBranchViewData( { data: { "$journeys_title": 'Mi App', "$journeys_description": 'Mi descripción', "$journeys_button_get_has_app": 'Abrir', "$journeys_button_get_no_app": 'Ver', } } ); }
        `}
      </Script>
      <Show below={"750px"}>
        <div className="branch-journeys-top"></div>
      </Show>
      <LoadingPage componentFrom="app-initialized" out hidden={!isLoading} />
      {props.children}
    </>
  );
};

Initialize.propTypes = {
  children: PropTypes.any,
  setUserIdentifiers: PropTypes.func,
  userIdentifiers: PropTypes.any,
  uaString: PropTypes.any,
};

const SetInitialize = connect(
  (state) => ({
    userIdentifiers: get("auth.userIdentifiers", state),
    loggedIn: get("auth.isLoggedIn", state),
  }),
  { setUserIdentifiers },
)(Initialize);

const DirectionalChakraProvider = ({ ChakraProvider, children }) => {
  const { i18n } = useTranslation();
  const direction = i18n.dir(i18n.language);
  const finalTheme = direction === "rtl" ? rtlTheme : mxTheme;
  return <ChakraProvider theme={finalTheme}>{children}</ChakraProvider>;
};

class Spring extends App {
  static propTypes = {
    Component: PropTypes.any,
    apolloClient: PropTypes.any,
    pageProps: PropTypes.any,
    reduxStore: PropTypes.any,
    router: PropTypes.shape({
      pathname: PropTypes.any,
      query: PropTypes.any,
    }),
  };

  // This is triggering a 'dropoff' and new 'session' for any page reload with this as is,
  // Mixpanel already tracks this for us based on project settings which trigger virtual events Session Start and Session End
  // Need to look at how session start and session end virtual events are exported to snowflake, that may be why this was added?
  componentDidMount() {
    track("Session Start", {
      deprecated: true,
      replaced_with: "Use virtual Session Start event in mixpanel.",
    });
    time_event("Dropoff");

    localStorage.setItem("initialTarget", window.location.pathname);

    window.onbeforeunload = () => {
      localStorage.removeItem("initialTarget");
      track("Dropoff", {
        deprecated: true,
        replaced_with: "Use virtual Session End event in mixpanel.",
      });
    };
  }

  render() {
    const { Component, pageProps, reduxStore, router, userAgent } = this.props;

    return (
      <I18nextProvider i18n={i18n}>
        <Head>
          <meta content="width=device-width,initial-scale=1" name="viewport" />
          <title>{formatPageTitle(router?.route)}</title>
          <meta
            property="og:title"
            content={formatPageTitle(router?.route)}
            key="title"
          />
        </Head>
        <ApolloProvider client={apolloClient}>
          <Provider store={reduxStore}>
            <LocaleWrapper>
              <ErrorBoundary>
                <SetInitialize userAgent={userAgent}>
                  <RegisterContext>
                    <Notifications />
                    <DirectionalChakraProvider ChakraProvider={ChakraProvider}>
                      <Component
                        url={{ query: router.query, pathname: router.pathname }}
                        {...pageProps}
                      />
                    </DirectionalChakraProvider>
                  </RegisterContext>
                </SetInitialize>
              </ErrorBoundary>
            </LocaleWrapper>
          </Provider>
        </ApolloProvider>
      </I18nextProvider>
    );
  }
}

export default withLDProvider({
  clientSideID: process.env.LAUNCH_DARKLY_CLIENT_ID,
  options: {
    evaluationReasons: true,
  },
  reactOptions: {
    useCamelCaseFlagKeys: false,
  },
})(withReduxStore(Spring));

Spring.getInitialProps = async (appContext) => {
  const headers = appContext?.ctx?.req?.headers;
  const userAgent =
    typeof window === "undefined" && headers
      ? headers["user-agent"]
      : undefined;

  return { userAgent: userAgent };
};
