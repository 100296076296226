import {
  VCuratedToolsResourcesDuotoneSpot,
  VGlobalDuotoneSpot,
  VOutpatientCareDuotoneSpot,
  VRelationshipsDuotoneSpot,
  VAdhdDuotoneSpot,
  VEmptyStateDuotoneSpot,
  VWorkersDuotoneSpot,
} from "@springcare/sh-component-library";
import {
  CareNavigator,
  ChildCare,
  ForkRoadSign,
  MedicationHand,
  MobileInHand,
  ShapesInPalm,
  Vine,
  WateringPlant,
  WelcomeHand,
  SubstanceUse,
  FlagOnHills,
} from "design-system/assets";
import Router from "next/router";
import routes from "routes";
import { TRACK_EVENT } from "utils/mixpanel";
import { openDownloadMobileAppModal } from "shared/utils/downloadMobileApp.utils";
import {
  AppointmentAction,
  AppointmentKind,
  ProviderRole,
  links,
} from "@spring/constants";
import {
  getIsUniqueCCACopy,
  getIsUniqueWPMCHCopy,
  getBadgeExists,
} from "utils/employerServicesHelpers";
import { getOr } from "lodash/fp";
import { getCustomerSite } from "operations/queries/customerSite";
import { useQuery } from "@apollo/client";
import { getMemberInfo } from "operations/queries/member";
import Meowth from "@spring/meowth";
import { isEmpty } from "lodash";

//Description character count should not exceed 175
//Heading char limit 55
export const getAlsoAvailableSectionConfigs = ({
  t,
  disclosures,
  showSudProgramPage = false,
  showGlobalExperience = false,
  createCheckInMutation,
  onOpen,
  hasManagedDependents,
  onScheduleModalOpen,
  customerId,
  recommendedConnectedBenefits,
  isHealthPlan,
}) => {
  const { to, as } = routes.MemberBenefits;
  const tabKey = {
    "EAP-Benefit": t("limitedLangBenefits:EAPBenefit.label"),
    Coaching: t("limitedLangHomepage:Coaching.title"),
  };
  const isUniqueWPMHCCopy = getIsUniqueWPMCHCopy(customerId);
  const isUniqueCCACopy = getIsUniqueCCACopy(customerId);
  const badgeExists = getBadgeExists(customerId);
  const stringKeyCCA = isUniqueCCACopy ? customerId : "default";
  const stringKeyWPMHC = isUniqueWPMHCCopy ? customerId : "default";

  const { data: memberInfo, loading: memberLoading } = useQuery(
    getMemberInfo,
    Meowth.apolloOptionsUserId(),
  );

  const member = getOr(memberInfo, "user.member", memberInfo); // check if nested member, else use top level object
  const isSudSupported = member?.cohort?.contract_term?.sud_supported;

  const customerSiteId = member?.customer_site_id;
  const customerName = member?.cohort?.customer?.name;
  const shouldLoadCustomerSite = customerSiteId && !memberLoading;
  const { data: { customer_site: customerSite } = {} } = useQuery(
    getCustomerSite,
    {
      variables: {
        id: customerSiteId,
      },
      skip: !shouldLoadCustomerSite, // Don't load if we don't need it
    },
  );

  const ccaProvider = customerSite?.care_providers[0];

  const config = {
    therapist_follow_up: {
      icon: Vine,
      heading: t("homepage:alsoAvailableSection.therapistFollowUp.heading"),
      description: t(
        "homepage:alsoAvailableSection.therapistFollowUp.description",
      ),
      onClickCallback: disclosures.therapy.onOpen,
      onClickAction: "Informational Fly-Out - Therapy",
    },
    couples_therapy: {
      icon: VRelationshipsDuotoneSpot,
      heading: t("homepage:alsoAvailableSection.couplesTherapy.heading"),
      description: t(
        "homepage:alsoAvailableSection.couplesTherapy.description",
      ),
      onClickCallback: disclosures.couplesTherapy.onOpen,
      onClickAction: "Informational Fly-Out - Couples Therapy",
    },
    care_navigator_scheduling: {
      icon: CareNavigator,
      heading: hasManagedDependents
        ? t(
            "homepage:alsoAvailableSection.careNavigatorScheduling.familyHeading",
          )
        : t("homepage:alsoAvailableSection.careNavigatorScheduling.heading"),
      description: t(
        "homepage:alsoAvailableSection.careNavigatorScheduling.description",
      ),
      onClickCallback: disclosures.careNavigation.onOpen,
      onClickAction: "Informational Fly-Out - Care Navigation",
    },
    specialized_care_navigator: {
      icon: CareNavigator,
      heading: t(
        "homepage:alsoAvailableSection.specializedCNScheduling.heading",
      ),
      description: t(
        "homepage:alsoAvailableSection.careNavigatorScheduling.description",
      ),
      onClickCallback: () => {
        Router.push(routes.SUDCareNavigationScheduling.as);
      },
      onClickAction:
        "View substance use disorder care navigation calendar page",
    },
    // IMPORTANT: The neurodiversity pages should NOT be accessible in the prod env.
    // They are vaporware and are only intended for our sales team to use in the demo env.
    // See https://springhealth.atlassian.net/browse/MXE-520 for more detail.
    neurodiversity_support: {
      icon: VAdhdDuotoneSpot,
      heading: "Neurodiversity support",
      description:
        "Find care and support for all neurodiverse needs at home and at work. Find care for you and your loved ones.",
      onClickCallback: () => Router.push("/members/neurodiversity"),
    },
    wpo_coach_assignment: {
      icon: WateringPlant,
      heading: t("limitedLangHomepage:Coaching.title"),
      description: t("limitedLangHomepage:Coaching.description"),
      onClickCallback: () =>
        Router.push(
          {
            pathname: to,
            query: { tabKey: tabKey["Coaching"] },
          },
          as,
        ),
    },
    coach_assignment: {
      icon: WateringPlant,
      heading: t("homepage:alsoAvailableSection.coachAssignment.heading"),
      description: t(
        "homepage:alsoAvailableSection.coachAssignment.description",
      ),
      onClickCallback: disclosures.coaching.onOpen,
      onClickAction: "Informational Fly-Out - Coaching",
    },
    medication_manager_assignment: {
      icon: MedicationHand,
      heading: t(
        "homepage:alsoAvailableSection.medicationManagerAssignment.heading",
      ),
      description: t(
        "homepage:alsoAvailableSection.medicationManagerAssignment.description",
      ),
      onClickCallback: disclosures.medicationManagement.onOpen,
      onClickAction: "Informational Fly-Out - Medication Management",
    },
    invite_a_dependent: {
      icon: ChildCare,
      heading: t("homepage:alsoAvailableSection.inviteADependent.heading"),
      description: showGlobalExperience
        ? t("homepage:alsoAvailableSection.inviteADependent.descriptionGlobal")
        : t("homepage:alsoAvailableSection.inviteADependent.description"),
      onClickAction: routes.InviteDependents.as,
      onClickCallback: () =>
        Router.push(routes.InviteDependents.to, routes.InviteDependents.as),
    },
    work_life_support: {
      icon: ShapesInPalm,
      heading: t("homepage:alsoAvailableSection.workLifeSupport.heading"),
      description: t(
        "homepage:alsoAvailableSection.workLifeSupport.description",
      ),
      onClickAction: routes.MemberBenefits.as,
      onClickCallback: () =>
        Router.push(
          {
            pathname: to,
            query: { tabKey: tabKey["EAP-Benefit"] },
          },
          as,
        ),
    },
    check_in: {
      icon: ForkRoadSign,
      heading: t("homepage:alsoAvailableSection.checkIn.heading"),
      description: t("homepage:alsoAvailableSection.checkIn.description"),
      onClickCallback: () => createCheckInMutation(),
    },
    complete_pre_visit_form: {
      icon: WelcomeHand,
      heading: t("homepage:alsoAvailableSection.completePreVisitForm.heading"),
      description: t(
        "homepage:alsoAvailableSection.completePreVisitForm.description",
      ),
      onClickCallback: () => Router.push(routes.PrevisitForm.as),
      onClickAction: routes.PrevisitForm.as,
    },
    // sage_content is only for Target employees(member)
    sage_content_to_develop_workplace_skills: {
      icon: VCuratedToolsResourcesDuotoneSpot,
      heading: t("homepage:alsoAvailableSection.sageContent.heading"),
      description: t("homepage:alsoAvailableSection.sageContent.description"),
      onClickCallback: () => window.open(links.TargetSageContentURL, "_blank"),
      onClickAction: "External link",
    },
    download_mobile_app: {
      icon: MobileInHand,
      heading: t("homepage:alsoAvailableSection.downloadMobileApp.heading"),
      description: t(
        "homepage:alsoAvailableSection.downloadMobileApp.description",
      ),
      onClickCallback: () => openDownloadMobileModal(onOpen, t),
    },
    substance_use: {
      icon: SubstanceUse,
      heading: t("homepage:alsoAvailableSection.substanceUse.heading"),
      description: t("homepage:alsoAvailableSection.substanceUse.description"),
      onClickCallback: () => {
        if (showSudProgramPage && isSudSupported) {
          Router.push(routes.SubstanceUseSupport.as);
        } else {
          disclosures.substanceUse.onOpen();
        }
      },
      onClickAction: showSudProgramPage
        ? routes.SubstanceUseSupport.as
        : "Informational Fly-Out - Substance Use",
    },
    tobacco_cessation: {
      icon: FlagOnHills,
      heading: t("homepage:alsoAvailableSection.tobaccoCessation.heading"),
      description: t(
        "homepage:alsoAvailableSection.tobaccoCessation.description",
      ),
      onClickCallback: () => {
        TRACK_EVENT.CARD_CLICKED(
          window.location.pathname,
          as,
          t("homepage:alsoAvailableSection.tobaccoCessation.heading"),
          {
            spring_doc_id: "HPR_021",
            location: "Also available to you section",
          },
        );
        disclosures.tobaccoCessation.onOpen();
      },
    },
    care_advocate_scheduling: {
      icon: VOutpatientCareDuotoneSpot,
      heading: t(
        `homepage:alsoAvailableSection.careAdvocateScheduling.${stringKeyCCA}.heading`,
      ),
      description: t(
        `homepage:alsoAvailableSection.careAdvocateScheduling.${stringKeyCCA}.description`,
      ),
      onClickCallback: () =>
        openScheduleCareAdvocateModal(onScheduleModalOpen, ccaProvider),
    },
    care_consultant_scheduling: {
      badgeText: badgeExists
        ? t(`homepage:careInfoFlyout.careConsultant.${customerId}.badge`)
        : null,
      icon: VRelationshipsDuotoneSpot,
      heading: t(
        `homepage:alsoAvailableSection.careConsultantScheduling.${stringKeyWPMHC}.heading`,
      ),
      description: t(
        `homepage:alsoAvailableSection.careConsultantScheduling.${stringKeyWPMHC}.description`,
      ),
      onClickCallback: disclosures.careConsultant.onOpen,
      onClickAction: "Informational Fly-Out - Care Consultant Scheduling",
    },
    sdoh_local_resources: {
      icon: VGlobalDuotoneSpot,
      heading: t(`homepage:alsoAvailableSection.sdohLocalResources.heading`),
      description: t(
        `homepage:alsoAvailableSection.sdohLocalResources.description`,
      ),
      onClickAction: routes.LocalResources.as,
      onClickCallback: () =>
        Router.push(routes.LocalResources.to, routes.LocalResources.as),
    },
    workplace_consultation_scheduling: {
      icon: VWorkersDuotoneSpot,
      heading: t(
        `homepage:alsoAvailableSection.workplaceConsultationScheduling.heading`,
      ),
      description: t(
        `homepage:alsoAvailableSection.workplaceConsultationScheduling.description`,
      ),
      onClickCallback: disclosures.workplaceConsultation.onOpen,
      onClickAction: "Informational Fly-Out - Workplace Consultation",
    },
  };

  if (isEmpty(recommendedConnectedBenefits)) {
    config["connected_benefits"] = {
      icon: VEmptyStateDuotoneSpot,
      heading: isHealthPlan
        ? t(`homepage:alsoAvailableSection.connectedBenefits.payerHeading`)
        : t(`homepage:alsoAvailableSection.connectedBenefits.heading`, {
            customerName: customerName,
          }),
      description: isHealthPlan
        ? t(`homepage:alsoAvailableSection.connectedBenefits.payerDescription`)
        : t(`homepage:alsoAvailableSection.connectedBenefits.description`),
      onClickAction: routes.MemberCareVisits.as,
      onClickCallback: () =>
        Router.push(
          {
            pathname: routes.MemberCareVisits.to,
            query: { resources: true },
          },
          routes.MemberCareVisits.as,
        ),
      trackingProps: {
        benefit_type: "default",
        benefit_name: "default",
      },
    };
  } else if (!isEmpty(recommendedConnectedBenefits)) {
    recommendedConnectedBenefits.forEach(
      (benefit) =>
        (config[benefit.name] = {
          icon: benefit.logo_url,
          heading: benefit.benefits_type,
          description: benefit.description,
          onClickAction: "External link",
          onClickCallback: () =>
            window.open(benefit.connected_benefits_url, "_blank"),
          trackingProps: {
            benefit_type: benefit.benefits_type,
            benefit_name: benefit.name,
          },
        }),
    );
  }

  return config;
};

export const getAvailableItemsWithPositionedCoachingCard = (
  availableCareItems,
  isPaidMember,
  coachingAvailableSectionFlag,
) => {
  const availableItems = [...availableCareItems];

  const coachAssignmentItem = availableItems.find(
    (item) => item.care_item_name === "coach_assignment",
  );
  const filteredAvailableItems = availableItems.filter(
    (item) => item.care_item_name !== "coach_assignment",
  );

  if (isPaidMember) {
    if (coachAssignmentItem) {
      filteredAvailableItems.splice(2, 0, coachAssignmentItem);
      return filteredAvailableItems;
    }
  } else if (!coachingAvailableSectionFlag) {
    return filteredAvailableItems;
  }

  return availableItems;
};

const openDownloadMobileModal = (onOpen, t) => {
  openDownloadMobileAppModal({
    eventType: t("homepage:alsoAvailableSection.downloadMobileApp.heading"),
    onDownloadAppOpen: onOpen,
    springDocIdMobile: "HPR_020",
    springDocIdWeb: "HPR_023",
  });
};

const openScheduleCareAdvocateModal = (onScheduleModalOpen, ccaProvider) => {
  onScheduleModalOpen({
    action: AppointmentAction.Create,
    buttonText: "Confirm Appointment",
    kind: AppointmentKind.WellnessAndSocialSupportSession,
    provider: ccaProvider,
    providerRole: ProviderRole.CareAdvocate, // Passing in an enum, though it'll be converted to a string
    // TODO: Fix telemetry props
    telemetryProps: {
      location: "Also available to you section",
      springDocId: "123",
    },
  });
};

export const closeDownloadMobileModal = (onClose, t) => {
  TRACK_EVENT.MODAL_CLOSED(
    window.location.pathname,
    t("homepage:alsoAvailableSection.downloadMobileApp.heading"),
    {
      spring_doc_id: "HPR_024",
      location: "Also available to you section",
    },
  );
  onClose();
};
