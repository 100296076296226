import { useState, useEffect } from "react";
import { useMediaDeviceSelect } from "@livekit/components-react";
import { isSafari } from "react-device-detect";
import {
  MenuOptionGroup,
  MenuItemOption,
} from "@springcare/sh-component-library";
import { useSessionDevices } from "context/SessionRoomContext";
import { trackDeviceMenuSelection } from "components/templates/SHSessionRoom/telemetry/analytics";
import {
  DeviceTypes,
  SafariAudioOutputDevice,
} from "components/templates/SHSessionRoom/types";
import { useTranslation } from "hooks/react-i18next";

export const DeviceMenuOptionGroup = ({ type, sourceView }) => {
  const [connectedDevices, setConnectedDevices] = useState({
    audioinput: [],
    videoinput: [],
    audiooutput: [],
  });
  const { t } = useTranslation("livekitExperience", {
    keyPrefix: "videoCallControls.deviceMenuOptionGroup",
  });
  const { sessionDevices, setSessionDevices } = useSessionDevices();
  const {
    devices,
    selectedAudioOutputDevice,
    selectedAudioInputDevice,
    selectedVideoDevice,
  } = sessionDevices;

  useEffect(() => {
    if (isSafari) {
      // Inject a fake device into connectedDevices
      const fakeDevice = {
        label: SafariAudioOutputDevice.SystemDefaultSpeaker,
        deviceId: SafariAudioOutputDevice.DeviceId,
      };
      setConnectedDevices({
        ...devices,
        audiooutput: [...devices.audiooutput, fakeDevice],
      });
    } else {
      setConnectedDevices(devices);
    }
  }, [devices]);

  const { setActiveMediaDevice: setActiveAudioInputDevice } =
    useMediaDeviceSelect({
      kind: DeviceTypes.AudioInput,
    });
  const { setActiveMediaDevice: setActiveVideoInputDevice } =
    useMediaDeviceSelect({
      kind: DeviceTypes.VideoInput,
    });

  const { setActiveMediaDevice: setActiveAudioOutputDevice } =
    useMediaDeviceSelect({
      kind: DeviceTypes.AudioOutput,
    });

  let defaultValue = { deviceId: "", label: "" };
  let title = "";
  let selectFunction = (device) => {};
  switch (type) {
    case DeviceTypes.AudioOutput:
      defaultValue = isSafari
        ? {
            deviceId: SafariAudioOutputDevice.DeviceId,
            label: SafariAudioOutputDevice.SystemDefaultSpeaker,
          }
        : selectedAudioOutputDevice;
      title = t("selectASpeaker");
      selectFunction = (device) => {
        if (!isSafari) {
          setActiveAudioOutputDevice(device.deviceId);
          setSessionDevices((prevState) => ({
            ...prevState,
            selectedAudioOutputDevice: device,
          }));
          trackDeviceMenuSelection(
            DeviceTypes.AudioOutput,
            sourceView,
            device.label,
          );
        }
      };
      break;
    case DeviceTypes.AudioInput:
      defaultValue = selectedAudioInputDevice;
      title = t("selectAMicrophone");
      selectFunction = (device) => {
        setActiveAudioInputDevice(device.deviceId);
        setSessionDevices((prevState) => ({
          ...prevState,
          selectedAudioInputDevice: device,
        }));
        trackDeviceMenuSelection(
          DeviceTypes.AudioInput,
          sourceView,
          device.label,
        );
      };
      break;
    case DeviceTypes.VideoInput:
      defaultValue = selectedVideoDevice;
      title = t("selectACamera");
      selectFunction = (device) => {
        setActiveVideoInputDevice(device.deviceId);
        setSessionDevices((prevState) => ({
          ...prevState,
          selectedVideoDevice: device,
        }));
        trackDeviceMenuSelection(
          DeviceTypes.VideoInput,
          sourceView,
          device.label,
        );
      };
      break;
    default:
      throw new Error("Unsupported device type");
  }

  return (
    <>
      <MenuOptionGroup
        defaultValue={defaultValue.deviceId}
        title={title}
        type="radio"
      >
        {connectedDevices[type].map((device) => (
          <MenuItemOption
            value={device.deviceId}
            key={device.deviceId}
            onClick={() => selectFunction(device)}
            _focus={{ outline: "0px solid transparent !important" }}
            _focusVisible={{ outline: "2px solid black !important" }}
            _hover={{ bg: "hover-base-low-emphasis" }}
          >
            {device.label}
          </MenuItemOption>
        ))}
      </MenuOptionGroup>
    </>
  );
};

export default DeviceMenuOptionGroup;
